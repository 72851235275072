import { SendOutline } from 'antd-mobile-icons';
import ItemCardHorizontal from '../../components/ItemCardHorizontal';
import React from 'react';
import useQueryNewsInfinate from '../../hooks/useQueryNewsInfinate';
import { MEDIA_TYPE_NAMES } from '../../constants';
import { fmtCurrentDateTime2Str, fmtRoute2FullPath } from '../../utils/tools';
import useJump from '../../hooks/useJump';
import Nodata from '../../components/Nodata';
import Loading from '../../components/Loading';
import { InfiniteScroll } from 'antd-mobile';
import zhts from '../../images/zhts-mrt.png';

/**
 * 翼投教-中航图说（图文投教）
 */

export interface YTJImageTextListProps {
  searchValue?: string;
  count?: number;
  isLoadMore?: boolean;
  loadingColor?: string;
  onShare?: (func: string, item: API.INews, index: number) => void;
  onbutClick?:()=>void;
  onItemClick?:()=>void;
}
const YTJImageTextList: React.FC<YTJImageTextListProps> = ({
  searchValue,
  count,
  isLoadMore,
  loadingColor,
  onShare,
  onbutClick,
  onItemClick,
}) => {
  const { jumpNewsDetailFromMediaType } = useJump();
  const { data, loading, loadMore, hasMore } = useQueryNewsInfinate({
    mediaTypeId: MEDIA_TYPE_NAMES.翼投教图文,
    searchValue,
    count,
  });
  return (
    <React.Fragment>
      {data?.map((item, index) => (
        <ItemCardHorizontal
          key={item.id}
          shereid={item.id}
          img={item.img||zhts}
          title={item.title}
          description={item.shortTitle}
          onbutClick={onbutClick}
          time={
            <span>
              {item?.updateTime
                ? fmtCurrentDateTime2Str(item.updateTime)
                : null}
              &nbsp;&nbsp;
              {item.hostMedia ? (
                <>
                  <SendOutline />
                  &nbsp;{item.hostMedia}
                </>
              ) : null}
            </span>
          }
          share
          onItemClick={() => {
            if(onItemClick){
              onItemClick()
            }
            jumpNewsDetailFromMediaType(MEDIA_TYPE_NAMES.翼投教图文, {
              url: item.url,
              id: item.id,
            });
          }}
          onShareClick={(func) => {
            onShare?.(
              func,
              { ...item, img: fmtRoute2FullPath('/shareImg/zhts.jpg') },
              index,
            );
          }}
        />
      ))}
      {!loading && !data?.length && (
        <Nodata content={searchValue ? '暂未搜索到相关数据' : '暂无数据'} />
      )}
      {/* 初始化loading */}
      {!data && loading ? (
        <Loading loadingColor={loadingColor} />
      ) : (
        hasMore &&
        !!data &&
        isLoadMore &&
        !loading && (
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <Loading loadingColor={loadingColor} />
          </InfiniteScroll>
        )
      )}
    </React.Fragment>
  );
};

export default YTJImageTextList;
