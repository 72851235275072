import React, { useCallback, useState } from 'react';
import ShareBtn from '../../components/ShareBtn';
import cls from './NewsFlash.module.scss';
import { Swiper } from 'antd-mobile';
export interface NewsFlashProps {
  dataList?: API.INews[];
  onShare?: (index: number) => void;
  handleCardClick:() => void;
}
const NewsFlash: React.FC<NewsFlashProps> = ({ dataList, onShare,handleCardClick }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleShareClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onShare?.(activeIndex);
    },
    [activeIndex, onShare],
  );
  return (
    <React.Fragment>
      {dataList?.length ? (
        <div className={cls.wrap} onClick={handleCardClick}>
          <img
            className={cls.img}
            src={require('../../images/NewsFlashIcon.png')}
            alt=""
          />
          <div className={`${cls.content}`}>
            <Swiper
              direction="vertical"
              indicator={() => null}
              autoplay
              loop
              allowTouchMove={false}
              onIndexChange={setActiveIndex}
              className={cls.SwiperView}
            >
              {dataList.map((item, index) => (
                <Swiper.Item key={index}>
                  <div className={cls.newsItemWrap}>
                    <div className={cls.newsItem}>{item?.conten || ''}</div>
                  </div>
                </Swiper.Item>
              ))}
            </Swiper>
          </div>
          <ShareBtn
            popup={false}
            onClick={handleShareClick}
            shereid={dataList[activeIndex].id}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default NewsFlash;
