import cls from './YZXSelector.module.scss';
export interface YZXSelectorData {
  label: string;
  value: string;
}
export interface YZXSelectorProps {
  data: YZXSelectorData[];
  onItemClick?: (item: YZXSelectorData, index: number) => void;
  activeKey?: string;
}
const YZXSelector: React.FC<YZXSelectorProps> = ({
  data,
  onItemClick,
  activeKey,
}) => {
  return (
    <div className={cls.selector}>
      {data.map((item, index) => (
        <div
          className={`${cls.selectorItem}${
            item.value === activeKey ? ' ' + cls.selectorItemActive : ''
          }`}
          onClick={() => onItemClick?.(item, index)}
          key={item.value}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default YZXSelector;
