import { Swiper as AntSwiper } from 'antd-mobile';
import cls from './Swiper.module.scss';

export interface SwiperProps {
  data: API.ISlideRes[];
  onClick?: (data: API.ISlideRes, index: number) => void;
}

const Swiper: React.FC<SwiperProps> = ({ data, onClick }) => {
  return (
    <AntSwiper
      indicatorProps={{
        style: {
          '--active-dot-color': `rgba(255, 255, 255, ${
            data?.length > 1 ? 1 : 0
          })`,
        },
      }}
      style={{
        '--border-radius': '0.5000rem',
      }}
      autoplay
      loop
    >
      {data.map((item, index) => (
        <AntSwiper.Item
          key={index}
          onClick={() => onClick && onClick(item, index)}
        >
          <div
            className={cls.content}
            style={{ backgroundImage: `url('${item.img}')` }}
          ></div>
        </AntSwiper.Item>
      ))}
    </AntSwiper>
  );
};

export default Swiper;
