// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubColumns_wrap__7uTAt {
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  border: 0.0625rem solid #ffffff;
  padding: 1.25rem 0.9375rem;
  background-color: #ffffff;
}
.SubColumns_wrap__7uTAt .SubColumns_titleView__aqgLQ {
  display: flex;
  padding-bottom: 1.25rem;
}
.SubColumns_wrap__7uTAt .SubColumns_titleView__aqgLQ .SubColumns_img__NcHBW {
  width: auto;
  height: 1.3125rem;
}
.SubColumns_wrap__7uTAt .SubColumns_titleView__aqgLQ .SubColumns_title__NTDL2 {
  color: rgb(37, 47, 66);
  font-size: 1.1875rem;
  padding-left: 0.3125rem;
  font-family: Alimama_ShuHeiTi_Bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/containers/SubColumns.module.scss"],"names":[],"mappings":"AACA;EACI,uBAAA;EACA,oBAAA;EACA,+BAAA;EACA,0BAAA;EACA,yBAAA;AAAJ;AACI;EACE,aAAA;EACE,uBAAA;AACR;AAAM;EACE,WAAA;EACA,iBAAA;AAER;AAAI;EACI,sBAAA;EACA,oBAAA;EACA,uBAAA;EACA,kCAAA;AAER","sourcesContent":["\r\n.wrap {\r\n    border-radius: 0.6250rem;\r\n    margin-top: 0.6250rem;\r\n    border: 0.0625rem solid #ffffff;\r\n    padding: 1.2500rem 0.9375rem;\r\n    background-color: #ffffff;\r\n    .titleView {\r\n      display: flex;\r\n        padding-bottom: 1.2500rem;\r\n      .img {\r\n        width: auto;\r\n        height: 1.3125rem;\r\n      }\r\n    .title {\r\n        color: rgba(37, 47, 66, 1);\r\n        font-size: 1.1875rem;\r\n        padding-left: 0.3125rem;\r\n        font-family: Alimama_ShuHeiTi_Bold;\r\n    }\r\n     \r\n    }\r\n   \r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `SubColumns_wrap__7uTAt`,
	"titleView": `SubColumns_titleView__aqgLQ`,
	"img": `SubColumns_img__NcHBW`,
	"title": `SubColumns_title__NTDL2`
};
export default ___CSS_LOADER_EXPORT___;
