// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Swiper_content__E4jk9 {
  height: 6.75rem;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/components/Swiper.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,sBAAA;EACA,4BAAA;AACF","sourcesContent":[".content {\r\n  height: 6.7500rem;\r\n  width: 100%;\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Swiper_content__E4jk9`
};
export default ___CSS_LOADER_EXPORT___;
