// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.More_wrap__QBDZl {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0.9375rem;
}
.More_wrap__QBDZl .More_content__zUHa2 {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1;
  padding: 0.25rem 0;
  color: #3975c6;
  position: relative;
}
.More_wrap__QBDZl .More_content__zUHa2 .More_icon__MbZUB {
  color: #3975c6;
  font-size: 1rem;
  margin-top: -0.125rem;
}
.More_wrap__QBDZl .More_content__zUHa2 .More_icon__MbZUB.More_left__D9Pdk {
  transform: rotateY(180deg);
}
.More_wrap__QBDZl .More_content__zUHa2 .More_icon__MbZUB.More_bottom__8r8Ki {
  transform: rotateZ(90deg);
}
.More_wrap__QBDZl .More_content__zUHa2 .More_icon__MbZUB.More_top__VikcE {
  transform: rotateZ(270deg);
}
.More_wrap__QBDZl .More_content__zUHa2.More_underline__Vst93::after {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: -0.125rem;
  border-bottom: 0.0625rem solid #3975c6;
}
.More_wrap__QBDZl.More_inline__oXygH {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/More.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;AAEJ;AADI;EACE,cAAA;EACA,eAAA;EACA,qBAAA;AAGN;AAFM;EACE,0BAAA;AAIR;AAFM;EACE,yBAAA;AAIR;AAFM;EACE,0BAAA;AAIR;AADI;EACE,YAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,sCAAA;AAGN;AAAE;EACE,qBAAA;AAEJ","sourcesContent":[".wrap {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin-top: 0.9375rem;\r\n  .content {\r\n    display: flex;\r\n    align-items: center;\r\n    font-size: 1.0000rem;\r\n    line-height: 1;\r\n    padding: 0.2500rem 0;\r\n    color: #3975c6;\r\n    position: relative;\r\n    .icon {\r\n      color: #3975c6;\r\n      font-size: 1.0000rem;\r\n      margin-top: -0.1250rem;\r\n      &.left {\r\n        transform: rotateY(180deg);\r\n      }\r\n      &.bottom {\r\n        transform: rotateZ(90deg);\r\n      }\r\n      &.top {\r\n        transform: rotateZ(270deg);\r\n      }\r\n    }\r\n    &.underline::after {\r\n      content: ' ';\r\n      display: block;\r\n      position: absolute;\r\n      width: 100%;\r\n      bottom: 0;\r\n      left: -0.1250rem;\r\n      border-bottom: 0.0625rem solid #3975c6;\r\n    }\r\n  }\r\n  &.inline {\r\n    display: inline-block;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `More_wrap__QBDZl`,
	"content": `More_content__zUHa2`,
	"icon": `More_icon__MbZUB`,
	"left": `More_left__D9Pdk`,
	"bottom": `More_bottom__8r8Ki`,
	"top": `More_top__VikcE`,
	"underline": `More_underline__Vst93`,
	"inline": `More_inline__oXygH`
};
export default ___CSS_LOADER_EXPORT___;
