import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Search from '../../components/Search';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { addParam2Search } from '../../utils/tools';

const SearchPageLayout: React.FC<{
  renderChild?: (searchValue: string) => any;
}> = () => {
  const [inputValue, setInputValue] = useState('');
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const location = useLocation();
  const searchValue = useMemo(() => {
    return searchParams.get('search') ?? '';
  }, [searchParams]);
  useEffect(() => {
    // 初始化输入框内容
    setInputValue(searchValue);
  }, [searchValue]);
  const handleEnterPress = useCallback(() => {
    const jumpUrl = addParam2Search(location.pathname, {
      search: location.search,
      param: {
        key: 'search',
        value: inputValue,
      },
    });
    navigator(jumpUrl, { replace: true });
  }, [location, navigator, inputValue]);
  return (
    <div
      style={{
        boxSizing: 'border-box',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Search
        value={inputValue}
        onChange={setInputValue}
        onEnterPress={handleEnterPress}
      />
      <div
        style={{
          backgroundImage: 'linear-gradient(180deg, #3975C6 0%, #D5E7FF 100%)',
          backgroundSize: '100% 25.0000rem',
          backgroundColor: '#D5E7FF',
          backgroundRepeat: 'no-repeat',
          padding: '0 0.9375rem 1.8750rem 0.9375rem',
          flex: 1,
        }}
      >
        <Outlet context={[{ searchValue }]} />
      </div>
    </div>
  );
};

export default SearchPageLayout;
