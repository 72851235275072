// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_wrap__ST8Z8 {
  padding: 0.9375rem 0.9375rem 0.3125rem 0.9375rem;
  background: #3975c6;
}
.Search_wrap__ST8Z8 .Search_searchContent__LrEhz {
  border-radius: 0.9375rem;
  background: rgba(247, 248, 250, 0.2);
  padding: 0.3125rem 1.875rem 0.3125rem 2.1875rem;
  color: #ffffff;
  align-items: center;
  position: relative;
}
.Search_wrap__ST8Z8 .Search_searchContent__LrEhz .Search_leftIcon__QkC\\+B {
  position: absolute;
  left: 0.9375rem;
  top: 50%;
  font-size: 0.875rem;
  margin-top: -0.4375rem;
}
.Search_wrap__ST8Z8 .Search_searchContent__LrEhz .Search_input__A-zY0 {
  width: 100%;
  opacity: 0.8;
  font-size: 0.8125rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1;
  border: none;
  background: transparent;
  outline: none;
  caret-color: rgba(255, 255, 255, 0.8);
}
.Search_wrap__ST8Z8 .Search_searchContent__LrEhz .Search_input__A-zY0:focus-visible {
  outline: none;
}
.Search_wrap__ST8Z8 .Search_searchContent__LrEhz .Search_input__A-zY0::placeholder {
  color: rgba(255, 255, 255, 0.8);
}`, "",{"version":3,"sources":["webpack://./src/components/Search.module.scss"],"names":[],"mappings":"AAAA;EACE,gDAAA;EACA,mBAAA;AACF;AAAE;EACE,wBAAA;EACA,oCAAA;EACA,+CAAA;EACA,cAAA;EAEA,mBAAA;EACA,kBAAA;AACJ;AAAI;EACE,kBAAA;EACA,eAAA;EACA,QAAA;EACA,mBAAA;EACA,sBAAA;AAEN;AAAI;EACE,WAAA;EACA,YAAA;EACA,oBAAA;EACA,+BAAA;EACA,cAAA;EACA,YAAA;EACA,uBAAA;EACA,aAAA;EACA,qCAAA;AAEN;AADM;EACE,aAAA;AAGR;AADM;EACE,+BAAA;AAGR","sourcesContent":[".wrap {\r\n  padding: 0.9375rem 0.9375rem 0.3125rem 0.9375rem;\r\n  background: #3975c6;\r\n  .searchContent {\r\n    border-radius: 0.9375rem;\r\n    background: rgba(247, 248, 250, 0.2);\r\n    padding: 0.3125rem 1.8750rem 0.3125rem 2.1875rem;\r\n    color: #ffffff;\r\n    // display: flex;\r\n    align-items: center;\r\n    position: relative;\r\n    .leftIcon {\r\n      position: absolute;\r\n      left: 0.9375rem;\r\n      top: 50%;\r\n      font-size: 0.8750rem;\r\n      margin-top: -0.4375rem;\r\n    }\r\n    .input {\r\n      width: 100%;\r\n      opacity: 0.8;\r\n      font-size: 0.8125rem;\r\n      color: rgba(255, 255, 255, 0.8);\r\n      line-height: 1;\r\n      border: none;\r\n      background: transparent;\r\n      outline: none;\r\n      caret-color: rgba(255, 255, 255, 0.8);\r\n      &:focus-visible {\r\n        outline: none;\r\n      }\r\n      &::placeholder {\r\n        color: rgba(255, 255, 255, 0.8);\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Search_wrap__ST8Z8`,
	"searchContent": `Search_searchContent__LrEhz`,
	"leftIcon": `Search_leftIcon__QkC+B`,
	"input": `Search_input__A-zY0`
};
export default ___CSS_LOADER_EXPORT___;
