import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DailyTask, { DailyTaskDataItemProps } from './components/DailyTask';
import StickyTabs, { StickyTabsItemProps } from './components/StickyTabs';
import Search from '../components/Search';
import HotShareCard from './components/HotShareCard';
import Divider from '../components/Divider';
import Card from '../components/Card';
import MainStickyTabItemTitle from './components/MainStickyTabItemTitle';
import More from '../components/More';
import TCJJList from './containers/TCJJList';
import ZHDJList from './containers/ZHDJList';
import YZBList from './containers/YZBList';
import YTJVideoList from './containers/YTJVideoList';
import YTJImageTextList from './containers/YTJImageTextList';
import YZXList from './containers/YZXList';
import { useNavigate } from 'react-router-dom';
import ExcitingActivityList from './containers/ExcitingActivityList';
import {
  useQueryActiveList,
  useQueryFinance,
  useQueryHotShareList,
  useQueryInvestmentAdviserList,
  useQueryMediaTypeList,
  useQueryUpdateTimeInformation,
} from '../services/api';

import cls from './Main.module.scss';
import {
  MEDIA_TYPE_ID_CONNECTOR,
  MEDIA_TYPE_NAMES,
  MRBZ_DESCRIPTIONS,
  PUSH_NAME,
  URL_NAMES,
  YZX_KEY_STR,
} from '../constants';
import useTitle from '../hooks/useTitle';
import YZXNewsFlash from './containers/YZXNewsFlash';
import moment from 'moment';
import { shareWxworkMessage } from '../utils/wxUtil';
import { TggdImg, fmtRoute2FullPath, getFullPathFromMediaTypeIds } from '../utils/tools';
import useInitWxFunc from '../hooks/useInitWxFunc';
import PageSwiper from './containers/PageSwiper';
import TGGDList from './containers/TGGDList';
import { useGlobalState } from '../models/global';
import { getPushUrl } from '../utils/utils';
import { track } from '../utils/sdk';
import DzjdTzrlCcrb from './components/DzjdTzrlCcrb';
import { Link } from 'react-router-dom';
const timeLineData: DailyTaskDataItemProps[] = [
  {
    mediaTypeId: MEDIA_TYPE_NAMES.投资早班机,
    time: '0830',
    titleImg: require('../images/DailyTaskItemCardTitle1.png'),
    description: MRBZ_DESCRIPTIONS.投资早班机,
    background: require('../images/DailyTaskItemCardBg1.png'),
    moreColor: 'rgba(66, 106, 158, 0.3)',
    descriptionColor: '#2E68B4',
    shareBgColor: '#5E8BE0',
    path: URL_NAMES.投资早班机,
    title: '投资早班机',
    imgUrl: fmtRoute2FullPath('/shareImg/zbj.jpg'),
    isDisplay:true,
  },
  {
    mediaTypeId: MEDIA_TYPE_NAMES.午间快评,
    time: '1230',
    titleImg: require('../images/DailyTaskItemCardTitle2.png'),
    description: MRBZ_DESCRIPTIONS.午间快评,
    background: require('../images/DailyTaskItemCardBg2.png'),
    moreColor: 'rgba(211, 91, 65, 0.3)',
    descriptionColor: '#DD7129',
    shareBgColor: '#E06C3C',
    path: URL_NAMES.午间快评,
    title: '午间快评',
    imgUrl: fmtRoute2FullPath('/shareImg/wjkp.jpg'),
    isDisplay:true,
  },
  {
    mediaTypeId: MEDIA_TYPE_NAMES.每日收评,
    time: '1630',
    titleImg: require('../images/WechatIMG42.png'),
    description: MRBZ_DESCRIPTIONS.每日收评,
    background: require('../images/WechatIMG21.png'),
    moreColor: 'RGBA(195, 83, 221, 0.3)',
    descriptionColor: '#BD41D9',
    shareBgColor: '#C353DD',
    path: URL_NAMES.每日收评,
    title: '每日收评',
    imgUrl: fmtRoute2FullPath('/shareImg/mrsp.png'),
    isDisplay:true,
  },
  {
    mediaTypeId: MEDIA_TYPE_NAMES.焦点复盘,
    time: '1800',
    titleImg: require('../images/DailyTaskItemCardTitle3.png'),
    description: MRBZ_DESCRIPTIONS.焦点复盘,
    background: require('../images/DailyTaskItemCardBg3.png'),
    moreColor: 'rgba(185, 140, 33, 0.3)',
    descriptionColor: '#B98C21',
    shareBgColor: '#D8AD2A',
    path: URL_NAMES.焦点复盘,
    title: '焦点复盘',
    imgUrl: fmtRoute2FullPath('/shareImg/jdfp.jpg'),
    isDisplay:true,
  },
  {
    mediaTypeId: MEDIA_TYPE_NAMES.盘后公告,
    time: '2000',
    titleImg: require('../images/WechatIMG44.png'),
    description: MRBZ_DESCRIPTIONS.盘后公告,
    background: require('../images/WechatIMG23.png'),
    moreColor: 'rgba(185, 140, 33, 0.3)',
    descriptionColor: '#EF913A',
    shareBgColor: '#F3AA66',
    path: URL_NAMES.盘后公告,
    title: '盘后公告',
    imgUrl: fmtRoute2FullPath('/shareImg/phgg.png'),
    isDisplay:true,
  },
  {
    mediaTypeId: MEDIA_TYPE_NAMES.明日洞察,
    time: '2030',
    titleImg: require('../images/DailyTaskItemCardTitle4.png'),
    description: MRBZ_DESCRIPTIONS.明日洞察,
    background: require('../images/DailyTaskItemCardBg4.png'),
    moreColor: 'rgba(85, 94, 198, 0.3)',
    descriptionColor: '#5E64AB',
    shareBgColor: '#3F5CD8',
    path: URL_NAMES.明日洞察,
    title: '明日洞察',
    imgUrl: fmtRoute2FullPath('/shareImg/mrdc.jpg'),
    isDisplay:true,
  },
  {
    mediaTypeId: MEDIA_TYPE_NAMES.新闻联播,
    time: '2100',
    titleImg: require('../images/WechatIMG43.png'),
    description: MRBZ_DESCRIPTIONS.新闻联播,
    background: require('../images/WechatIMG22.png'),
    moreColor: 'RGBA(243, 105, 116, 0.3)',
    descriptionColor: '#F25A66',
    shareBgColor: '#F36974',
    path: URL_NAMES.新闻联播,
    title: '新闻联播',
    imgUrl: fmtRoute2FullPath('/shareImg/xflb.png'),
    isDisplay:true,
  },
  
  
];

const PAGE_NAME = '资讯中心';
const Main = () => {
  useTitle(PAGE_NAME);
  const navigator = useNavigate();
  useEffect(()=>{
    track('页面浏览')
  },[])
  const { onShare } = useInitWxFunc();
  const [{wxUseData}]=useGlobalState()
  const { data: mediaTypeList = [] } = useQueryMediaTypeList();
  const { data: hotShareList } = useQueryHotShareList();
  const { data: newsUpdateTimeList } = useQueryUpdateTimeInformation();
  const { data: tzzbjData } = useQueryActiveList({
    mediaTypeId: MEDIA_TYPE_NAMES.投资早班机,
    pageMaxCount: 1,
  });
  const { data: wjkpData } = useQueryFinance({
    dataType: MEDIA_TYPE_NAMES.午间快评,
  });
  const { data: jdfpData } = useQueryFinance({
    dataType: MEDIA_TYPE_NAMES.焦点复盘,
  });
  const { data: mrdcData } = useQueryFinance({
    dataType: MEDIA_TYPE_NAMES.明日洞察,
  });
  const { data: mrspData } = useQueryFinance({
    dataType: MEDIA_TYPE_NAMES.每日收评,
  });
  const { data: xflbData } = useQueryFinance({
    dataType: MEDIA_TYPE_NAMES.新闻联播,
  });
  const { data: phggData } = useQueryFinance({
    dataType: MEDIA_TYPE_NAMES.盘后公告,
  });
 
  const { data: tggdData } = useQueryInvestmentAdviserList()
  const dailyTaskShareData = useMemo(() => {
    return {
      [MEDIA_TYPE_NAMES.投资早班机]: tzzbjData?.[0],
      [MEDIA_TYPE_NAMES.午间快评]: wjkpData,
      [MEDIA_TYPE_NAMES.焦点复盘]: jdfpData,
      [MEDIA_TYPE_NAMES.明日洞察]: mrdcData,
      [MEDIA_TYPE_NAMES.每日收评]: mrspData,
      [MEDIA_TYPE_NAMES.新闻联播]: xflbData,
      [MEDIA_TYPE_NAMES.盘后公告]: phggData,
    };
  }, [tzzbjData, wjkpData, jdfpData, mrdcData,mrspData,xflbData,phggData]);
  const handleNewsShareClick = useCallback(
    (func: string, item: API.INews, index: number,btn_title?:string) => {
      track('按钮点击',{
        page_id:'leaf1002',
        btn_title:`${btn_title}转发|${PUSH_NAME[func]}`
      })
      const timeLineDataObj = timeLineData.reduce<
        Record<string, DailyTaskDataItemProps>
      >((prev, curr) => {
        return {
          ...prev,
          [curr.mediaTypeId]: curr,
        };
      }, {});
      const timeLineShareInfo = timeLineDataObj[item.mediaType];
      // 今日必做和普通h5分享方式不同,本来投资早班机也要特殊处理，但是由于热门分享不会出现投资早班机的内容，此处不额外处理
      const shareObj = timeLineShareInfo
        ? {
            title:timeLineShareInfo.mediaTypeId === MEDIA_TYPE_NAMES.新闻联播?item?.title:`${moment(item?.updateTime, 'YYYY-MM-DD HH:mm:ss').format(
              'M.DD',
            )}${timeLineShareInfo.title}|${item?.title}`,
            link: getPushUrl(getFullPathFromMediaTypeIds(item.mediaType, {
              url: item.url,
              id: item.id,
            }),wxUseData),
            desc: item.shortTitle||timeLineShareInfo.description,
            imgUrl: timeLineShareInfo.imgUrl,
          }
        : {
            title: item.title,
            link: getPushUrl(getFullPathFromMediaTypeIds(item.mediaType, {
              url: item.url,
              id: item.id,
            }),wxUseData),
            desc: item.shortTitle,
            imgUrl: item.img,
          };
      onShare(func, () => {
        shareWxworkMessage(func, shareObj);
      });
    },
    [onShare,wxUseData],
  );
  const handleNewsShareClickTG = useCallback(
    (func: string, item: API.IInvestmentAdviser, index: number) => {
      track('按钮点击',{
        page_id:'leaf1002',
        btn_title:`投顾观点转发|${PUSH_NAME[func]}`
      })
      const shareObj = {
            title: item.mediaName,
            link: getPushUrl(getFullPathFromMediaTypeIds(MEDIA_TYPE_NAMES.投顾观点,{
              id: item.id,
            }),wxUseData),
            desc: item.shortTitle,
            imgUrl: TggdImg,
          };
      onShare(func, () => {
        shareWxworkMessage(func, shareObj);
      });
    },
    [onShare,wxUseData],
  );
  const onbutClick=useCallback((title:string)=>{
    track('按钮点击',{
      btn_title:`${title}|分享`
    })
  },[])
  const onItemClick=useCallback((title:string)=>{
    track('按钮点击',{
      btn_title:`${title}|内容`
    })
  },[])
  const tabItems = useMemo(() => {
    const mainTabComponents = {
      [MEDIA_TYPE_NAMES.精彩活动]: () => {
        return (
          <Card>
            <MainStickyTabItemTitle
              titleImg={require('../images/StickyTabsItemTitleJCHD.png')}
            />
            <ExcitingActivityList />
            <More
              onClick={() => {
                track('按钮点击',{
                  btn_title:'精彩活动|查看更多'
                })
                navigator('/jchd');
              }}
            />
          </Card>
        );
      },
      [MEDIA_TYPE_NAMES.题材掘金]: () => {
        return (
          <Card>
            <MainStickyTabItemTitle
              titleImg={require('../images/StickyTabsItemTitleTCJJ.png')}
            />
            <TCJJList 
            onItemClick={()=>onItemClick('题材掘金')}
            onbutClick={()=>onbutClick('题材掘金')}
            onShare={(func: string, item: API.INews, index: number)=>{handleNewsShareClick(func, item, index,'题材掘金')}} />
            <More
              onClick={() => {
                track('按钮点击',{
                  btn_title:'题材掘金|查看更多'
                })
                navigator(URL_NAMES.题材掘金);
              }}
            />
          </Card>
        );
      },
      [MEDIA_TYPE_NAMES.投顾观点]: () => {
        return (
          <>
            {tggdData&&tggdData.length>0?
             <Card>
             <MainStickyTabItemTitle
               titleImg={require('../images/WechatIMG25.png')}
             />
             <TGGDList 
             onItemClick={()=>onItemClick('投顾观点')}
             onShare={handleNewsShareClickTG}  onbutClick={()=>onbutClick('投顾观点')}/>
             <More
               onClick={() => {
                track('按钮点击',{
                  btn_title:'投顾观点|查看更多'
                })
                 navigator(URL_NAMES.投顾观点);
               }}
             />
           </Card>:null
            }
          </>
         
        );
      },
      [MEDIA_TYPE_NAMES.中航独家]: () => {
        return (
          <Card>
            <MainStickyTabItemTitle
              titleImg={require('../images/StickyTabsItemTitleZHDJ.png')}
            />
            <ZHDJList onItemClick={()=>onItemClick('中航独家')} onbutClick={()=>onbutClick('中航独家')} onShare={(func: string, item: API.INews, index: number)=>{handleNewsShareClick(func, item, index,'中航独家')}} />
            <More
              onClick={() => {
                track('按钮点击',{
                  btn_title:'中航独家|查看更多'
                })
                navigator(URL_NAMES.中航独家);
              }}
            />
          </Card>
        );
      },
      [MEDIA_TYPE_NAMES.翼直播]: () => {
        return (
          <Card>
            <MainStickyTabItemTitle
              style={{ marginBottom: '0.7500rem' }}
              titleImg={require('../images/StickyTabsItemTitleYZB.png')}
            />
            <YZBList onItemClick={()=>onItemClick('翼直播')} onbutClick={()=>onbutClick('翼直播')} onShare={(func: string, item: API.INews, index: number)=>{handleNewsShareClick(func, item, index,'翼直播')}} />
            <More
              onClick={() => {
                track('按钮点击',{
                  btn_title:'翼直播|查看更多'
                })
                navigator(URL_NAMES.翼直播);
              }}
            />
          </Card>
        );
      },
      [YZX_KEY_STR]: () => {
        return (
          <Card>
            <MainStickyTabItemTitle
              style={{ marginBottom: '0.7500rem' }}
              titleImg={require('../images/StickyTabsItemTitleYZX.png')}
            />
            
            <YZXList  onItemClick={()=>onItemClick('翼资讯')} onbutClick={()=>onbutClick('翼资讯')} onShare={(func: string, item: API.INews, index: number)=>{handleNewsShareClick(func, item, index,'翼资讯')}} count={3} />
            <More
              onClick={() => {
                track('按钮点击',{
                  btn_title:'翼资讯|查看更多'
                })
                navigator(URL_NAMES.翼资讯);
              }}
            />
          </Card>
        );
      },
      [`${MEDIA_TYPE_NAMES.翼投教图文}${MEDIA_TYPE_ID_CONNECTOR}${MEDIA_TYPE_NAMES.翼投教视频}`]:
        () => {
          return (
            <Card>
              <MainStickyTabItemTitle
                style={{ marginBottom: '0.7500rem' }}
                titleImg={require('../images/StickyTabsItemTitleYTJ.png')}
              />
              <YTJVideoList  onItemClick={()=>onItemClick('翼投教')} onbutClick={()=>onbutClick('翼投教')} onShare={(func: string, item: API.INews, index: number)=>{handleNewsShareClick(func, item, index,'翼投教')}} />
              <YTJImageTextList onItemClick={()=>onItemClick('翼投教')} onbutClick={()=>onbutClick('翼投教')} count={3} onShare={(func: string, item: API.INews, index: number)=>{handleNewsShareClick(func, item, index,'翼投教')}} />
              <More
                onClick={() => {
                  track('按钮点击',{
                    btn_title:'翼投教|查看更多'
                  })
                  navigator(URL_NAMES.翼投教);
                }}
              />
            </Card>
          );
        },
    };
    let list=mediaTypeList
    if(tggdData&&tggdData.length>0){
      let index = list?.findIndex(item => item.mediaTypeName === '中航独家');
      let newObj={id: 29, mediaTypeId: "29", mediaTypeName: "投顾观点", sortnbr: 29}
      let isExistence =list?.some(item => item.mediaTypeName === '投顾观点')
      if(!isExistence){
        list?.splice(index, 0, newObj);
      }
    }
   
    const tabs = list?.reduce<StickyTabsItemProps[]>((prev, curr) => {
      if (mainTabComponents[curr.mediaTypeId]) {
        return [
          ...prev,
          {
            title: curr.mediaTypeName,
            key: curr.mediaTypeId + '',
            component: mainTabComponents[curr.mediaTypeId],
          },
        ];
      } else {
        return prev;
      }
    }, []);
    return tabs;
  }, [navigator, mediaTypeList, handleNewsShareClick,handleNewsShareClickTG,tggdData,onbutClick,onItemClick]);
  const timeLineItems = useMemo(() => {
    if (!newsUpdateTimeList?.length) {
      return timeLineData;
    }
    const newsUpdateTimeObj = newsUpdateTimeList.reduce<{
      [key: string]: (typeof newsUpdateTimeList)[0];
    }>((prev, curr) => {
      return { ...prev, [curr.mediaTypeId]: curr };
    }, {});
    const res = timeLineData.map((item) => {
      const { mediaTypeId } = item;
      if (mediaTypeId && newsUpdateTimeObj[mediaTypeId]) {
        return {
          ...item,
          isUpdate: true,
          time: moment(
            newsUpdateTimeObj[mediaTypeId].updateTime,
            'YYYY-MM-DD HH:mm:ss',
          ).format('hhmm'),
        };
      }
      return item;
    });
    return res;
  }, [newsUpdateTimeList]);
  const handleDailyTaskItemClick = useCallback(
    (item: DailyTaskDataItemProps, index: number) => {
      if (item.path) {
        track('按钮点击',{
          btn_title:`今日必做|${item.title}`
        })
        navigator(item.path);
      }
    },
    [navigator],
  );
  const handleDailyTaskShareClick = useCallback(
    (func: string, item: DailyTaskDataItemProps, index: number) => {
      const news = dailyTaskShareData[item.mediaTypeId];
      const title=item.mediaTypeId === MEDIA_TYPE_NAMES.新闻联播?news?.title:`${moment(news?.updateTime, 'YYYY-MM-DD HH:mm:ss').format(
        'M.DD',
      )}${item.title}|${news?.title}`
      track('按钮点击',{
        page_id:'leaf1002',
        btn_title:`${item.title}转发|${PUSH_NAME[func]}`
      })
      onShare(func, () => {
        shareWxworkMessage(func, {
          title:title,
          link: getPushUrl(getFullPathFromMediaTypeIds(item.mediaTypeId, {
            id: news?.id,
            date:
              item.mediaTypeId === MEDIA_TYPE_NAMES.投资早班机
                ? moment(news?.updateTime, 'YYYY-MM-DD HH:mm:ss').format(
                    'YYYYMMDD',
                  )
                : undefined,
          }),wxUseData),
          imgUrl: item.imgUrl,
          desc: news?.shortTitle||item.description,
        });
      });
    },
    [onShare, dailyTaskShareData,wxUseData],
  );

  const [searchValue, setSearchValue] = useState('');
  const handleSearchEnterPress = useCallback(() => {
    if (searchValue) {
      navigator(`/mainSearchDetail?search=${searchValue}`);
      setSearchValue('');
    }
  }, [navigator, searchValue]);

  return (
    <div className={cls.wrap}>
      <Search
        placeholder="请输入搜索内容"
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e);
        }}
        onEnterPress={handleSearchEnterPress}
      />

  <Link to='/jxzx'>
    <button>测试用【精选资讯】</button>
  </Link>

      <div className={cls.wrapContent}>
        <PageSwiper regionName={PAGE_NAME} style={{ paddingBottom: '0.7500rem' }} onClick={()=>{
          track('按钮点击',{
            btn_title:`头部广告位`
          })
        }} />
        <DailyTask
          data={timeLineItems}
          onClick={handleDailyTaskItemClick}
          onShareClick={handleDailyTaskShareClick}
        />
        <Divider />
        <YZXNewsFlash  regionName={PAGE_NAME}/>
        <Divider />
        <DzjdTzrlCcrb />
        <Divider />
        <HotShareCard
          data={hotShareList || []}
          onbutClick={()=>onbutClick('分享热榜')}
          onShareClick={(func: string, item: API.INews, index: number)=>{handleNewsShareClick(func, item, index,'分享热榜')}}
        />
        <Divider />
      </div>
      <StickyTabs tabItems={tabItems} />
    </div>
  );
};

export default Main;
