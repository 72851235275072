import { useEffect } from 'react';
import { useNavigationType } from 'react-router-dom';

export default function useScrollToTop() {
  const navigationType = useNavigationType();
  useEffect(() => {
    if (['PUSH', 'REPLACE'].includes(navigationType)) {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);
}
