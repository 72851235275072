import React from 'react';

export interface MainStickyTabItemTitleProps {
  titleImg: string;
  style?: React.CSSProperties;
}
const MainStickyTabItemTitle: React.FC<MainStickyTabItemTitleProps> = ({
  titleImg,
  style,
}) => {
  return <img src={titleImg} alt="" style={{ height: '1.2500rem', ...style }} />;
};

export default MainStickyTabItemTitle;
