
import { useEffect, useState } from 'react';
import { APP_ID } from '../constants';
import { envjudge, getUrlParam } from '../utils/utils';
import { useQueryGetCustorInfomation } from '../services/api';

export const useWxUrlParam = () => {
  const [code, setCode] = useState('');
  const [useData, setUseData] = useState<API.IGetCustorInfomation|null|any>();
  const [pushData, setPushData] = useState<API.IGetCustorInfomation|null|any>();
  const { data } = useQueryGetCustorInfomation(code)
  useEffect(() => {
    if (!window.config.hiddenBusinessCard) {
      let link = window.location.href;
      const envResult = envjudge()
      if (getUrlParam(link, 'usedata')) {
        if (envResult === 'com-wx-mobile' || envResult === 'com-wx-pc') {
          setPushData(null)
        }else{
          setPushData(JSON.parse(decodeURIComponent(getUrlParam(link, 'usedata'))))
          setUseData(JSON.parse(decodeURIComponent(getUrlParam(link, 'usedata'))))
        }
      }
      if(envResult === 'com-wx-mobile' || envResult === 'com-wx-pc'){
        if (getUrlParam(link, 'code')) {
          setCode(getUrlParam(link, 'code'));
        } else {
          //重定向地址，就是用户授权后返回来的地址
          let uri = encodeURIComponent(link);
          let authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
          window.location.href = authURL;
        }
      }
     
    }
  }, []);
  useEffect(() => {
    if (data) {
      setUseData(data)
      if (!pushData) {
        setPushData(data)
      }
    }
  }, [data, pushData])
  return {
    code,
    useData,
    pushData
  };
};

