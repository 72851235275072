import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Loading from '../../components/Loading';
// import  cls from './PdfView.module.scss'
import { blobToBase64, urlToBase64 } from '../../utils/utils';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export interface Props {
  file: string;
}

const PdfView: React.FC<Props> = ({ file }) => {
  const [numPages, setNumPages] = useState(0);
  const [url, setUrl] = useState('');
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (file) {
      urlToBase64(file).then((blob: any) => {
        console.log(blob, '=====blob');
        blobToBase64(blob)
          .then((url: any) => {
            setUrl(url);
          })
          .catch((err) => {
            console.log(err, '===');
          });
      });
    }
  }, [file]);
  if (!url) return null;
  return (
    <Document
      onLoadError={(err) => {
        console.log('onLoadError---', err);
      }}
      file={url} // 文件地址
      onLoadSuccess={onDocumentLoadSuccess}
      loading={() => <Loading />}
      error={() => (
        <div
          style={{
            paddingTop: '20vh',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          加载失败，请检查地址是否正确！
        </div>
      )}
    >
      {new Array(numPages).fill('').map((cur, index) => (
        <Page
          key={index}
          width={window.screen.width}
          pageNumber={index + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      ))}
    </Document>
  );

  // const onClick=useCallback((file:string)=>{
  //   downloadPDF(file);
  // },[])
  // return (
  //   <div className={cls.warp}>
  //   <div onClick={()=>onClick(file)} className={cls.button}>下载</div>
  //   </div>

  // )
};
export default PdfView;
