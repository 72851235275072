/**
 * 首页-搜索
 */

import React, { useCallback, useMemo } from 'react';

import cls from './CJZBDetail.module.scss';
import useTitle from '../hooks/useTitle';
import useScrollToTop from '../hooks/useScrollToTop';

import ShareBtnBottom from '../components/ShareBtnBottom';

import useInitWxFunc from '../hooks/useInitWxFunc';
import { shareWxworkMessage } from '../utils/wxUtil';
import { useParams } from 'react-router-dom';
import { queryGetUpdateHost, useQueryMediaById } from '../services/api';
import { fmtCurrentDateTime2Str, fmtRoute2FullPath } from '../utils/tools';
import Tips from '../components/Tips';
import { getPushUrl } from '../utils/utils';
import { useGlobalState } from '../models/global';
import BusinessCard from './containers/BusinessCard';

const CJZBDetail = () => {
  useScrollToTop();
  useTitle('投资早班机');
  const { id } = useParams();
  const { data } = useQueryMediaById({ id });
  const [{wxUseData}]=useGlobalState()
  const shareInfo = useMemo(() => {
    if (!data?.id) {
      return undefined;
    }
    return {
      title: data?.title,
      link: getPushUrl(window.location.href,wxUseData),
      desc: data?.shortTitle,
      imgUrl: fmtRoute2FullPath('/shareImg/zbj.jpg'),
      success: () => {
        queryGetUpdateHost(data.id + '');
      },
    };
  }, [data,wxUseData]);
  const { onShare } = useInitWxFunc({ shareInfo });
  const handleNewsShareClick = useCallback(
    (func: string) => {
      queryGetUpdateHost(`${data?.id}`);
      onShare(func, () => {
        const { success, ...otherShareInfo } = shareInfo || {};
        shareWxworkMessage(func, otherShareInfo);
      });
    },
    [onShare, data,shareInfo],
  );

  return (
    <div className={cls.wrap}>
      <BusinessCard />
      <div className={cls.pageCard}>
        <div className={cls.title}>{data?.title || '--'}</div>
        <div className={cls.time}>
          {fmtCurrentDateTime2Str(data?.updateTime || '', {
            format: 'YYYY-MM-DD HH:mm:ss',
          })}
        </div>
        <div className={cls.card}>
          <div className={cls.subTitle}>{data?.shortTitle}</div>
        </div>
        <div className={cls.text}>
          <div
            className={cls.content + ' reach-text-content'}
            dangerouslySetInnerHTML={{ __html: data?.conten ?? '' }}
          ></div>
        </div>
      </div>
      <Tips textColor="#666666" style={{ padding: '1.2500rem 0.9375rem 1.2500rem' }} title={'以上内容来自财联社。'}/>
      <ShareBtnBottom onIconClick={handleNewsShareClick} />
    </div>
  );
};

export default CJZBDetail;
