// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConsultationZone_warp__6NItk {
  width: 100%;
  padding-top: 1.25rem;
}
.ConsultationZone_warp__6NItk .ConsultationZone_imgView__gjWAt {
  display: flex;
  justify-content: center;
  padding-bottom: 0.625rem;
}
.ConsultationZone_warp__6NItk .ConsultationZone_imgView__gjWAt img {
  width: 90%;
  height: 1.5625rem;
}
.ConsultationZone_warp__6NItk .ConsultationZone_contView__dozKE {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ConsultationZone_warp__6NItk .ConsultationZone_contView__dozKE .ConsultationZone_item__vINP0 {
  width: 48%;
  padding-top: 0.625rem;
}
.ConsultationZone_warp__6NItk .ConsultationZone_contView__dozKE .ConsultationZone_item__vINP0 .ConsultationZone_img__ZZPh2 {
  height: 5.3125rem;
  width: 100%;
}
.ConsultationZone_warp__6NItk .ConsultationZone_contView__dozKE .ConsultationZone_itemone__bSj0S {
  width: 100%;
  padding-top: 0.625rem;
}
.ConsultationZone_warp__6NItk .ConsultationZone_contView__dozKE .ConsultationZone_itemone__bSj0S .ConsultationZone_img__ZZPh2 {
  height: 5.3125rem;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/containers/ConsultationZone.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,oBAAA;AACJ;AAAI;EACI,aAAA;EACA,uBAAA;EACA,wBAAA;AAER;AADQ;EACI,UAAA;EACA,iBAAA;AAGZ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;AAER;AADQ;EACI,UAAA;EACA,qBAAA;AAGZ;AAFY;EACI,iBAAA;EACA,WAAA;AAIhB;AADQ;EACI,WAAA;EACA,qBAAA;AAGZ;AAFY;EACI,iBAAA;EACA,WAAA;AAIhB","sourcesContent":[".warp {\r\n    width: 100%;\r\n    padding-top: 1.2500rem;\r\n    .imgView {\r\n        display: flex;\r\n        justify-content: center;\r\n        padding-bottom: 0.6250rem;\r\n        img {\r\n            width: 90%;\r\n            height: 1.5625rem;\r\n        }\r\n    }\r\n    .contView {\r\n        display: flex;\r\n        flex-direction:row;\r\n        flex-wrap:wrap;\r\n        justify-content: space-between;\r\n        .item {\r\n            width: 48%;\r\n            padding-top: 0.6250rem;\r\n            .img {\r\n                height: 5.3125rem;\r\n                width: 100%;\r\n            }\r\n        }\r\n        .itemone {\r\n            width: 100%;\r\n            padding-top: 0.6250rem;\r\n            .img {\r\n                height: 5.3125rem;\r\n                width: 100%;\r\n            }\r\n        }\r\n        \r\n    }\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warp": `ConsultationZone_warp__6NItk`,
	"imgView": `ConsultationZone_imgView__gjWAt`,
	"contView": `ConsultationZone_contView__dozKE`,
	"item": `ConsultationZone_item__vINP0`,
	"img": `ConsultationZone_img__ZZPh2`,
	"itemone": `ConsultationZone_itemone__bSj0S`
};
export default ___CSS_LOADER_EXPORT___;
