/**
 * 大涨股解读、投资日历、自选股/持仓股日报栏目入口
 */

import styles from './DzjdTzrlCcrbItem.module.scss';
import React, { useCallback} from 'react';
import '../common.scss';
import ShareBtn from '../../components/ShareBtn';

export interface DzjdTzrlCcrbItemDataProps {
  onClick1?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick2?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick3?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onShareClick?: (func: string) => void;
  onbutClick?:() => void;
}

const DzjdTzrlCcrbItem: React.FC<DzjdTzrlCcrbItemDataProps> = (props) => {
  const { onShareClick, onClick1, onClick2, onClick3, onbutClick } = props;
  const handleCardClick1 = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (onClick1) {
        onClick1(e);
      }
    },
    [onClick1],
  );
  const handleCardClick2 = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (onClick2) {
        onClick2(e);
      }
    },
    [onClick2],
  );
  const handleCardClick3 = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (onClick3) {
        onClick3(e);
      }
    },
    [onClick3],
  );
  return (
    <div className={`${styles['wrap']} flex-row justify-between`}>
        <div className={`${styles['group_13']} flex-col`}>
          <div onClick={handleCardClick1} className={`${styles['block_1']} flex-col`}>
            <div className={`${styles['section_3']} flex-row justify-between`}>
              <span className={`${styles['text_1']}`}>大涨解读</span>
              <img
                className={`${styles['label_1']}`}
                src={require('../../images/dayuhao.png')}
                alt=''
              />
            </div>
            <div className={`${styles['section_4']} flex-row justify-between`}>
              <span className={`${styles['text_2']}`}>抢先一步，抓机会</span>
              <ShareBtn
                onIconClick={onShareClick}
                className={`${styles['text-wrapper_1']} flex-col`}
                onbutClick={onbutClick}
              >
                <span className={`${styles['text_3']}`}>分享</span>
              </ShareBtn>
            </div>
          </div>
          <div onClick={handleCardClick2} className={`${styles['block_2']} flex-col`}>
            <div className={`${styles['box_16']} flex-row justify-between`}>
              <span className={`${styles['text_4']}`}>投资日历</span>
              <img
                className={`${styles['label_2']}`}
                src={require('../..//images/dayuhao.png')}
                alt=''
              />
            </div>
            <div className={`${styles['box_17']} flex-row justify-between`}>
              <span className={`${styles['text_5']}`}>把握投资每一天</span>
              <ShareBtn
                onIconClick={onShareClick}
                className={`${styles['text-wrapper_2']} flex-col`}
                onbutClick={onbutClick}
                >
                <span className={`${styles['text_6']}`}>分享</span>
              </ShareBtn>
            </div>
          </div>
        </div>
        <div onClick={handleCardClick3} className={`${styles['group_3']} flex-col`}>
          <div className={`${styles['text-wrapper_25']} flex-row`}>
            <span className={`${styles['text_7']}`}>持仓日报</span>
          </div>
          <div className={`${styles['group_14']} flex-row justify-between`}>
            <span className={`${styles['text_8']}`}>
              以客户持仓股为
              <br />
              核心，展示相关的
              <br />
              行情动态及异动解读。
            </span>
            <ShareBtn
                onIconClick={onShareClick}
                className={`${styles['text-wrapper_3']} flex-col`}
                onbutClick={onbutClick}
              >
                <span className={`${styles['text_9']}`}>分享</span>
              </ShareBtn>
          </div>
          <div className={`${styles['text-wrapper_26']} flex-row`}>
            <span className={`${styles['text_10']}`}>统计截止T-1</span>
          </div>
        </div>
      </div>
    );
  
};

export default DzjdTzrlCcrbItem;
