/**
 * 题材掘金列表
 */

import React from 'react';
import ItemCardHorizontal from '../../components/ItemCardHorizontal';
import { SendOutline } from 'antd-mobile-icons';
import { MEDIA_TYPE_NAMES } from '../../constants';
import useQueryNewsInfinate from '../../hooks/useQueryNewsInfinate';
import {
  fmtCurrentDateTime2Str,
  fmtRoute2FullPath,
  randomImg,
} from '../../utils/tools';
import { InfiniteScroll } from 'antd-mobile';
import Loading from '../../components/Loading';
import Nodata from '../../components/Nodata';
import useJump from '../../hooks/useJump';

export interface TCJJListProps {
  searchValue?: string;
  count?: number;
  isLoadMore?: boolean;
  loadingColor?: string;
  onShare?: (func: string, item: API.INews, index: number) => void;
  onbutClick?: () => void;
  onItemClick?: () => void;
}
const TCJJList: React.FC<TCJJListProps> = ({
  searchValue,
  count,
  isLoadMore,
  loadingColor,
  onShare,
  onbutClick,
  onItemClick
}) => {
  const { jumpNewsDetailFromMediaType } = useJump();
  const { data, loading, loadMore, hasMore } = useQueryNewsInfinate({
    mediaTypeId: MEDIA_TYPE_NAMES.题材掘金,
    searchValue,
    count,
  });
  return (
    <React.Fragment>
      {data?.map((item, index) => (
        <ItemCardHorizontal
          key={item.id}
          shereid={item.id}
          img={item.img || randomImg()}
          title={item.title}
          description={item.shortTitle}
          onbutClick={onbutClick}
          time={
            <span>
              {item?.updateTime
                ? fmtCurrentDateTime2Str(item.updateTime, { format: 'MM-DD' })
                : null}
              &nbsp;&nbsp;
              {item.hostMedia ? (
                <>
                  <SendOutline />
                  &nbsp;{item.hostMedia}
                </>
              ) : null}
            </span>
          }
          share
          onItemClick={() => {
            if(onItemClick){
              onItemClick()
            }
            jumpNewsDetailFromMediaType(MEDIA_TYPE_NAMES.题材掘金, {
              url: item.url,
              id: item.id,
            });
          }}
          onShareClick={(func) =>
            onShare?.(
              func,
              { ...item, img: fmtRoute2FullPath('/shareImg/tcjj.jpg') },
              index,
            )
          }
          customerList={item.relatedCustomers}
          stockList={item.relatedStocks}
        />
      ))}
      {!loading && !data?.length && (
        <Nodata content={searchValue ? '暂未搜索到相关数据' : '暂无数据'} />
      )}
      {/* 初始化loading */}
      {!data && loading ? (
        <Loading loadingColor={loadingColor} />
      ) : (
        hasMore &&
        !!data &&
        isLoadMore &&
        !loading && (
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <Loading loadingColor={loadingColor} />
          </InfiniteScroll>
        )
      )}
    </React.Fragment>
  );
};

export default TCJJList;
