import React, { useCallback, useState } from 'react';
import cls from './ShareBtn.module.scss';
import { Popup } from 'antd-mobile';
import { WX_AGENT_FUNC_NAMES } from '../constants';
import { useGlobalState } from '../models/global';
import { queryGetUpdateHost, queryInvestmentAdviser } from '../services/api';

const shareList = [
  {
    img: require('../images/ShareZF.png'),
    label: '转发',
    func: WX_AGENT_FUNC_NAMES.转发到企微好友,
  },
  {
    img: require('../images/ShareQF.png'),
    label: '群发',
    func: WX_AGENT_FUNC_NAMES.群发客户,
  },
  {
    img: require('../images/SharePYQ.png'),
    label: '发朋友圈',
    func: WX_AGENT_FUNC_NAMES.转发到客户朋友圈,
  },
  {
    img: require('../images/ShareWX.png'),
    label: '分享微信',
    func: WX_AGENT_FUNC_NAMES.转发到微信好友,
  },
  {
    img: require('../images/ShareXGKH.png'),
    label: '相关客户',
    func: WX_AGENT_FUNC_NAMES.群发客户,
  },
];
export interface ShareBtnProps {
  children?: any;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCancel?: () => void;
  onIconClick?: (
    func: (typeof WX_AGENT_FUNC_NAMES)[keyof typeof WX_AGENT_FUNC_NAMES],
  ) => void;
  onbutClick?: () => void;
  popup?: boolean;
  className?: string;
  style?: React.CSSProperties;
  shereid?: string | number;
  isTG?:boolean;
}
const ShareBtn: React.FC<ShareBtnProps> = ({
  children,
  onClick,
  onCancel,
  onIconClick,
  className,
  style,
  popup = true,
  shereid,
  isTG=false,
  onbutClick
}) => {
  const [{ wxAgentInitSuccess }] = useGlobalState();
  const [popupVisible, setPopupVisible] = useState(false);
  const handleShareClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if(onbutClick){
        onbutClick()
      }
      if (shereid) {
        isTG?queryInvestmentAdviser(Number(shereid)):queryGetUpdateHost(`${shereid}`);
      }
      e.stopPropagation();
      setPopupVisible(true);
      onClick?.(e);
    },
    [onClick, shereid,isTG,onbutClick],
  );
  const handleCancleSharePopup = useCallback(() => {
    setPopupVisible(false);
    onCancel?.();
  }, [onCancel]);

  const handleShareIconClick = useCallback(
    (item: (typeof shareList)[0], index: number) => {
      onIconClick?.(item.func);
      setPopupVisible(false);
    },
    [onIconClick],
  );
  return (
    <React.Fragment>
      {wxAgentInitSuccess ? (
        <>
          <div
            onClick={handleShareClick}
            style={style}
            className={`${cls.wrap} ${className}`}
          >
            {children || '分享'}
          </div>
          <Popup visible={popup && popupVisible}>
            <div className={cls.sharePopupWrap}>
              <div className={cls.sharePopupContent}>
                {shareList.map((item, index) => (
                  <div
                    key={index}
                    className={cls.sharePopupShareItem}
                    onClick={() => handleShareIconClick(item, index)}
                  >
                    <div className={cls.shareIconWrap}>
                      <img src={item.img} alt="" />
                    </div>
                    <span className={cls.shareLabel}>{item.label}</span>
                  </div>
                ))}
              </div>
              <div
                className={cls.sharePopupCancel}
                onClick={handleCancleSharePopup}
              >
                取消
              </div>
            </div>
          </Popup>
        </>
      ) : null}
    </React.Fragment>
  );
};

export default ShareBtn;
