/**
 * 首页-搜索
 */

import React, { useCallback, useMemo, useState } from 'react';
import { Tabs } from 'antd-mobile';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Search from '../components/Search';
import { useQueryMediaTypeList } from '../services/api';
import Card from '../components/Card';
import Divider from '../components/Divider';
// import ExcitingActivityList from './containers/ExcitingActivityList';
import TCJJList from './containers/TCJJList';
import ZHDJList from './containers/ZHDJList';
import YZXList from './containers/YZXList';
// import YZBList from './containers/YZBList';
// import YTJList from './containers/YTJList';
import {
  // MEDIA_TYPE_ID_CONNECTOR,
  MEDIA_TYPE_NAMES,
  URL_NAMES,
  YZX_KEY_STR,
} from '../constants';
import cls from './MainSearchDetail.module.scss';
import useTitle from '../hooks/useTitle';
import YZXNewsFlash from './containers/YZXNewsFlash';
import useScrollToTop from '../hooks/useScrollToTop';
import useInitWxFunc from '../hooks/useInitWxFunc';
import { shareWxworkMessage } from '../utils/wxUtil';
import { getFullPathFromMediaTypeIds } from '../utils/tools';
import { useGlobalState } from '../models/global';
import { getPushUrl } from '../utils/utils';

const MainSearchDetail = () => {
  useScrollToTop();
  useTitle('资讯中心');
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const { data: tabItems = [] } = useQueryMediaTypeList();
  const { searchMemo, activeTab } = useMemo(() => {
    return {
      searchMemo: searchParams.get('search') ?? '',
      activeTab: searchParams.get('activeTab') ?? tabItems[0]?.mediaTypeId,
    };
  }, [searchParams, tabItems]);

  const [searchValue, setSearchValue] = useState(searchMemo);
  const handleSearchEnterPress = useCallback(() => {
    if (searchValue) {
      searchParams.set('search', searchValue);
      navigator(URL_NAMES.首页搜索 + '?' + searchParams.toString(), {
        replace: true,
      });
    } else {
      searchParams.delete('search');
      const searchStr = searchParams.toString();
      navigator(`${URL_NAMES.首页搜索}${searchStr ? '?' + searchStr : ''}`, {
        replace: true,
      });
    }
  }, [searchParams, navigator, searchValue]);

  const handleTabChange = useCallback(
    (key: string) => {
      searchParams.set('activeTab', key);
      navigator(URL_NAMES.首页搜索 + '?' + searchParams.toString(), {
        replace: true,
      });
    },
    [searchParams, navigator],
  );
  const { onShare } = useInitWxFunc();
  const [{wxUseData}]=useGlobalState()
  const handleNewsShareClick = useCallback(
    (func: string, item: API.INews, index: number) => {
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: item.title,
          link: getPushUrl(getFullPathFromMediaTypeIds(item.mediaType, {
            url: item.url,
            id: item.id,
          }),wxUseData),
          desc: item.shortTitle,
          imgUrl: item.img,
        });
      });
    },
    [onShare,wxUseData],
  );
  const renderEleFromActiveTab = useMemo(() => {
    switch (activeTab) {
      // case MEDIA_TYPE_NAMES.精彩活动:
      //   return (
      //     <ExcitingActivityList
      //       isLoadMore
      //       showInfo
      //       searchValue={searchMemo}
      //       count={20}
      //     />
      //   );
      case MEDIA_TYPE_NAMES.题材掘金:
        return (
          <TCJJList
            searchValue={searchMemo}
            count={20}
            isLoadMore
            onShare={handleNewsShareClick}
          />
        );
      case MEDIA_TYPE_NAMES.中航独家:
        return (
          <ZHDJList
            searchValue={searchMemo}
            count={20}
            isLoadMore
            onShare={handleNewsShareClick}
          />
        );
      // case MEDIA_TYPE_NAMES.翼直播:
      //   return <YZBList searchValue={searchMemo} count={20} isLoadMore onShare={handleNewsShareClick} />;
      case YZX_KEY_STR:
        return (
          <>
            <YZXNewsFlash  regionName='资讯中心'/>
            <YZXList
              showSelector
              searchValue={searchMemo}
              count={20}
              isLoadMore
              onShare={handleNewsShareClick}
            />
          </>
        );
      // case `${MEDIA_TYPE_NAMES.翼投教图文}${MEDIA_TYPE_ID_CONNECTOR}${MEDIA_TYPE_NAMES.翼投教视频}`:
      //   return <YTJList searchValue={searchMemo} onShare={handleNewsShareClick}/>;
      default:
        return null;
    }
  }, [activeTab, searchMemo, handleNewsShareClick]);

  return (
    <div className={cls.wrap}>
      <Search
        placeholder="请输入搜索内容"
        value={searchValue}
        onChange={setSearchValue}
        onEnterPress={handleSearchEnterPress}
      />
      <div>
        <Tabs
          activeLineMode="fixed"
          style={{
            position: 'sticky',
            zIndex: 101,
            top: 0,
            '--fixed-active-line-width': '1.1875rem',
            '--content-padding': '0',
          }}
          onChange={handleTabChange}
          activeKey={activeTab}
        >
          {tabItems.map((item) => (
            <Tabs.Tab title={item.mediaTypeName} key={item.mediaTypeId} />
          ))}
        </Tabs>
        <Card>{renderEleFromActiveTab}</Card>
        <Divider height="20" />
      </div>
    </div>
  );
};

export default MainSearchDetail;
