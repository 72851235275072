// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CJZBMarketIndexCard_wrap__E9oeO {
  padding: 0.8125rem 0.625rem;
  text-align: center;
}
.CJZBMarketIndexCard_wrap__E9oeO .CJZBMarketIndexCard_title__SRII- {
  color: rgb(37, 47, 66);
  margin-bottom: 0.875rem;
  font-size: 0.8125rem;
}
.CJZBMarketIndexCard_wrap__E9oeO .CJZBMarketIndexCard_index__JPUtW {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0.875rem;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.CJZBMarketIndexCard_wrap__E9oeO .CJZBMarketIndexCard_info__I8w45 {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
}
.CJZBMarketIndexCard_wrap__E9oeO.CJZBMarketIndexCard_up__4kj3X {
  background-image: linear-gradient(0deg, #fef1ee, #faf9f9);
  color: #ec3030;
}
.CJZBMarketIndexCard_wrap__E9oeO.CJZBMarketIndexCard_down__j35xs {
  background-image: linear-gradient(0deg, #f1fef6, #fafbfa);
  color: #02b53d;
}
.CJZBMarketIndexCard_wrap__E9oeO.CJZBMarketIndexCard_dft__a3BNx {
  background-image: linear-gradient(180deg, rqba(252, 252.252, 1) 0%, rgb(247, 247, 247) 100%);
  color: rgb(89, 95, 118);
}`, "",{"version":3,"sources":["webpack://./src/pages/components/CJZBMarketIndexCard.module.scss"],"names":[],"mappings":"AAWA;EACE,2BAAA;EACA,kBAAA;AAVF;AAWE;EACE,sBAAA;EACA,uBAAA;EACA,oBAAA;AATJ;AAWE;EACE,iBAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AATJ;AAWE;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;AATJ;AAWE;EACE,yDA9BQ;EA+BR,cAlCU;AAyBd;AAWE;EACE,yDAjCU;EAkCV,cArCY;AA4BhB;AAWE;EACE,4FApCQ;EAqCR,uBAxCU;AA+Bd","sourcesContent":["$upTextColor: #ec3030;\r\n$downTextColor: #02b53d;\r\n$dfTextColor: rgba(89, 95, 118, 1);\r\n$upBlockBg: linear-gradient(0deg, #fef1ee, #faf9f9);\r\n$downBlockBg: linear-gradient(0deg, #f1fef6, #fafbfa);\r\n$dfBlockBg: linear-gradient(\r\n  180deg,\r\n  rqba(252, 252.252, 1) 0%,\r\n  rgba(247, 247, 247, 1) 100%\r\n);\r\n\r\n.wrap {\r\n  padding: 0.8125rem 0.6250rem;\r\n  text-align: center;\r\n  .title {\r\n    color: rgba(37, 47, 66, 1);\r\n    margin-bottom: 0.8750rem;\r\n    font-size: 0.8125rem;\r\n  }\r\n  .index {\r\n    font-weight: bold;\r\n    font-size: 1.2500rem;\r\n    margin-bottom: 0.8750rem;\r\n    white-space: nowrap;\r\n    overflow-x: auto;\r\n    overflow-y: hidden;\r\n  }\r\n  .info {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    font-size: 0.7500rem;\r\n  }\r\n  &.up {\r\n    background-image: $upBlockBg;\r\n    color: $upTextColor;\r\n  }\r\n  &.down {\r\n    background-image: $downBlockBg;\r\n    color: $downTextColor;\r\n  }\r\n  &.dft {\r\n    background-image: $dfBlockBg;\r\n    color: $dfTextColor;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `CJZBMarketIndexCard_wrap__E9oeO`,
	"title": `CJZBMarketIndexCard_title__SRII-`,
	"index": `CJZBMarketIndexCard_index__JPUtW`,
	"info": `CJZBMarketIndexCard_info__I8w45`,
	"up": `CJZBMarketIndexCard_up__4kj3X`,
	"down": `CJZBMarketIndexCard_down__j35xs`,
	"dft": `CJZBMarketIndexCard_dft__a3BNx`
};
export default ___CSS_LOADER_EXPORT___;
