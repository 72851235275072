// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FullImg_wrap__Sv3Vo {
  border-radius: 0.625rem;
  overflow: hidden;
}
.FullImg_wrap__Sv3Vo .FullImg_img__JBrBx {
  border-radius: 0.625rem;
  background-size: cover;
  background-repeat: no-repeat;
}
.FullImg_wrap__Sv3Vo .FullImg_info__Y\\+SYn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  padding: 0.9375rem;
}
.FullImg_wrap__Sv3Vo .FullImg_info__Y\\+SYn .FullImg_title__jNuYB {
  color: #1e1e1e;
  font-size: 1.0625rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.625rem;
}
.FullImg_wrap__Sv3Vo .FullImg_info__Y\\+SYn .FullImg_extra__BGsX0 {
  color: #666666;
  font-weight: lighter;
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/FullImg.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,gBAAA;AACF;AAAE;EACE,uBAAA;EACA,sBAAA;EACA,4BAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,cAAA;EACA,kBAAA;AAEJ;AADI;EACE,cAAA;EACA,oBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,sBAAA;AAGN;AADI;EACE,cAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,YAAA;AAGN","sourcesContent":[".wrap {\r\n  border-radius: 0.6250rem;\r\n  overflow: hidden;\r\n  .img {\r\n    border-radius: 0.6250rem;\r\n    background-size: cover;\r\n    background-repeat: no-repeat;\r\n  }\r\n  .info {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    line-height: 1;\r\n    padding: 0.9375rem;\r\n    .title {\r\n      color: #1e1e1e;\r\n      font-size: 1.0625rem;\r\n      font-weight: bold;\r\n      white-space: nowrap;\r\n      overflow: hidden;\r\n      text-overflow: ellipsis;\r\n      margin-right: 0.6250rem;\r\n    }\r\n    .extra {\r\n      color: #666666;\r\n      font-weight: lighter;\r\n      font-size: 0.7500rem;\r\n      white-space: nowrap;\r\n      overflow: hidden;\r\n      flex-shrink: 0;\r\n      flex-grow: 0;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `FullImg_wrap__Sv3Vo`,
	"img": `FullImg_img__JBrBx`,
	"info": `FullImg_info__Y+SYn`,
	"title": `FullImg_title__jNuYB`,
	"extra": `FullImg_extra__BGsX0`
};
export default ___CSS_LOADER_EXPORT___;
