import React, { useCallback } from 'react';
import Card from '../../components/Card';
import IconNo1 from '../../images/IconNo1.png';
import IconNo2 from '../../images/IconNo2.png';
import IconNo3 from '../../images/IconNo3.png';
import ShareBtn from '../../components/ShareBtn';
import cls from './HotShareCard.module.scss';
import { Skeleton } from 'antd-mobile';
import useJump from '../../hooks/useJump';
import { fmtRoute2FullPath } from '../../utils/tools';
import { mediaTypeToImg } from '../../utils/utils';
import { track } from '../../utils/sdk';

export interface HotShareCardProps {
  data?: API.INews[];
  onShareClick?: (func: string, item: API.INews, index: number) => void;
  onbutClick?:()=>void
}

function getIconFromIndex(index: number) {
  switch (index) {
    case 0:
      return IconNo1;
    case 1:
      return IconNo2;
    case 2:
      return IconNo3;
    default:
      return;
  }
}
const HotShareCard: React.FC<HotShareCardProps> = ({
  data = [],
  onShareClick,
  onbutClick
}) => {
  const { jumpNewsDetailFromMediaType } = useJump();
  const handleShareItemClick = useCallback(
    (item: API.INews) => {
      track('按钮点击',{
        btn_title:'分享热榜|内容'
      })
      jumpNewsDetailFromMediaType(item.mediaType, {
        url: item.url,
        id: item.id,
      });
    },
    [jumpNewsDetailFromMediaType],
  );

  return (
    <div className={cls.wrap}>
      <img
        className={cls.title}
        src={require('../../images/HotShareCardTitle.png')}
        alt=""
      />
      <Card styles={{ margin: '0', padding: '0.9375rem 0.5000rem' }}>
        {data?.length ? (
          data.slice(0, 3).map((item, index) => (
            <div
              className={cls.item}
              key={item.id}
              onClick={() => {
                handleShareItemClick(item);
              }}
            >
              <img className={cls.icon} src={getIconFromIndex(index)} alt="" />
              <div className={cls.itemTitle}>{item.title}</div>
              <ShareBtn
                shereid={item.id}
                onbutClick={onbutClick}
                onIconClick={(func) =>
                  onShareClick?.(
                    func,
                    {
                      ...item,
                      img: fmtRoute2FullPath(mediaTypeToImg[item.mediaType]),
                    },
                    index,
                  )
                }
              />
            </div>
          ))
        ) : (
          <Skeleton.Paragraph lineCount={3} animated />
        )}
      </Card>
    </div>
  );
};

export default HotShareCard;
