// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShareBtn_wrap__sLrqc {
  display: inline-block;
  border: 0.0625rem solid #3975c6;
  border-radius: 0.75rem;
  font-size: 0.8125rem;
  color: #3975c6;
  line-height: 1.25rem;
  padding: 0 0.75rem;
  flex-basis: 0;
  white-space: nowrap;
}

.ShareBtn_sharePopupWrap__eG6Ir {
  background-color: #eaeaea;
}

.ShareBtn_sharePopupContent__-5W9g {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.375rem 1.5625rem 0.875rem 1.5625rem;
  border-bottom: 0.0625rem solid #d3d3d3;
}
.ShareBtn_sharePopupContent__-5W9g .ShareBtn_sharePopupShareItem__64YJ3 {
  display: flex;
  flex-direction: column;
  font-size: 0.9375rem;
  color: #182445;
  text-align: center;
}
.ShareBtn_sharePopupContent__-5W9g .ShareBtn_sharePopupShareItem__64YJ3 .ShareBtn_shareIconWrap__t\\+svS {
  width: 3.375rem;
  height: 3.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 0.625rem;
  align-items: center;
  margin-bottom: 0.75rem;
}
.ShareBtn_sharePopupContent__-5W9g .ShareBtn_sharePopupShareItem__64YJ3 .ShareBtn_shareIconWrap__t\\+svS > img {
  width: 1.875rem;
}

.ShareBtn_sharePopupCancel__IFXtX {
  font-size: 0.9375rem;
  color: #182445;
  text-align: center;
  padding: 1.125rem;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ShareBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,+BAAA;EACA,sBAAA;EACA,oBAAA;EACA,cAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,8CAAA;EACA,sCAAA;AAEF;AADE;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,cAAA;EACA,kBAAA;AAGJ;AAFI;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAIN;AAHM;EACE,eAAA;AAKR;;AAAA;EACE,oBAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AAGF","sourcesContent":[".wrap {\r\n  display: inline-block;\r\n  border: 0.0625rem solid #3975c6;\r\n  border-radius: 0.7500rem;\r\n  font-size: 0.8125rem;\r\n  color: #3975c6;\r\n  line-height: 1.2500rem;\r\n  padding: 0 0.7500rem;\r\n  flex-basis: 0;\r\n  white-space: nowrap;\r\n}\r\n\r\n.sharePopupWrap {\r\n  background-color: #eaeaea;\r\n}\r\n.sharePopupContent {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  padding: 1.3750rem 1.5625rem 0.8750rem 1.5625rem;\r\n  border-bottom: 0.0625rem solid #d3d3d3;\r\n  .sharePopupShareItem {\r\n    display: flex;\r\n    flex-direction: column;\r\n    font-size: 0.9375rem;\r\n    color: #182445;\r\n    text-align: center;\r\n    .shareIconWrap {\r\n      width: 3.3750rem;\r\n      height: 3.3750rem;\r\n      display: flex;\r\n      align-items: center;\r\n      justify-content: center;\r\n      background-color: #ffffff;\r\n      border-radius: 0.6250rem;\r\n      align-items: center;\r\n      margin-bottom: 0.7500rem;\r\n      > img {\r\n        width: 1.8750rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n.sharePopupCancel {\r\n  font-size: 0.9375rem;\r\n  color: #182445;\r\n  text-align: center;\r\n  padding: 1.1250rem;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ShareBtn_wrap__sLrqc`,
	"sharePopupWrap": `ShareBtn_sharePopupWrap__eG6Ir`,
	"sharePopupContent": `ShareBtn_sharePopupContent__-5W9g`,
	"sharePopupShareItem": `ShareBtn_sharePopupShareItem__64YJ3`,
	"shareIconWrap": `ShareBtn_shareIconWrap__t+svS`,
	"sharePopupCancel": `ShareBtn_sharePopupCancel__IFXtX`
};
export default ___CSS_LOADER_EXPORT___;
