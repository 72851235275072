import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addParam2Search } from '../utils/tools';
import {
  MEDIA_TYPE_ID_CONNECTOR,
  MEDIA_TYPE_NAMES,
  URL_NAMES,
} from '../constants';

const useJump = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const jumpNewsDetailFromMediaType = useCallback(
    (
      mediaTypeIds: string, // 多个mediaTypeId使用连接符链接
      options?: { url?: string; id?: number; type?: number; date?: string },
    ) => {
      const type = mediaTypeIds.split(MEDIA_TYPE_ID_CONNECTOR)[0];
      const { date = '', id = '' } = options || {};
      switch (type) {
        case MEDIA_TYPE_NAMES.投资早班机:
          navigator(URL_NAMES.投资早班机 + '/' + date); // 投资早班机的id字段传日期即可
          break;
        case MEDIA_TYPE_NAMES.午间快评:
          navigator(URL_NAMES.午间快评 + '/' + id);
          break;
        case MEDIA_TYPE_NAMES.焦点复盘:
          navigator(URL_NAMES.焦点复盘 + '/' + id);
          break;
        case MEDIA_TYPE_NAMES.明日洞察:
          navigator(URL_NAMES.明日洞察 + '/' + id);
          break;
        case MEDIA_TYPE_NAMES.每日收评:
          navigator(URL_NAMES.每日收评 + '/' + id);
          break;
        case MEDIA_TYPE_NAMES.盘后公告:
          navigator(URL_NAMES.盘后公告 + '/' + id);
          break;
        case MEDIA_TYPE_NAMES.新闻联播:
          navigator(URL_NAMES.新闻联播 + '/' + id);
          break;
        case MEDIA_TYPE_NAMES.精彩活动:
        case MEDIA_TYPE_NAMES.翼直播:
        case MEDIA_TYPE_NAMES.翼投教图文:
        case MEDIA_TYPE_NAMES.翼投教视频: {
          if (options?.url) {
            window.open(options.url,'_blank')
            // window.location.href = options.url;
          }
          break;
        }
        case MEDIA_TYPE_NAMES.题材掘金: {
          if (options?.id) {
            const url = URL_NAMES.题材掘金详情 + '/' + options.id;
            navigator(url);
          }
          break;
        }
        case MEDIA_TYPE_NAMES.中航独家: {
          if (options?.id) {
            const url = URL_NAMES.中航独家详情 + '/' + options.id;
            if (options?.url && options?.type === 0) {
              // window.location.href = options.url;
              window.open(options.url,'_blank')
            } else {
              navigator(url);
            }
          }
          break;
        }
        case MEDIA_TYPE_NAMES.电报:
          break;
        case MEDIA_TYPE_NAMES.翼资讯科创:
        case MEDIA_TYPE_NAMES.翼资讯宏观:
        case MEDIA_TYPE_NAMES.翼资讯股市:
        case MEDIA_TYPE_NAMES.翼资讯公司:
        case MEDIA_TYPE_NAMES.翼资讯地产:
        case MEDIA_TYPE_NAMES.翼资讯金融:
        case MEDIA_TYPE_NAMES.翼资讯基金:
        case MEDIA_TYPE_NAMES.翼资讯环球: {
          if (options?.id) {
            const url = URL_NAMES.翼资讯详情 + '/' + options.id;
            navigator(url);
          }
          break;
        }
        default:
          break;
      }
    },
    [navigator],
  );
  const addParam2SearchAndNavigate2Url = useCallback(
    (
      url: string,
      options: { param: { key: string; value: string }; replace?: boolean },
    ) => {
      const jumpUrl = addParam2Search(url, {
        search: location.search,
        param: options.param,
      });
      navigator(jumpUrl, { replace: options.replace });
    },
    [location, navigator],
  );

  return {
    jumpNewsDetailFromMediaType,
    addParam2SearchAndNavigate2Url,
  };
};

export default useJump;
