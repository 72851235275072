import {
  AGENT_ID,
  APP_ID,
  GRWX_APP_ID,
  PLATFORM_TYPES,
  WX_AGENT_CONFIG_API_LIST,
  WX_AGENT_FUNC_NAMES,
  WX_CONFIG_API_LIST,
} from '../constants';
import sha1 from 'js-sha1';
import { env } from './tools';

function getSignature(ticket: string) {
  const signatureData = {
    jsapi_ticket: ticket,
    noncestr: 'noncestr',
    timestamp: Math.ceil(new Date().getTime() / 1000),
    url: window.location.href,
  };
  const signData = Object.keys(signatureData).sort() as Array<
    keyof typeof signatureData
  >;
  const signStr = signData
    .map((value) => {
      return `${value}=${signatureData[value]}`;
    })
    .join('&');
  return {
    timestamp: signatureData.timestamp, // 必填，生成签名的时间戳
    nonceStr: signatureData.noncestr, // 必填，生成签名的随机串
    signature: sha1(signStr), // 必填，签名
  };
}

const configInfo =
  env === PLATFORM_TYPES.企业微信
    ? {
        appId: APP_ID,
        agentId: AGENT_ID,
      }
    : env === PLATFORM_TYPES.微信
    ? {
        appId: GRWX_APP_ID,
      }
    : {};
export async function initWxConfig(ticket?: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    if (!configInfo.appId) {
      reject('initWxConfig 没有appId');
      return;
    }
    if (!ticket) {
      reject('initWxConfig ticket不能为空');
      return;
    }
    try {
      const config = {
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId: configInfo.appId, // 必填，公众号的唯一标识
        ...getSignature(ticket),
        jsApiList: [...WX_CONFIG_API_LIST], // 必填，需要使用的 JS 接口列表
      };
      // @ts-ignore
      window.wx.config(config);
      const timeOut = setTimeout(() => {
        resolve({
          err: 'err',
          errmsg: 'wxReady超时',
        });
      }, 5000);

      window.wx.ready((res: any) => {
        clearTimeout(timeOut);
        resolve({
          success: 'ok',
        });
      });
    } catch (e) {
      resolve({
        err: 'err',
        errmsg: '出错了',
      });
    }
  });
}

export async function initWxAgentConfig(ticket?: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    if (!ticket) {
      reject('initWxAgentConfig ticket不能为空');
      return;
    }
    if (!configInfo.appId || !configInfo.agentId) {
      reject({
        err: 'err',
        errmsg: 'appId或agentId未拿到！',
      });
    }
    await waitForAgentConfigReady(window.wx);
    const agentConfigInfo = {
      corpid: configInfo.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
      agentid: configInfo.agentId, // 必填，企业微信的应用id （e.g. 1000247）
      ...getSignature(ticket),
      jsApiList: WX_AGENT_CONFIG_API_LIST, //必填，传入需要使用的接口名称
    };
    // 只有企业微信手机版可以调用
    window.wx.agentConfig({
      ...agentConfigInfo,
      success: function (res: any) {
        console.log('agentConfig 初始化成功', agentConfigInfo);
        // 安卓checkResult拿不到，这里是undefined，为了将成功回调和失败回调区分，此处传入空数组
        resolve(res.checkResult || {});
      },
      fail: function (err: any) {
        console.error('agentConfig err', err);
        if (err.errMsg.indexOf('function not exist') > -1) {
          alert('版本过低请升级');
          reject({
            err: 'err',
            errmsg: '版本过低请升级',
          });
        } else {
          reject({
            err: 'err',
            errmsg: err,
          });
        }
      },
    });
  });
}
function waitForAgentConfigReady(wx: any) {
  if (wx.agentConfig !== undefined) {
    return;
  }
  return new Promise((resolve) => {
    detectAgentConfig(wx, resolve as () => void);
  });
}
function detectAgentConfig(wx: any, resolve: () => void) {
  console.info(' detect wx.agentConfig ' + new Date());
  if (wx.agentConfig !== undefined) {
    resolve();
    return;
  }
  setTimeout(() => {
    detectAgentConfig(wx, resolve);
  }, 1000);
}

export function shareWxworkMessage(
  func: string,
  options: {
    imgUrl?: string;
    title?: string;
    desc?: string;
    link?: string;
    enableIdTrans?: 1 | 0;
  },
) {
  switch (func) {
    case WX_AGENT_FUNC_NAMES.转发到微信好友:
      shareWxworkMessageToWechatMessage(options);
      break;
    case WX_AGENT_FUNC_NAMES.转发到企微好友:
      shareWxworkMessageToAppMessage(options);
      break;
    case WX_AGENT_FUNC_NAMES.转发到客户朋友圈:
      shareWxworkMessageToExternalMoments(options);
      break;
    case WX_AGENT_FUNC_NAMES.群发客户:
      shareWxworkMessageToExternalContact(options);
      break;
    default:
      break;
  }
}

/**转发企微消息到企微好友(转发) */
export function shareWxworkMessageToAppMessage(options: {
  imgUrl?: string;
  title?: string;
  desc?: string;
  link?: string;
  enableIdTrans?: 1 | 0;
}) {
  window.wx.invoke(
    WX_AGENT_FUNC_NAMES.转发到企微好友,
    {
      enableIdTrans: 0, // 是否开启id转译，不填默认为0
      ...options,
    },
    function (res: any) {},
  );
}

/**转发企微消息到客户（群发） */
export function shareWxworkMessageToExternalContact(options: {
  imgUrl?: string;
  title?: string;
  desc?: string;
  link?: string;
}) {
  window.wx.invoke(
    WX_AGENT_FUNC_NAMES.群发客户,
    {
      attachments: [
        {
          msgtype: 'link', // 消息类型，必填
          link: {
            title: options.title, // H5消息标题
            imgUrl: options.imgUrl, // H5消息封面图片URL
            desc: options.desc, // H5消息摘要
            url: options.link, // H5消息页面url 必填
          },
        },
      ],
    },
    function (res: any) {},
  );
}

/**转发企微消息到客户朋友圈（朋友圈）*/
export function shareWxworkMessageToExternalMoments(options: {
  imgUrl?: string;
  title?: string;
  desc?: string;
  link?: string;
  enableIdTrans?: 1 | 0;
}) {
  const title =
    options.title && options.title?.length > 30
      ? options.title?.slice(0, 30) + '...'
      : options.title;
  window.wx.invoke(
    WX_AGENT_FUNC_NAMES.转发到客户朋友圈,
    {
      attachments: [
        {
          msgtype: 'link', // 消息类型，必填
          link: {
            title: title, // H5消息标题, 最多显示64个字节（32个字符）
            imgUrl: options.imgUrl, // H5消息封面图片URL
            url: options.link, // H5消息页面url 必填
          },
        },
      ],
    },
    function (res: any) {},
  );
}

/**转发企微消息到微信好友(微信) */
export function shareWxworkMessageToWechatMessage(options: {
  imgUrl?: string;
  title?: string;
  desc?: string;
  link?: string;
  enableIdTrans?: 1 | 0;
}) {
  window.wx.invoke(
    WX_AGENT_FUNC_NAMES.转发到微信好友,
    {
      enableIdTrans: 0, // 是否开启id转译，不填默认为0
      ...options,
    },
    function (res: any) {},
  );
}
