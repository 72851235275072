/**
 * 中航独家列表
 */

import React, { useCallback, useMemo } from 'react';
import ItemCardHorizontal from '../../components/ItemCardHorizontal';
import useJump from '../../hooks/useJump';
import useQueryNewsInfinate from '../../hooks/useQueryNewsInfinate';
import { MEDIA_TYPE_NAMES, YZX_KEY_STR } from '../../constants';
import {
  fmtCurrentDateTime2Str,
  fmtRoute2FullPath,
  randomImg,
} from '../../utils/tools';
import Nodata from '../../components/Nodata';
import Loading from '../../components/Loading';
import { InfiniteScroll } from 'antd-mobile';
import YZXSelector from '../components/YZXSelector';
import { useLocation, useSearchParams } from 'react-router-dom';

const baseOptions = [
  {
    label: '科创',
    value: MEDIA_TYPE_NAMES.翼资讯科创,
  },
  {
    label: '宏观',
    value: MEDIA_TYPE_NAMES.翼资讯宏观,
  },
  {
    label: '股市',
    value: MEDIA_TYPE_NAMES.翼资讯股市,
  },
  {
    label: '公司',
    value: MEDIA_TYPE_NAMES.翼资讯公司,
  },
  {
    label: '地产',
    value: MEDIA_TYPE_NAMES.翼资讯地产,
  },
  {
    label: '金融',
    value: MEDIA_TYPE_NAMES.翼资讯金融,
  },
  {
    label: '基金',
    value: MEDIA_TYPE_NAMES.翼资讯基金,
  },
  {
    label: '环球',
    value: MEDIA_TYPE_NAMES.翼资讯环球,
  },
];
const options = [
  {
    label: '要闻',
    value: YZX_KEY_STR,
  },
  ...baseOptions,
];
export interface YZXListProps {
  searchValue?: string;
  count?: number;
  isLoadMore?: boolean;
  showSelector?: boolean;
  loadingColor?: string;
  onShare?: (func: string, item: API.INews, index: number) => void;
  onbutClick?:()=>void;
  onItemClick?:()=>void;
}
const YZXList: React.FC<YZXListProps> = ({
  searchValue,
  count,
  isLoadMore,
  loadingColor,
  showSelector,
  onShare,
  onbutClick,
  onItemClick
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const activeTabMemo = useMemo(() => {
    return searchParams?.get('activeTab') ?? options[0].value;
  }, [searchParams]);
  const { jumpNewsDetailFromMediaType, addParam2SearchAndNavigate2Url } =
    useJump();

  const { data, loading, loadMore, hasMore } = useQueryNewsInfinate({
    mediaTypeId: activeTabMemo,
    searchValue,
    count,
  });
  console.log(data);
  const hanldeSelectorItemClick = useCallback(
    (item: (typeof options)[0]) => {
      addParam2SearchAndNavigate2Url(location.pathname, {
        replace: true,
        param: { key: 'activeTab', value: item.value },
      });
    },
    [addParam2SearchAndNavigate2Url, location],
  );
  return (
    <React.Fragment>
      {showSelector && (
        <YZXSelector
          data={options}
          activeKey={activeTabMemo}
          onItemClick={hanldeSelectorItemClick}
        />
      )}
      {data?.map((item, index) => (
        <ItemCardHorizontal
          key={item.id}
          shereid={item.id}
          img={item.img || randomImg()}
          title={item.title}
          description={item.shortTitle}
          time={fmtCurrentDateTime2Str(item.updateTime)}
          onbutClick={onbutClick}
          share
          onItemClick={() => {
            if(onItemClick){
              onItemClick()
            }
            jumpNewsDetailFromMediaType(MEDIA_TYPE_NAMES.翼资讯公司, {
              url: item.url,
              id: item.id,
            });
          }}
          onShareClick={(func) => {
            onShare?.(
              func,
              { ...item, img: fmtRoute2FullPath('/shareImg/yzx.jpg') },
              index,
            );
          }}
          customerList={item.relatedCustomers}
          stockList={item.relatedStocks}
        />
      ))}
      {!loading && !data?.length && (
        <Nodata content={searchValue ? '暂未搜索到相关数据' : '暂无数据'} />
      )}
      {/* 初始化loading */}
      {!data && loading ? (
        <Loading loadingColor={loadingColor} />
      ) : (
        hasMore &&
        !!data &&
        isLoadMore &&
        !loading && (
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <Loading loadingColor={loadingColor} />
          </InfiniteScroll>
        )
      )}
    </React.Fragment>
  );
};

export default YZXList;
