import { DotLoading, Space } from 'antd-mobile';

export interface LoadingProps {
  loadingColor?: string;
  text?: string;
}
const Loading: React.FC<LoadingProps> = ({
  loadingColor = '#cccccc',
  text = '加载中',
}) => {
  return (
    <Space
      block
      justify="center"
      style={{ '--gap': '0', padding: '0.6250rem', color: loadingColor }}
    >
      {text}
      <DotLoading color="#currentColor" />
    </Space>
  );
};
export default Loading;
