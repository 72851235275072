import { useCallback, useEffect, useState } from 'react';

import cls from './BusinessCard.module.scss'
import dh from '../../images/dh-copy.png';
import jh from '../../images/jh-copy.png';
import PhoneModal from '../components/PhoneModal';
import EwmModal from '../components/EwmModal';
import { useGlobalState } from '../../models/global';

const BusinessCard: React.FC<{}> = () => {

    const [visible, setVisible] = useState(false)
    const [colorObj, setColorObj] = useState({
        background: `linear-gradient(180deg, rgba(229, 236, 255, 0.58) 0%, rgba(255, 255, 255, 1) 100%)`,
        title: 'rgba(37, 47, 66, 1)',
        subtitle: 'rgba(37, 47, 66, 0.7)'
    })
    const [isModal, setIsModal] = useState(false)
    const [{ wxPushData }] = useGlobalState();
    const onSubmit = useCallback((phoneNumber: string) => {
        setVisible(false)
        window.location.href = `tel:${phoneNumber}`;
    }, [])
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset || document.documentElement.scrollTop;
        setScrollPosition(position);
    };
    useEffect(() => {
        // 监听滚动事件
        window.addEventListener('scroll', handleScroll);
        // 组件卸载时移除监听事件
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (scrollPosition > 58) {
            setColorObj({
                background: `rgba(0, 0, 0, 0.8)`,
                title: 'rgba(255, 255, 255, 1)',
                subtitle: 'rgba(255, 255, 255, 0.7)'
            })
        } else {
            setColorObj({
                background: `linear-gradient(180deg, rgba(229, 236, 255, 0.58) 0%, rgba(255, 255, 255, 1) 100%)`,
                title: 'rgba(37, 47, 66, 1)',
                subtitle: 'rgba(37, 47, 66, 0.7)'
            })
        }
    }, [scrollPosition])
    return (
        <>
            <EwmModal visible={isModal} onCancel={() => setIsModal(false)} url={wxPushData?.qr_code || ''} txurl={wxPushData?.avatar || ''} />
            <PhoneModal onSubmit={() => onSubmit(wxPushData?.mobile || '')} onCancel={() => setVisible(false)} visible={visible} phone={wxPushData?.mobile || ''} />
            {wxPushData?.username && !window.config.hiddenBusinessCard ? <div className={cls.warp} style={{ background: colorObj.background, height: wxPushData?.position && wxPushData?.position?.length > 11 ? '4.8750rem' : '3.6250rem' }}>
                <div className={cls.left}>
                    <div className={cls.imgView}>
                        <img src={wxPushData?.avatar} className={cls.img} alt='' />
                    </div>
                    <div className={cls.conent}>
                        <div className={cls.title} style={{ color: colorObj.title }}>
                            {wxPushData?.username}</div>
                        <div className={cls.subtitle} style={{ color: colorObj.subtitle }}>{wxPushData?.position}</div>
                    </div>
                </div>

                <div className={cls.right}>
                    <div className={cls.but} onClick={() => setVisible(true)}>
                        <img src={dh} className={cls.icon} alt='' />打电话</div>
                    <div className={cls.but} onClick={() => setIsModal(true)}>
                        <img src={jh} className={cls.icon} alt='' />加好友</div>
                </div>

            </div> : null}

        </>



    );
};
export default BusinessCard;
