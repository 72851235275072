// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body * {
  box-sizing: border-box;
  flex-shrink: 0;
}

body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
}

button {
  margin: 0;
  padding: 0;
  border: 0.0625rem solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/common.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,cAAA;AACF;;AACA;EACE,qHAAA;AAEF;;AACA;EACE,SAAA;EACA,UAAA;EACA,mCAAA;EACA,aAAA;EACA,6BAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,aAAA;EACA,sBAAA;AAGF;;AADA;EACE,aAAA;EACA,mBAAA;AAIF;;AAFA;EACI,aAAA;EACA,2BAAA;AAKJ;;AAHA;EACI,aAAA;EACA,uBAAA;AAMJ;;AAHA;EACI,aAAA;EACA,yBAAA;AAMJ;;AAJA;EACI,aAAA;EACA,6BAAA;AAOJ;;AALA;EACI,aAAA;EACA,6BAAA;AAQJ;;AANA;EACI,aAAA;EACA,8BAAA;AASJ;;AAPA;EACI,aAAA;EACA,uBAAA;AAUJ;;AARA;EACI,aAAA;EACA,mBAAA;AAWJ;;AATA;EACI,aAAA;EACA,qBAAA;AAYJ","sourcesContent":["body * {\n  box-sizing: border-box;\n  flex-shrink: 0;\n}\nbody {\n  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,\n    Arial, PingFang SC-Light, Microsoft YaHei;\n}\nbutton {\n  margin: 0;\n  padding: 0;\n  border: 0.0625rem solid transparent;\n  outline: none;\n  background-color: transparent;\n}\n\nbutton:active {\n  opacity: 0.6;\n}\n.flex-col {\n  display: flex;\n  flex-direction: column;\n}\n.flex-row {\n  display: flex;\n  flex-direction: row;\n}\n.justify-start {\n    display: flex;\n    justify-content: flex-start;\n  }\n.justify-center {\n    display: flex;\n    justify-content: center;\n}\n  \n.justify-end {\n    display: flex;\n    justify-content: flex-end;\n}\n.justify-evenly {\n    display: flex;\n    justify-content: space-evenly;\n}\n.justify-around {\n    display: flex;\n    justify-content: space-around;\n}\n.justify-between {\n    display: flex;\n    justify-content: space-between;\n}\n.align-start {\n    display: flex;\n    align-items: flex-start;\n}\n.align-center {\n    display: flex;\n    align-items: center;\n}\n.align-end {\n    display: flex;\n    align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
