import React from 'react';

import cls from './Tips.module.scss';

export interface TipsProps {
  textColor?: string;
  style?: React.CSSProperties;
  text?:string,
  title?:string
}
const Tips: React.FC<TipsProps> = ({ textColor, style,text='以上所载内容均来自第三方，中航证券对信息的准确性、完整性、可靠性、及时性不做任何保证，仅供投资者参考，不代表中航证券观点，在任何情况下，本内容所载信息，非股票、基金或产品推荐，不构成任何投资建议。对任何因直接或间接使用本内容或据此进行投资所造成的后果或损失，中航证券不承担任何法律责任。投资有风险，入市需谨慎。',title }) => {
  return (
    <div className={cls.wrap} style={{ color: textColor, ...style }}>
       {title&&<div>{title}</div>} 
       <div>免责声明:{text}</div>
     </div>
  );
};

export default Tips;
