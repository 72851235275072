import React, { useState } from 'react';
import cls from './XGKH.module.scss';
import './common.scss';

export interface XgkhXgggProps {
  data: API.INews | undefined;
}

const XGKH: React.FC<XgkhXgggProps> = ({
  data,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
 
  // 截取文本
  let customerList = data?.relatedCustomers;
  let customerMaxList = data?.relatedCustomers;
  if (customerList && !isExpanded && customerList.length > 8) {
    customerMaxList = customerList.slice(0, 8);
  }
 
  // 切换展开/收起状态
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className={`${cls['block_3']} flex-col`}>
      <div className={`${cls['box_1']} flex-col`}>
        <div className={`${cls['block_4']} flex-row`}>
          <img
            className={`${cls['label_1']}`}
            src={require('../images/xiangguankehu1.png')}
            alt=''
          />
          <span className={`${cls['text_3']}`}>相关客户</span>
              {customerList && customerList.length > 8 && (
                <div className={`${cls['box_3']} flex-row`}>
                  <div className={`${cls['image-text_7']} flex-row justify-between`}>
                    <div className={`flex-row justify-between`}>
                      <button 
                        onClick={toggleExpand}
                        className={`${cls['text-group_1']}`} >
                        {isExpanded ? '收起' : '展开'}
                      </button>
                      <img
                        className={`${cls['thumbnail_1']}`}
                        src={require('../images/xiangguankehu2.png')}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              )}
          <img
            className={`${cls['image_5']}`}
            src={require('../images/xiangguankehu3.png')}
            alt=''
          />
        </div>
        <div className={`${cls['block_5']} flex-row justify-between`}>
          <div className={`${cls['box_14']}`}>
            {customerMaxList && customerMaxList.map((item, index) => (
              <div key={index} className={`${cls['xiangguankehu']}`}>
                <div className={`${cls['text-wrapper_1']}`}>
                  <div className={`${cls['text_4']}`}>
                      {item.customerName}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default XGKH;
