// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_wrap__QoYTQ {
  margin: 0.625rem 0.9375rem;
  background-color: #ffffff;
  border-radius: 0.625rem;
  padding: 0.9375rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Card.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,yBAAA;EACA,uBAAA;EACA,kBAAA;AACF","sourcesContent":[".wrap {\r\n  margin: 0.6250rem 0.9375rem;\r\n  background-color: #ffffff;\r\n  border-radius: 0.6250rem;\r\n  padding: 0.9375rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Card_wrap__QoYTQ`
};
export default ___CSS_LOADER_EXPORT___;
