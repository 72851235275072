import React from 'react';

import cls from './BannerCard.module.scss';
import { fmtCurrentDateTime2Str } from '../../utils/tools';
import wjkp from '../../images/wjkp-banner.png';
import jdfp from '../../images/jdfp-banner.png';
import tcjj from '../../images/tcjj-banner.png';
import mrdc from '../../images/mrdc-banner.png';
import yzx from '../../images/yzx-banner.png';
import tzzbj from '../../images/tzzbj-banner.png';
import mrsp from '../../images/mrsp_banner.png';
import xwlb from '../../images/xwlb_banner.png';
import phgg from '../../images/phgg_banner.png';
import tggd from '../../images/tggd.png';
import { URL_NAMES } from '../../constants';

/**
 * 顶部banner
 */

export interface BannerCardProps {
  hiddenTime?: boolean;
  time?: string;
  pageTitle?: string;
  bgColor?: string;
  timeType?: boolean;
  img?: string;
}

export function getBgImg(data?: string) {
  switch (true) {
    case data === URL_NAMES.午间快评:
      return wjkp;
    case data === URL_NAMES.焦点复盘:
      return jdfp;
    case data === URL_NAMES.题材掘金:
      return tcjj;
    case data === URL_NAMES.明日洞察:
      return mrdc;
    case data === URL_NAMES.翼资讯:
      return yzx;
    case data === URL_NAMES.投资早班机:
      return tzzbj;
    case data === URL_NAMES.每日收评:
      return mrsp;
    case data === URL_NAMES.新闻联播:
      return xwlb;
    case data === URL_NAMES.盘后公告:
      return phgg;
    case data === URL_NAMES.投顾观点详情页:
        return tggd;
    default:
      return wjkp;
  }
}
const BannerCard: React.FC<BannerCardProps> = ({
  hiddenTime,
  time,
  bgColor,
  pageTitle,
  timeType = false,
  img,
}) => {
  return (
    <div
      className={cls.banner}
      style={{ backgroundImage: `url(${img || getBgImg(pageTitle)})` }}
    >
      <div className={cls.bannerContent}>
        {hiddenTime ? null : (
          <div className={cls.timeView} style={{ background: bgColor }}>
            <div className={cls.month}>
              <div className={cls.monthText}>
                {fmtCurrentDateTime2Str(
                  time,
                  { format: 'MM', defaultValue: '--' },
                  timeType,
                )}
                <span>/</span>
                {fmtCurrentDateTime2Str(
                  time,
                  { format: 'DD', defaultValue: '--' },
                  timeType,
                )}
              </div>
            </div>
            <div className={cls.lineView}>
              <div className={cls.lineC}></div>
            </div>
            <div className={cls.botView}>
              <div className={cls.year}>
                <div className={cls.yearText}>
                  {fmtCurrentDateTime2Str(
                    time,
                    { format: 'YYYY', defaultValue: '----' },
                    timeType,
                  )}
                </div>
              </div>
              <div className={cls.boText}>
                {fmtCurrentDateTime2Str(
                  time,
                  { format: 'HH:mm', defaultValue: '--:--' },
                  timeType,
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerCard;
