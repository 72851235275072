import React, { useMemo } from 'react';

import cls from './CJZBStockCard.module.scss';

export interface CJZBStockCardProps {
  titleImg: string;
  titleImg2?: string;
  titleImg3?: string;
  tagColor: string;
  style?: React.CSSProperties & {
    '--divider-color'?: string;
    '--stock-row-bg': string;
  };
  stockList?: API.IsellDate[];
  noDataText: string;
}

const fmtArrayTo2DArray = (array: any[], count: number) => {
  const res = array.reduce((prev, curr, currIndex) => {
    if (currIndex % count === 0) {
      return [...prev, [curr]];
    } else {
      const newArray = [...prev];
      newArray[prev.length - 1].push(curr);
      return newArray;
    }
  }, []);
  return res;
};

const CJZBStockCard: React.FC<CJZBStockCardProps> = ({
  style,
  titleImg,
  tagColor,
  stockList = [],
  titleImg2,
  noDataText,
  titleImg3
}) => {
  const lengthMemo = useMemo(() => {
    if (stockList && stockList.length > 2 && stockList.length < 5) {
      return {
        height: cls.bgheight2,
        img:titleImg2
      }
    }
    if (stockList && stockList.length > 4) {
      return {
        height:cls.bgheight3,
        img:titleImg3,
      }
    }
    return {
      height: cls.bgheight,
      img:titleImg
    }

  }, [stockList,titleImg3,titleImg,titleImg2])
  return (
    <React.Fragment>
      <div
        className={`${cls.lineWrap} ${lengthMemo.height}`} style={style} >
        <div className={cls.left}>
          <img alt="" src={lengthMemo.img} />
        </div>
        <div className={cls.right}>
          {stockList && stockList.length > 0 ? fmtArrayTo2DArray(stockList, 2).map(
            (itemList: any[], inex: number) => {
              return (
                <div className={cls.stockLine} key={inex}>
                  {itemList.map((item, index) => (
                    <div className={`${cls.stockWrap} `} key={index}>
                      <div className={cls.stockWrapContent}>
                        <div className={cls.stockName}>{item.name}</div>
                        <div className={cls.info}>
                          <div
                            className={cls.tag}
                            style={{ backgroundColor: tagColor }}
                          >
                            <div className={cls.tagContent}>{item.type}</div>
                          </div>
                          <div className={cls.stockCode}>{item.code}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            },
          ) : <div className={cls.stockLine}>
            <div className={cls.nodata}>{noDataText}</div>
          </div>}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CJZBStockCard;
