import React, { useMemo } from 'react';
import cls from './NewsTimeline.module.scss';
import ShareBtn from '../../components/ShareBtn';
import { fmtCurrentDateTime2Str } from '../../utils/tools';

export interface NewsTimelineProps {
  data?: API.INews[] | null;
  onShareClick?: (item: API.INews, index: number) => void;
}
const NewsTimeline: React.FC<NewsTimelineProps> = ({ data, onShareClick }) => {
  const dataMemo = useMemo(() => {
    return data?.reduce<
      (API.INews & {
        currentDate?: string;
        currentTime?: string;
        showDate: boolean;
      })[]
    >((prev, curr) => {
      const fmtDate = fmtCurrentDateTime2Str(
        curr.updateTime,
        {
          format: 'YYYY年MM月DD日 HH:mm:ss',
        },
        false,
      );
      const [currentDate, currentTime] = fmtDate
        ? fmtDate.split(' ')
        : ['', ''];
      prev.push({
        ...curr,
        currentDate,
        currentTime,
        showDate: currentDate !== prev[prev.length - 1]?.currentDate,
      });
      return prev;
    }, []);
  }, [data]);

  return (
    <div className={cls.wrap}>
      {dataMemo?.map((item, index) => (
        <React.Fragment key={item.id}>
          {item.showDate && <div className={cls.date}>{item.currentDate}</div>}
          <div className={cls.dataItem}>
            <div className={cls.timeWrap}>
              <div className={cls.time}>{item.currentTime}</div>
              <ShareBtn
                popup={false}
                shereid={item.id}
                onClick={(e) => {
                  e.stopPropagation();
                  onShareClick?.(item, index);
                }}
              />
            </div>
            <div className={cls.description}>{item.conten}</div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default NewsTimeline;
