/**
 *  中航咨询专区
 */

import React from 'react';
import cls from './ConsultationZone.module.scss'
import url from '../../images/zlm-title.png'
import { useQueryMoningFlightPicture } from '../../services/api';
export interface Props {
    onimgClick:(data:string)=>void
}

const ConsultationZone: React.FC<Props> = ({onimgClick}) => {
    const { data } = useQueryMoningFlightPicture()
    return (
        <>
            {data && data?.length > 0 ? (
                <div className={cls.warp}>
                    <div className={cls.imgView}>
                        <img src={url} alt='title' />
                    </div>
                    <div className={cls.contView}>
                        {data.map((item, index) => (
                            <div className={(data.length % 2 === 1 && index === 0) ? cls.itemone : cls.item} key={index}>
                                <img src={item?.icon} className={cls.img}  alt='' onClick={()=>onimgClick(item.url)}/>
                            </div>
                        ))}


                    </div>
                </div>
            ) : null
            }
        </>

    );
};

export default ConsultationZone;
