import React from 'react';

import cls from './Nodata.module.scss';
const Nodata: React.FC<{ content?: string }> = ({ content = '暂无数据' }) => {
  return (
    <div className={cls.wrap}>
      <img src={require('../images/IconNodata.png')} alt="" />
      <div>{content}</div>
    </div>
  );
};

export default Nodata;
