import React, { useCallback, useEffect, useMemo } from 'react';
import cls from './TGGDDetail.module.scss';
import Tips from '../components/Tips';
import { useParams } from 'react-router-dom';
import { queryGetUpdateHost, queryInvestmentAdviser, useQueryInvestmentAdviserById } from '../services/api';
import ShareBtnBottom from '../components/ShareBtnBottom';
import useInitWxFunc from '../hooks/useInitWxFunc';
import { shareWxworkMessage } from '../utils/wxUtil';
import useScrollToTop from '../hooks/useScrollToTop';
import BannerCard from './components/BannerCard';
import { PUSH_NAME_BUT, URL_NAMES } from '../constants';
import useTitle from '../hooks/useTitle';
import PageSwiper from './containers/PageSwiper';
import DetailTitleCard from './components/DetailTitleCard';
import DetailContCard from './components/DetailContCard';
import BusinessCard from './containers/BusinessCard';
import { getPushUrl } from '../utils/utils';
import { useGlobalState } from '../models/global';
import { TggdImg } from '../utils/tools';
import { track } from '../utils/sdk';

const PAGE_NAME = '投顾观点详情';
const TGGDDetail = () => {
  useScrollToTop();
  const [{ wxUseData }] = useGlobalState()
  const { id } = useParams();
  const { data } = useQueryInvestmentAdviserById(Number(id));
  useTitle(data?.mediaName || '');

  const shareInfo = useMemo(() => {
    if (!data?.id) {
      return undefined;
    }
    return {
      title: data?.mediaName,
      link: getPushUrl(window.location.href, wxUseData),
      desc: data?.shortTitle,
      imgUrl: TggdImg,
      success: () => {
        queryGetUpdateHost(data.id + '');
      },
    };
  }, [data, wxUseData]);
  const { onShare } = useInitWxFunc({ shareInfo });
  useEffect(()=>{
    track('页面浏览',{
      page_id:'leaf1003',
      page_title:'投顾观点'
    })
  },[])
  const handleNewsShareClick = useCallback(
    (func: string) => {
      queryInvestmentAdviser(Number(data?.id))
      track('按钮点击', {
        page_id: 'leaf1003',
        page_title: '投顾观点',
        btn_title: `${PUSH_NAME_BUT[func]}`
      })
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: data?.mediaName,
          link: getPushUrl(window.location.href, wxUseData),
          desc: data?.shortTitle,
          imgUrl: TggdImg,
        });
      });
    },
    [onShare, data, wxUseData],
  );
  return (
    <div className={cls.warp}>
      <BusinessCard />
      <BannerCard
        time={data?.updateTime || ''}
        bgColor={`rgba(124,30,30,0.45)`}
        pageTitle={URL_NAMES.投顾观点详情页}
      />
      <div className={cls.conent}>
        <DetailTitleCard title={data?.mediaName}>
          <div className={cls.conentView}>
            <div className={cls.leftView}></div>
            <div className={cls.rightView}>
              <div className={cls.text}>{`投顾：${data?.author}`}</div>
              <div className={cls.text}>{`执业编号：${data?.positionNum}`}</div>
            </div>
          </div>
        </DetailTitleCard>
        <PageSwiper regionName={PAGE_NAME} style={{ paddingTop: '0.6250rem' }} />
        <DetailContCard conten={data?.content} />
      </div>
      <Tips textColor="#989898" style={{ padding: '2.1875rem 0.9375rem 3.7500rem' }} text='本文所载内容仅供中航证券有限公司的客户参考使用。中航证券不会因接收人收到本内容而视其为客户。在任何情况下，本内容所载信息或所表述的意见并不构成对任何人的投资建议，对任何因直接或间接使用本内容或者据此进行投资所造成的一切后果或损失，中航证券不承担任何法律责任。任何人如需引用或转载，请务必注明出处，且不得对内容进行有悖原意的引用和删改。市场有风险，投资需谨慎。' />
      <ShareBtnBottom onIconClick={handleNewsShareClick} />
    </div>
  );
};

export default TGGDDetail;
