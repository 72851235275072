/**
 * 明日洞察
 */
import Tips from '../components/Tips';
import { MEDIA_TYPE_NAMES, MRBZ_DESCRIPTIONS, PUSH_NAME_BUT, URL_NAMES } from '../constants';
import { queryGetUpdateHost, useQueryFinance } from '../services/api';
import cls from './MRDC.module.scss';
import useTitle from '../hooks/useTitle';
import useInitWxFunc from '../hooks/useInitWxFunc';
import { useCallback, useEffect, useMemo } from 'react';
import { shareWxworkMessage } from '../utils/wxUtil';
import ShareBtnBottom from '../components/ShareBtnBottom';
import useScrollToTop from '../hooks/useScrollToTop';
import fdhurl from '../images/wjkp-fdh.png';
import dhurl from '../images/wjkp-dh.png';
import url from '../images/wjkp-tb.png';
import nrxqurl from '../images/nrxq.png';
// import sburl from '../images/jrdd-sb.png';
// import jrddurl from '../images/jrdd.png';
// import anurl from '../images/jrdd-xzq.png';
import BannerCard from './components/BannerCard';
import { fmtRoute2FullPath, getFullPathFromMediaTypeIds } from '../utils/tools';
import PageSwiper from './containers/PageSwiper';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalState } from '../models/global';
import { getPushUrl } from '../utils/utils';
import BusinessCard from './containers/BusinessCard';
import moment from 'moment';
import { track } from '../utils/sdk';
import XGKH from './XGKH';
const PAGE_NAME = '明日洞察';
const MRDC = () => {
  useScrollToTop();
  useTitle(PAGE_NAME);
  const { id } = useParams();
  const { data, isValidating } = useQueryFinance({
    dataType: MEDIA_TYPE_NAMES.明日洞察,
    id,
  });
  const [{wxUseData}]=useGlobalState()
  const navigator = useNavigate();
  useEffect(() => {
    if (!isValidating && !data && id) {
      // url入参有日期 且 没有请求到该日数据，跳转首页
      navigator(URL_NAMES[PAGE_NAME], { replace: true });
    }
   
    
  }, [data, isValidating, id, navigator]);
  const shareInfo = useMemo(() => {
    if (!data?.id) {
      return undefined;
    }
    return {
      title:`${moment(data?.updateTime, 'YYYY-MM-DD HH:mm:ss').format(
        'M.DD',
      )}${PAGE_NAME}|${data?.title}`,
      link:getPushUrl(getFullPathFromMediaTypeIds(data.mediaType, {
        id: data?.id,
      }),wxUseData),
      desc: data?.shortTitle||MRBZ_DESCRIPTIONS[PAGE_NAME],
      imgUrl: fmtRoute2FullPath('/shareImg/mrdc.jpg'),
      success: () => {
        queryGetUpdateHost(data.id + '');
      },
    };
  }, [data,wxUseData]);
 
  const { onShare } = useInitWxFunc({ shareInfo });
  useEffect(() => {
    track('页面浏览', {
      page_id: 'leaf1003',
      page_title: '明日洞察'
    })
  }, [])
  const handleNewsShareClick = useCallback(
    (func: string) => {
      track('按钮点击', {
        page_id: 'leaf1003',
        page_title: '明日洞察',
        btn_title: `${PUSH_NAME_BUT[func]}`
      })
      queryGetUpdateHost(`${data?.id}`);
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: shareInfo?.title,
          link: shareInfo?.link,
          desc: shareInfo?.desc,
          imgUrl: shareInfo?.imgUrl,
        });
      });
    },
    [onShare, data, shareInfo],
  );
  return (
    <div className={cls.warp}>
      <BusinessCard />
      <BannerCard
        time={data?.updateTime || ''}
        bgColor={`rgba(147,64,0,0.51)`}
        pageTitle={URL_NAMES.明日洞察}
      />
      <div className={cls.conent}>
        <div className={cls.titleView}>
          <div className={cls.fdhView}>
            <img src={fdhurl} className={cls.fdhimg} alt="" />
          </div>
          <div className={cls.title}>{data?.title}</div>
          <div className={cls.dhView}>
            <img src={dhurl} className={cls.dhimg} alt="" />
          </div>
          <div className={cls.abstract}>{data?.shortTitle}</div>
        </div>
        <PageSwiper regionName={PAGE_NAME} style={{ paddingTop: '0.6250rem' }} />
        <XGKH data={data}/>
        <div className={cls.contentWrap}>
          <div className={cls.contentWrap_title}>
            <img src={url} className={cls.tbimg} alt="" />
            <div className={cls.nrxqView}>
              <img src={nrxqurl} className={cls.nrxqimg} alt="" />
            </div>
          </div>
          <div
            className={cls.content + ' reach-text-content'}
            dangerouslySetInnerHTML={{ __html: data?.conten ?? '' }}
          ></div>
        </div>
      </div>
      <Tips textColor="#989898" style={{ padding: '2.1875rem 0.9375rem 3.7500rem' }} title={'以上内容来自财联社。'}/>
      <ShareBtnBottom onIconClick={handleNewsShareClick} />
    </div>
  );
};

export default MRDC;
