/**
 * 盘后公告
 */
import { useCallback, useEffect, useMemo } from 'react';
import ShareBtnBottom from '../components/ShareBtnBottom';
import Tips from '../components/Tips';
import { MEDIA_TYPE_NAMES, MRBZ_DESCRIPTIONS, PUSH_NAME_BUT, URL_NAMES } from '../constants';
import useInitWxFunc from '../hooks/useInitWxFunc';
import useTitle from '../hooks/useTitle';
import { queryGetUpdateHost, useQueryFinance } from '../services/api';
import { fmtRoute2FullPath, getFullPathFromMediaTypeIds } from '../utils/tools';
import cls from './JDFP.module.scss';
import { shareWxworkMessage } from '../utils/wxUtil';
import useScrollToTop from '../hooks/useScrollToTop';

import BannerCard from './components/BannerCard';

import { useNavigate, useParams } from 'react-router-dom';
import DetailTitleCard from './components/DetailTitleCard';
import DetailContCard from './components/DetailContCard';
import PageSwiper from './containers/PageSwiper';
import { useGlobalState } from '../models/global';
import { getPushUrl } from '../utils/utils';
import BusinessCard from './containers/BusinessCard';
import moment from 'moment';
import { track } from '../utils/sdk';
import XGKH from './XGKH';
const PAGE_NAME = '盘后公告';
const PHGG = () => {
  useScrollToTop();
  useTitle(PAGE_NAME);
  const { id } = useParams();
  const { data, isValidating } = useQueryFinance({
    dataType: MEDIA_TYPE_NAMES[PAGE_NAME],
    id,
  });
  const navigator = useNavigate();
  const [{wxUseData}]=useGlobalState()
  useEffect(() => {
    if (!isValidating && !data && id) {
      // url入参有日期 且 没有请求到该日数据，跳转首页
      navigator(URL_NAMES[PAGE_NAME], { replace: true });
    }
  }, [data, isValidating, id, navigator]);
  const shareInfo = useMemo(() => {
    if (!data?.id) {
      return undefined;
    }
    return {
      title: `${moment(data?.updateTime, 'YYYY-MM-DD HH:mm:ss').format(
        'M.DD',
      )}${PAGE_NAME}|${data?.title}`,
      link: getPushUrl(getFullPathFromMediaTypeIds(data.mediaType, {
        id: data?.id,
      }),wxUseData),
      desc:data?.shortTitle|| MRBZ_DESCRIPTIONS[PAGE_NAME],
      imgUrl: fmtRoute2FullPath('/shareImg/phgg.png'),
      success: () => {
        queryGetUpdateHost(data.id + '');
      },
    };
  }, [data,wxUseData]);
  const { onShare } = useInitWxFunc({ shareInfo });
  useEffect(() => {
    track('页面浏览', {
      page_id: 'leaf1003',
      page_title: '盘后公告'
    })
  }, [])
  const handleNewsShareClick = useCallback(
    (func: string) => {
      queryGetUpdateHost(`${data?.id}`);
      track('按钮点击', {
        page_id: 'leaf1003',
        page_title: '盘后公告',
        btn_title: `${PUSH_NAME_BUT[func]}`
      })
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: shareInfo?.title,
          link: shareInfo?.link,
          desc: shareInfo?.desc,
          imgUrl: shareInfo?.imgUrl,
        });
      });
    },
    [onShare, data, shareInfo],
  );
  return (
    <div className={cls.warp}>
      <BusinessCard />
      <BannerCard
        time={data?.updateTime || ''}
        bgColor={`rgba(141, 99, 148, 0.5)`}
        pageTitle={URL_NAMES.盘后公告}
      />

      <div className={cls.conent}>
        <DetailTitleCard title={data?.title} shortTitle={data?.shortTitle} />
        <PageSwiper regionName={PAGE_NAME} style={{ paddingTop: '0.6250rem' }} />
        <XGKH data={data}/>
        <DetailContCard conten={data?.conten} />
      </div>
      <Tips textColor="#989898" style={{ padding: '2.1875rem 0.9375rem 3.7500rem' }} title={'以上内容来自财联社。'}/>
      <ShareBtnBottom onIconClick={handleNewsShareClick} />
    </div>
  );
};

export default PHGG;
