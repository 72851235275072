import React from 'react';

import cls from './CJZBNewsCard.module.scss';
import { RightOutline } from 'antd-mobile-icons';
import imgurl from '../../images/cjzb-xzbt.png';

export interface CJZBNewsCardProps {
  title?: string;
  description?: string;
  showDetailBtn?: boolean;
  onCardClick?: () => void;
}
const CJZBNewsCard: React.FC<CJZBNewsCardProps> = ({
  title = '北京“取消地价上限”？权威回应北京“取消地价上限”？权威回应北京“取消地价上限”？权威回应',
  description = '11月7日，针对有媒体报道“北京市拟在今年第三批次供地中取消地价上限”的消息，上海证券报记者向北京市规划和自然资源委员会进行求11月7日，针对有媒体报道“北京市拟在今年第三批次供地中取消地价上限”的消息，上海证券报记者向北京市规划和自然资源委员会进行求',
  showDetailBtn,
  onCardClick,
}) => {
  return (
    <div
      className={cls.cardWrap}
      onClick={() => (onCardClick ? onCardClick() : '')}
    >
      <div className={cls.topView}>
        <div className={cls.imgView}>
          <img src={imgurl} className={cls.img} alt="" />
        </div>
        <div className={cls.title}>{title}</div>
      </div>

      <div className={cls.description}>{description}</div>
      {showDetailBtn && (
        <div className={cls.detailBtn}>
          查看详情
          <RightOutline />
        </div>
      )}
    </div>
  );
};

export default CJZBNewsCard;
