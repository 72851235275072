import React, {
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
  useMemo,
} from 'react';
import { SearchOutline } from 'antd-mobile-icons';
import cls from './Search.module.scss';
import { track } from '../utils/sdk';
export interface SearchProps {
  placeholder?: string;
  onEnterPress?: () => void;
  onChange?: (value: string) => void;
  value?: string;
}
const Search: React.FC<SearchProps> = ({
  placeholder,
  onEnterPress,
  onChange,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (['Enter'].includes(e.key) && onEnterPress) {
        onEnterPress();
        inputRef?.current?.blur();
      }
    },
    [onEnterPress],
  );
  const handleFocusout = useCallback(
    (e: any) => {
      if (['INPUT'].indexOf(e.target.tagName) !== -1 && onEnterPress) {
        // 防止其非输入框的事件触发
        onEnterPress();
        inputRef?.current?.blur();
      }
    },
    [onEnterPress],
  );
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange],
  );
  const isIOS = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }, []);

  useEffect(() => {
    if (isIOS) {
      document.addEventListener('focusout', handleFocusout);
    } else {
      document.addEventListener('keyup', handleKeyUp);
    }

    return () => {
      if (isIOS) {
        document.removeEventListener('focusout', handleFocusout);
      } else {
        document.removeEventListener('keyup', handleKeyUp);
      }
    };
  }, [handleKeyUp, isIOS, handleFocusout]);
  const onFocus=useCallback(()=>{
    track('按钮点击',{
      btn_title:'搜索栏'
    })
  },[])
  return (
    <div className={cls.wrap}>
      <div className={cls.searchContent}>
        <SearchOutline className={cls.leftIcon} />
        <input
          ref={inputRef}
          onChange={handleChange}
          type="text"
          className={cls.input}
          placeholder={placeholder}
          onFocus={onFocus}
          value={value}
          onBlur={() => handleKeyUp}
        />
      </div>
    </div>
  );
};

export default Search;
