import { useCallback, useEffect, useState } from 'react';
import { initWxAgentConfig, initWxConfig } from '../utils/wxUtil';
import {
  PLATFORM_TYPES,
  WX_AGENT_FUNC_NAMES,
  WX_FUNC_NAMES,
} from '../constants';
import { env } from '../utils/tools';
import { useLocation } from 'react-router-dom';
import { ACTION_TYPES, useGlobalState } from '../models/global';
import {
  queryGrwxJsapiTicket,
  queryJsapiTicket,
  queryTicket,
} from '../services/api';

const useInitWxFunc = (options?: {
  shareInfo?: {
    title?: string;
    desc?: string;
    imgUrl?: string;
    link?: string;
    success?: () => void;
  };
}) => {
  const location = useLocation();
  const [wxConfigReady, setWxConfigReady] = useState(false);
  const [wxAgentConfig, setWxAgentConfig] = useState<null | {
    [
      key: (typeof WX_AGENT_FUNC_NAMES)[keyof typeof WX_AGENT_FUNC_NAMES]
    ]: boolean;
  }>(null);
  const [{ wxTicket, wxAgentTicket }, dispatch] = useGlobalState();
  const getWxTicket = useCallback(async () => {
    const currentTime = new Date().getTime();
    // ticket未过期，直接使用
    if (wxTicket?.expiresTime && wxTicket.expiresTime > currentTime) {
      return wxTicket?.ticket;
    }
    // 企业微信，查询ticket
    let ticketRes;
    if (env === PLATFORM_TYPES.企业微信) {
      ticketRes = await queryJsapiTicket();
    } else if (env === PLATFORM_TYPES.微信) {
      ticketRes = await queryGrwxJsapiTicket();
    }
    if (ticketRes) {
      dispatch({
        type: ACTION_TYPES.WX_TICKET,
        payload: ticketRes,
      });
      return ticketRes.ticket;
    }
    return '';
  }, [wxTicket, dispatch]);
  const getWxAgentTicket = useCallback(async () => {
    const currentTime = new Date().getTime();
    // ticket未过期，直接使用
    if (wxAgentTicket?.expiresTime && wxAgentTicket.expiresTime > currentTime) {
      return wxAgentTicket?.ticket;
    }
    // 企业微信，查询ticket
    let ticketRes;
    if (env === PLATFORM_TYPES.企业微信) {
      ticketRes = await queryTicket();
    }
    if (ticketRes) {
      dispatch({
        type: ACTION_TYPES.WX_AGENT_TICKET,
        payload: ticketRes,
      });
      return ticketRes.ticket;
    }
    return '';
  }, [wxAgentTicket, dispatch]);
  const init = useCallback(async () => {
    try {
      if (![PLATFORM_TYPES.企业微信, PLATFORM_TYPES.微信].includes(env)) {
        return;
      }
      const ticket = await getWxTicket().catch((err) => {
        console.error('err---', err);
        return '';
      });
      const wxConfigInitRes = await initWxConfig(ticket);
      console.log('-----初始化成功', wxConfigInitRes);
      if (!wxConfigInitRes.success) {
        return;
      }
      setWxConfigReady(true);
      if (env === PLATFORM_TYPES.企业微信) {
        const ticket = await getWxAgentTicket().catch((err) => {
          return '';
        });
        const wxAgentConfigInitRes = await initWxAgentConfig(ticket);
        setWxAgentConfig(wxAgentConfigInitRes);
        dispatch({ type: ACTION_TYPES.WX_AGENT_INIT_SUCCESS, payload: true });
      }
    } catch (e) {
      console.error('initWxFunc err', e);
    }
  }, [getWxTicket, getWxAgentTicket, dispatch]);

  useEffect(() => {
    init();
    // location变化，需要重新初始化, init只初始化一次，避免初始化过多
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!wxConfigReady) {
      return;
    }
    if (env === PLATFORM_TYPES.微信 && options?.shareInfo) {
      window.wx[WX_FUNC_NAMES.显示左上角菜单]();
      const updateInfo = {
        title: options?.shareInfo?.title, // 分享标题
        desc: options?.shareInfo?.desc, // 分享描述
        imgUrl: options?.shareInfo?.imgUrl,
        link: options?.shareInfo?.link,
      };
      console.log('updateInfo---', updateInfo);
      [
        WX_FUNC_NAMES.转发好友,
        WX_FUNC_NAMES.转发朋友圈,
        WX_FUNC_NAMES.转发微信好友,
      ].forEach((item) => {
        if (window.wx[item]) {
          window.wx[item]({
            ...updateInfo,
            success: function () {
              console.log('朋友圈分享设置成功');
              if (options?.shareInfo?.success) {
                options.shareInfo.success();
              }
            },
            fail: function (e: any) {
              console.log('朋友圈分享设置失败', e);
            },
          });
        }
      });
    } else {
      window.wx[WX_FUNC_NAMES.隐藏左上角菜单]();
    }
  }, [wxConfigReady, options?.shareInfo]);

  const onShare = useCallback(
    (func: string, callback?: () => void) => {
      if (!wxAgentConfig || !func) {
        return;
      }
      callback?.();
    },
    [wxAgentConfig],
  );

  return {
    onShare,
    wxConfigReady,
    wxAgentConfig,
  };
};

export default useInitWxFunc;
