// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StickyTabs_wrap__POdBM {
  background: #f2f3f7;
}
.StickyTabs_wrap__POdBM .StickyTabs_stickyTabItemCardTitle__eOaX- {
  height: 1.25rem;
}
.StickyTabs_wrap__POdBM .adm-tabs-header {
  background-color: #f2f3f7;
}
.StickyTabs_wrap__POdBM .adm-tabs-tab {
  color: #666666;
  font-size: 0.875rem;
}
.StickyTabs_wrap__POdBM .adm-tabs-tab-active {
  font-size: 1rem;
  font-weight: bold;
  color: #1e1e1e;
}
.StickyTabs_wrap__POdBM .adm-tabs-tab-line {
  background-color: #3975c6;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/StickyTabs.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,eAAA;AACJ;AAGI;EACE,yBAAA;AADN;AAGI;EACE,cAAA;EACA,mBAAA;AADN;AAGI;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AADN;AAII;EACE,yBAAA;AAFN","sourcesContent":[".wrap {\r\n  background: #f2f3f7;\r\n\r\n  .stickyTabItemCardTitle {\r\n    height: 1.2500rem;\r\n  }\r\n\r\n  :global {\r\n    .adm-tabs-header {\r\n      background-color: #f2f3f7;\r\n    }\r\n    .adm-tabs-tab {\r\n      color: #666666;\r\n      font-size: 0.8750rem;\r\n    }\r\n    .adm-tabs-tab-active {\r\n      font-size: 1.0000rem;\r\n      font-weight: bold;\r\n      color: #1e1e1e;\r\n    }\r\n\r\n    .adm-tabs-tab-line {\r\n      background-color: #3975c6;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `StickyTabs_wrap__POdBM`,
	"stickyTabItemCardTitle": `StickyTabs_stickyTabItemCardTitle__eOaX-`
};
export default ___CSS_LOADER_EXPORT___;
