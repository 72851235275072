import React from 'react';
import cls from './ItemCardVertical.module.scss';

// import IconPerson from '../images/IconPerson.png';
import IconEye from '../images/IconEye.png';
import ShareBtn from './ShareBtn';
export interface ItemCardVerticalProps {
  title?: string;
  img?: string;
  share?: boolean;
  time?: string;
  name?: string;
  count?: string | number;
  onItemClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onImgClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onTitleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onShareClick?: (func: string) => void;
  shereid?: string | number;
  imgHeight?:string;
  onbutClick?:()=>void
}
const ItemCardVertical: React.FC<ItemCardVerticalProps> = (props) => {
  return (
    <div className={cls.wrap} onClick={props.onItemClick}>
      <div className={cls.imgWrap}>
        <img
          className={cls.img}
          style={{height:props.imgHeight?props.imgHeight:'5.0000rem'}}
          onClick={props.onImgClick}
          src={props.img}
          alt=""
        />
        {props.time ? <div className={cls.time}>{props.time}</div> : null}
      </div>
      <div className={cls.title} onClick={props.onTitleClick}>
        {props.title}
      </div>
      <div className={cls.info}>
        <div className={cls.infoContent}>
          {props.count ? (
            <div className={cls.flexCenter}>
              <img className={cls.icon} src={IconEye} alt="" />
              {props.count}
            </div>
          ) : (
            <div className={cls.flexCenter}></div>
          )}
        </div>
        {props.share && (
          <ShareBtn onIconClick={props.onShareClick} shereid={props.shereid} onbutClick={props.onbutClick} />
        )}
      </div>
    </div>
  );
};

export default ItemCardVertical;
