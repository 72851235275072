import axios, { AxiosResponse } from 'axios';
import request from '../utils/request';
import urls from './urls';
import useSWR from 'swr';
import { filterEmptyKeys } from '../utils/tools';
import { REGION_NAMES } from '../constants';

function defaultResultParse(res: AxiosResponse<any>) {
  const content = res.data;
  if (content.status === 200) {
    return content.data;
  }
  throw content;
}

/** 获取企业微信应用ticket */
export async function queryTicket(): Promise<API.IWxTicket | null> {
  return request
    .get(urls.getTicket)
    .then(defaultResultParse)
    .then((res) => {
      if (res && res.errmsg === 'ok' && res.ticket) {
        return {
          ticket: res.ticket,
          expiresTime: new Date().getTime() + res.expires_in * 500,
        };
      }
      return null;
    });
}
/** 获取企业微信ticket */
export async function queryJsapiTicket(): Promise<API.IWxTicket | null> {
  return request
    .get(urls.getJsapiTicket)
    .then(defaultResultParse)
    .then((res) => {
      if (res && res.errmsg === 'ok' && res.ticket) {
        return {
          ticket: res.ticket,
          expiresTime: new Date().getTime() + res.expires_in * 500,
        };
      }
      return null;
    });
}
/** 获取个人微信ticket */
export async function queryGrwxJsapiTicket(): Promise<API.IWxTicket | null> {
  return request
    .get(urls.queryGrwxJsapiTicket)
    .then(defaultResultParse)
    .then((res) => {
      if (res && res.errmsg === 'ok' && res.ticket) {
        return {
          ticket: res.ticket,
          expiresTime: new Date().getTime() + res.expires_in * 500,
        };
      }
      return null;
    });
}

/**查询财经午报、焦点复盘、明日前瞻 */
export function useQueryFinance(
  options: API.IReady & { dataType: string; id?: number | string },
) {
  const { dataType, id, ready = true } = options ?? {};
  const swrResult = useSWR(
    () => (ready ? ['useQueryFinance', dataType, id] : null),
    () => (id ? queryMediaById(id + '') : queryFinance(dataType)),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}
export async function queryFinance(dataType: string): Promise<API.IFinanceRes> {
  return request
    .get(urls.finance, { params: { dataType } })
    .then(defaultResultParse)
    .catch(() => null);
}

export function useQueryHotShareList(options?: API.IReady) {
  const { ready = true } = options ?? {};
  const swrResult = useSWR(
    () => (ready ? ['useQueryHotShareList'] : null),
    () => queryHotShareList(),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}

export async function queryHotShareList(): Promise<API.INews[] | null> {
  return request
    .get(urls.hotShare)
    .then(defaultResultParse)
    .catch(() => null);
}

export function useQueryActiveList(
  options?: API.IReady & API.ISearchReq & { revalidateIfStale?: boolean },
) {
  const { ready = true, revalidateIfStale, ...params } = options ?? {};

  const swrResult = useSWR(
    () => (ready ? ['useQueryActiveList', JSON.stringify(params)] : null),
    () => queryActiveList(params),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
      revalidateIfStale,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}

export async function queryActiveList(
  params: API.ISearchReq,
): Promise<API.INews[] | null> {
  return request
    .post(urls.activeList, filterEmptyKeys(params))
    .then(defaultResultParse)
    .then((res) => res?.pageDataList || []);
}

export function useQueryMediaTypeList(options?: API.IReady & API.ISearchReq) {
  const { ready = true } = options ?? {};
  const swrResult = useSWR(
    () => (ready ? ['useQueryMediaTypeList'] : null),
    () => queryMediaTypeList(),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}

export async function queryMediaTypeList(): Promise<API.IMediaTypeRes[]> {
  return request
    .get(urls.mediaType)
    .then(defaultResultParse)
    .catch(() => []);
}

export function useQueryMediaById(
  options?: API.IReady & { id?: string | number },
) {
  const { ready = true, id } = options ?? {};
  const swrResult = useSWR(
    () => (ready && id ? ['useQueryMediaById', id] : null),
    () => queryMediaById(id + ''),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}

export async function queryMediaById(id: string): Promise<API.INews> {
  return request
    .get(urls.shareMedia, { params: { id } })
    .then(defaultResultParse)
    .catch(() => []);
}
export function useQueryUpdateTimeInformation(options?: API.IReady) {
  const { ready = true } = options ?? {};
  const swrResult = useSWR(
    () => (ready ? ['queryUpdateTimeInformation'] : null),
    () => queryUpdateTimeInformation(),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}

export async function queryUpdateTimeInformation(): Promise<
  API.IUpdateTimeInformation[]
> {
  return request
    .get(urls.getUpdateTimeInformation)
    .then(defaultResultParse)
    .catch(() => []);
}
//投资早班机接口
// 获取新股新债
export async function querySellMarketDate(): Promise<API.IsellDate[]> {
  return request
    .get(urls.sellMarketDate, {})
    .then(defaultResultParse)
    .catch(() => []);
}
export function useQuerySellMarketDate(options?: API.IReady) {
  const { ready = true } = options ?? {};
  const swrResult = useSWR(
    () => (ready ? ['querySellMarketDate'] : null),
    () => querySellMarketDate(),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}
// 获取今日上市
export async function querySellDate(): Promise<API.IsellDate[]> {
  return request
    .get(urls.sellDate, {})
    .then(defaultResultParse)
    .catch(() => []);
}
export function useQuerySellDate(options?: API.IReady) {
  const { ready = true } = options ?? {};
  const swrResult = useSWR(
    () => (ready ? ['querySellDate'] : null),
    () => querySellDate(),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}
// 获取大盘指数
export async function queryGetLargeCap(): Promise<
  {
    name: string;
    newLargePan: string;
    lowRisk: string;
    limitLine: string;
  }[]
> {
  return request
    .get(urls.getLargeCap, {})
    .then(defaultResultParse)
    .catch(() => []);
}
export function useQueryGetLargeCap(options?: API.IReady) {
  const { ready = true } = options ?? {};
  const swrResult = useSWR(
    () => (ready ? ['queryGetLargeCap'] : null),
    () => queryGetLargeCap(),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}
// 更新分享次数
export async function queryGetUpdateHost(id: string): Promise<number> {
  return request
    .get(urls.getUpdateHost, { params: { id } })
    .then(defaultResultParse)
    .catch(() => {});
}
//  分享图片上传
export async function querySharePicture(imageFile: File): Promise<string> {
  const formData = new FormData();
  formData.append('file', imageFile);
  return axios
    .post(`${window.config.API_URL}${urls.sharePicture}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(defaultResultParse)
    .catch(() => {});
}

export function useQueryIndexSlide(
  options: API.IReady & {
    regionId: (typeof REGION_NAMES)[keyof typeof REGION_NAMES];
  },
) {
  const { ready = true, regionId } = options ?? {};
  const swrResult = useSWR(
    () =>
      ready && !window.config.hiddenSwiper
        ? ['queryIndexSlide', regionId]
        : null,
    () => queryIndexSlide(regionId),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}
// 获取轮播图
export async function queryIndexSlide(
  regionId: number,
): Promise<API.ISlideRes[]> {
  return request
    .get(urls.indexSlide, { params: { regionId } })
    .then(defaultResultParse);
}

// 通过聚合ID查询聚合信息以及资讯详情
export async function queryAggregationUrl(
  id: number,
): Promise<API.IAggregationUrl> {
  return request
    .get(urls.aggregationUrl, { params: {aggregationId: id } })
    .then(defaultResultParse);
}

export function useQueryAggregationUrl(id:number ) {
  const swrResult = useSWR(
    () => (id ? ['AggregationUrl',id] : null),
    () => queryAggregationUrl(id),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}

// 查询早班机子栏目
export async function queryGetMoningFlight(
): Promise<API.IMoningFlight[]> {
  return request
    .get(urls.getMoningFlight, { params: {} })
    .then(defaultResultParse);
}

export function useQueryGetMoningFlight(options?: API.IReady) {
  const { ready = true } = options ?? {};
  const swrResult = useSWR(
    () => (ready ? ['getMoningFlight',] : null),
    () => queryGetMoningFlight(),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    ...swrResult,
    loading: !swrResult.data && !swrResult.error,
  };
}

// 查询早班机图片
export async function queryMoningFlightPicture(
  ): Promise<API.IMoningFlightPicture[]> {
    return request
      .get(urls.moningFlightPicture, { params: {} })
      .then(defaultResultParse);
  }
  
  export function useQueryMoningFlightPicture(options?: API.IReady) {
    const { ready = true } = options ?? {};
    const swrResult = useSWR(
      () => (ready ? ['moningFlightPicture',] : null),
      () => queryMoningFlightPicture(),
      {
        errorRetryCount: 0,
        revalidateOnFocus: false,
      },
    );
    return {
      ...swrResult,
      loading: !swrResult.data && !swrResult.error,
    };
  }
 //  查询早班机子栏目详情
  export async function queryMoningFlightById(
    id: number,
  ): Promise<API.IMoningFlight> {
    return request
      .get(urls.moningFlightById, { params: {id: id } })
      .then(defaultResultParse);
  }
  
  export function useQueryMoningFlightById(id:number ) {
    const swrResult = useSWR(
      () => (id ? ['moningFlightById',id] : null),
      () => queryMoningFlightById(id),
      {
        errorRetryCount: 0,
        revalidateOnFocus: false,
      },
    );
    return {
      ...swrResult,
      loading: !swrResult.data && !swrResult.error,
    };
  }

  export function useQueryInvestmentAdviserList(
    options?: API.IReady & API.ISearchAdviser & { revalidateIfStale?: boolean },
  ) {
    const { ready = true, revalidateIfStale, ...params } = options ?? {};
  
    const swrResult = useSWR(
      () => (ready ? ['useQueryInvestmentAdviserList', JSON.stringify(params)] : null),
      () => queryInvestmentAdviserList(params),
      {
        errorRetryCount: 0,
        revalidateOnFocus: false,
        revalidateIfStale,
      },
    );
    return {
      ...swrResult,
      loading: !swrResult.data && !swrResult.error,
    };
  }
  
  export async function queryInvestmentAdviserList(
    params: API.ISearchAdviser,
  ): Promise<API.IInvestmentAdviser[] | null> {
    return request
      .get(urls.investmentAdviserList, { params:filterEmptyKeys(params)})
      .then(defaultResultParse)
      .then((res) => res?.pageDataList || []);
  }

  export async function queryInvestmentAdviserById(
    id: number,
  ): Promise<API.IInvestmentAdviserItem> {
    return request
      .get(urls.investmentAdviserById, { params: {id:id } })
      .then(defaultResultParse);
  }
  
  export function useQueryInvestmentAdviserById(id:number  ) {
    const swrResult = useSWR(
      () => (id ? ['useQueryInvestmentAdviserById',id] : null),
      () => queryInvestmentAdviserById(id),
      {
        errorRetryCount: 0,
        revalidateOnFocus: false,
      },
    );
    return {
      ...swrResult,
      loading: !swrResult.data && !swrResult.error,
    };
  }
  export async function queryInvestmentAdviser(
    id: number,
  ): Promise<boolean> {
    return request
      .post(urls.investmentAdviser, {id:id })
      .then(defaultResultParse);
      
  }
  

  /**
   * 员工名片
   */
  export async function queryGetCustorInfomation(
    code: number|string,
  ): Promise<API.IGetCustorInfomation> {
    return request
      .get(urls.getCustorInfomation, { params: {code: code } })
      .then(defaultResultParse)
      .then((res)=>res?.data||{})
  }
  
  export function useQueryGetCustorInfomation(code:number|string ) {
    const swrResult = useSWR(
      () => (code ? ['useQueryGetCustorInfomation',code] : null),
      () => queryGetCustorInfomation(code),
      {
        errorRetryCount: 0,
        revalidateOnFocus: false,
      },
    );
    return {
      ...swrResult,
      loading: !swrResult.data && !swrResult.error,
    };
  }