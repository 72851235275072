/**
 * 翼投教列表
 */

import React from 'react';
import { Grid, InfiniteScroll } from 'antd-mobile';
import ItemCardVertical from '../../components/ItemCardVertical';
import useJump from '../../hooks/useJump';
import useQueryNewsInfinate from '../../hooks/useQueryNewsInfinate';
import { MEDIA_TYPE_NAMES } from '../../constants';
import { fmtCurrentDateTime2Str, fmtRoute2FullPath } from '../../utils/tools';
import Nodata from '../../components/Nodata';
import Loading from '../../components/Loading';
import zhsp from '../../images/zhtj-mrt.png';
export interface YTJVideoListProps {
  searchValue?: string;
  count?: number;
  isLoadMore?: boolean;
  loadingColor?: string;
  onShare?: (func: string, item: API.INews, index: number) => void;
  onbutClick?:()=>void;
  onItemClick?:()=>void;
}
const YTJVideoList: React.FC<YTJVideoListProps> = ({
  searchValue,
  count,
  isLoadMore,
  loadingColor,
  onShare,
  onbutClick,
  onItemClick
}) => {
  const { jumpNewsDetailFromMediaType } = useJump();
  const { data, loading, loadMore, hasMore } = useQueryNewsInfinate({
    mediaTypeId: MEDIA_TYPE_NAMES.翼投教视频,
    searchValue,
    count,
  });
  return (
    <React.Fragment>
      <Grid columns={2} gap={12}>
        {data?.map((item, index) => (
          <Grid.Item key={item.id}>
            <ItemCardVertical
              img={zhsp}
              title={item.title}
              shereid={item.id}
              time={fmtCurrentDateTime2Str(item.updateTime, {
                format: 'MM-DD HH:mm',
              })}
              name={item.author}
              count={item.hostMedia}
              share
              onbutClick={onbutClick}
              onItemClick={() => {
                if(onItemClick){
                  onItemClick()
                }
                jumpNewsDetailFromMediaType(MEDIA_TYPE_NAMES.翼投教视频, {
                  url: item.url,
                  id: item.id,
                });
              }}
              onShareClick={(func) => {
                onShare?.(
                  func,
                  { ...item, img: fmtRoute2FullPath('/shareImg/zhsp.jpg') },
                  index,
                );
              }}
            />
          </Grid.Item>
        ))}
      </Grid>
      {!loading && !data?.length && (
        <Nodata content={searchValue ? '暂未搜索到相关数据' : '暂无数据'} />
      )}
      {/* 初始化loading */}
      {!data && loading ? (
        <Loading loadingColor={loadingColor} />
      ) : (
        hasMore &&
        !!data &&
        isLoadMore &&
        !loading && (
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <Loading loadingColor={loadingColor} />
          </InfiniteScroll>
        )
      )}
    </React.Fragment>
  );
};

export default YTJVideoList;
