import { useCallback, useEffect, useMemo } from 'react';

import { queryGetUpdateHost, useQueryMediaById } from '../services/api';
import { useParams } from 'react-router-dom';
import ShareBtnBottom from '../components/ShareBtnBottom';
import cls from './ZHDJDetail.module.scss';
import useInitWxFunc from '../hooks/useInitWxFunc';
import { shareWxworkMessage } from '../utils/wxUtil';
import useScrollToTop from '../hooks/useScrollToTop';
import { fmtRoute2FullPath } from '../utils/tools';
import PdfView from './components/PdfView';
import useTitle from '../hooks/useTitle';
import BusinessCard from './containers/BusinessCard';
import { getPushUrl } from '../utils/utils';
import { useGlobalState } from '../models/global';
import { track } from '../utils/sdk';
import { PUSH_NAME_BUT } from '../constants';

function ZHDJDetail() {
  useScrollToTop();
  useTitle('中航独家');
  useEffect(()=>{
    track('页面浏览',{
      page_id:'leaf1003',
      page_title:'中航独家'
    })
  },[])
  const [{wxUseData}]=useGlobalState()
  const { id } = useParams();
  const { data } = useQueryMediaById({ id });
  const shareInfo = useMemo(() => {
    if (!data?.id) {
      return undefined;
    }
    return {
      title: data?.title,
      link: getPushUrl(window.location.href,wxUseData),
      desc: data?.shortTitle,
      imgUrl: data?.img || fmtRoute2FullPath('/shareImg/zhdj.jpg'),
      success: () => {
        queryGetUpdateHost(data.id + '');
      },
    };
  }, [data,wxUseData]);
  const { onShare } = useInitWxFunc({ shareInfo });
  const handleNewsShareClick = useCallback(
    (func: string) => {
      queryGetUpdateHost(`${data?.id}`);
      track('按钮点击',{
        page_id:'leaf1003',
        page_title:'中航独家',
        btn_title:`${PUSH_NAME_BUT[func]}`
      })
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: data?.title,
          link: getPushUrl(window.location.href,wxUseData),
          desc: data?.shortTitle,
          imgUrl: data?.img || fmtRoute2FullPath('/shareImg/zhdj.jpg'),
        });
      });
    },
    [onShare, data,wxUseData],
  );
  return (
    <div className={cls.wrap}>
       <BusinessCard />
      <div className={cls.content}>
        {data?.viewers === 1 && <PdfView file={data?.url || ''} />}
      </div>
      <ShareBtnBottom onIconClick={handleNewsShareClick} />
    </div>
  );
}

export default ZHDJDetail;
