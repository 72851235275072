import { useCallback } from 'react';
import Swiper from '../../components/Swiper';
import { REGION_NAMES } from '../../constants';
import { useQueryIndexSlide } from '../../services/api';

const PageSwiper: React.FC<{
  regionName: keyof typeof REGION_NAMES;
  style?: React.CSSProperties;
  onClick?:()=>void
}> = ({ regionName, style,onClick }) => {
  const { data } = useQueryIndexSlide({ regionId: REGION_NAMES[regionName] });
  const handleItemClick = useCallback((item: API.ISlideRes, index: number) => {
    if(onClick){
      onClick()
    }
    window.location.href = item.url;
  }, [onClick]);
  return (
    <>
      {data && data?.length ? (
        <div style={style}>
          <Swiper onClick={handleItemClick} data={data} />
        </div>
      ) : null}
    </>
  );
};
export default PageSwiper;
