import { useCallback, useEffect, useMemo } from 'react';
import Tips from '../components/Tips';
import ShareBtnBottom from '../components/ShareBtnBottom';
import CJZBCard from './components/CJZBCard';
import CJZBVideo from './components/CJZBVideo';
import cls from './CJZB.module.scss';
import CJZBStockCard from './components/CJZBStockCard';
import { Grid } from 'antd-mobile';
import CJZBMarketIndexCard from './components/CJZBMarketIndexCard';
import CJZBNewsCard from './components/CJZBNewsCard';
import moment from 'moment';
import useTitle from '../hooks/useTitle';
import useInitWxFunc from '../hooks/useInitWxFunc';
import { shareWxworkMessage } from '../utils/wxUtil';
import useScrollToTop from '../hooks/useScrollToTop';

import BannerCard from './components/BannerCard';
import { MEDIA_TYPE_NAMES, MRBZ_DESCRIPTIONS, PUSH_NAME_BUT, URL_NAMES } from '../constants';
import dhUrl from '../images/cjzb-dh.png';
import {
  queryGetUpdateHost,
  useQueryActiveList,
  useQueryGetLargeCap,
  useQuerySellDate,
  useQuerySellMarketDate,
} from '../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { fmtRoute2FullPath, getFullPathFromMediaTypeIds } from '../utils/tools';
import PageSwiper from './containers/PageSwiper';
import SubColumns from './containers/SubColumns';
import ConsultationZone from './containers/ConsultationZone';
import BusinessCard from './containers/BusinessCard';
import { useGlobalState } from '../models/global';
import { getPushUrl } from '../utils/utils';
import { track } from '../utils/sdk';
import XGKHXGGG from './XGKH';

/**
 * 投资早班机，投资早班机
 */
const list = ['公司新闻', '行业新闻', '宏观新闻'];
const getWeek = () => {
  const week = moment().day();
  switch (week) {
    case 1:
      return '周一';
    case 2:
      return '周二';
    case 3:
      return '周三';
    case 4:
      return '周四';
    case 5:
      return '周五';
    case 6:
      return '周六';
    case 0:
      return '周日';
  }
};
const PAGE_NAME = '投资早班机';
const CJZB = () => {
  const navigator = useNavigate();
  useScrollToTop();
  useTitle(PAGE_NAME);
  const [{ wxUseData }] = useGlobalState()
  const { data: yszbData } = useQueryActiveList({
    mediaTypeId: MEDIA_TYPE_NAMES.投资早班机,
    pageMaxCount: 1,
  });
  useEffect(() => {
    track('页面浏览', {
      page_id: 'leaf1003',
      page_title: '投资早班机'
    })
  }, [])
  const { date } = useParams();
  useEffect(() => {
    if (yszbData?.length === 0 && date) {
      // url入参有日期 且 没有请求到该日数据，跳转首页
      navigator(URL_NAMES.投资早班机, { replace: true });
    }
  }, [yszbData, date, navigator]);

  const shareInfo = useMemo(() => {
    if (!yszbData?.[0]?.id) {
      return undefined;
    }
    return {
      title: `${moment(yszbData[0].updateTime, 'YYYY-MM-DD HH:mm:ss').format(
        'M.DD',
      )}${PAGE_NAME}|${yszbData[0].title}`,
      link: getPushUrl(getFullPathFromMediaTypeIds(MEDIA_TYPE_NAMES[PAGE_NAME], {
        date: moment(yszbData[0]?.updateTime, 'YYYY-MM-DD HH:mm:ss').format(
          'YYYYMMDD',
        ),
      }), wxUseData),
      imgUrl: fmtRoute2FullPath('/shareImg/zbj.jpg'),
      desc: yszbData[0]?.shortTitle || MRBZ_DESCRIPTIONS[PAGE_NAME],
      success: () => {
        queryGetUpdateHost(yszbData[0].id + '');
      },
    };
  }, [yszbData, wxUseData]);
  const { onShare } = useInitWxFunc({ shareInfo });

  const { data: SellMarketDate } = useQuerySellMarketDate();
  const { data: SellDate } = useQuerySellDate();
  
  const { data: largeCapList } = useQueryGetLargeCap();

  const showLargeCapCard = useMemo(() => {
    return largeCapList && largeCapList?.length > 0;
  }, [largeCapList]);

  const { data: zhttData } = useQueryActiveList({
    mediaTypeId: MEDIA_TYPE_NAMES.置顶标签资讯,
    pageMaxCount: 2,
  });
  const { data: gyhqData } = useQueryActiveList({
    mediaTypeId: MEDIA_TYPE_NAMES.隔夜环球市场,
    pageMaxCount: 1,
  });
  const { data: tzyjData } = useQueryActiveList({
    mediaTypeId: MEDIA_TYPE_NAMES.每日投资预警,
    pageMaxCount: 1,
  });
  const onCardClick = useCallback(
    (e: any) => {
      if (e && e.id) {
        const url = URL_NAMES.投资早班机详情页 + '/' + e.id;
        navigator(url);
      }
    },
    [navigator],
  );
  const handleNewsShareClick = useCallback(
    (func: string) => {
      queryGetUpdateHost(`${yszbData?.[0]?.id}`);
      track('按钮点击', {
        page_id: 'leaf1003',
        page_title: '投资早班机',
        btn_title: `${PUSH_NAME_BUT[func]}`
      })
      onShare(func, () => {
        const { success, ...otherShareInfo } = shareInfo || {};
        shareWxworkMessage(func, otherShareInfo);
      });
    },
    [onShare, yszbData, shareInfo],
  );
  const onimgClick = useCallback((url: string) => {
    if (url) {
      window.location.href = url;
    }
  }, [])
  console.log(yszbData);
  return (
    <div className={cls.wrap}>
      <BusinessCard />
      <BannerCard
        timeType={false}
        time={yszbData?.[0]?.updateTime ?? ''}
        bgColor={`rgba(60,66,151,0.5)`}
        pageTitle={URL_NAMES.投资早班机}
      />
      <div className={cls.content}>
        {/* 中航之声 */}
        {yszbData && yszbData?.[0]?.stany2 && (
          <div className={cls.zhzs}>
            <div className={cls.zhzsContent}>
              <div className={cls.title}>中航之声</div>
              <div className={cls.sbtitle}>
                {list.map((item, index) => (
                  <div className={cls.sbTitleList} key={index}>
                    <div className={cls.imgView}>
                      <img src={dhUrl} className={cls.img} alt="" />
                    </div>
                    <div className={cls.text}>{item}</div>
                  </div>
                ))}
              </div>
              <CJZBVideo vidUrl={yszbData?.[0]?.stany2} />
            </div>
          </div>
        )}

        <PageSwiper regionName={PAGE_NAME} style={{ paddingTop: '0.6250rem' }} />
        <XGKHXGGG data={yszbData?.[0]}/>
        {/* 打新日历 */}
        <CJZBCard
          titleImg={require('../images/cjzb-dxrl.png')}
          style={{ background: '#FFFFFF' }}
          time={`${moment().format('MM月DD日')} (${getWeek()})`}
        >
          <CJZBStockCard
            key="xgxz"
            titleImg={require('../images/xgxz-1.png')}
            titleImg2={require('../images/xgxz-2.png')}
            titleImg3={require('../images/xgxz-3.png')}
            tagColor="#FEC749"
            stockList={SellMarketDate?.slice(0,6)}
            style={{
              '--divider-color': '#FFAB42',
              '--stock-row-bg':
                'linear-gradient(180deg, #FFFCF6 0%, #FFF4E6 100%)',
            }}
            noDataText='今日无新股新债申购'
          />


          <CJZBStockCard
            key="jrss"
            titleImg={require('../images/jrss-1.png')}
            titleImg2={require('../images/jrss-2.png')}
            titleImg3={require('../images/jrss-3.png')}
            style={{
              '--divider-color': '#428BFF',
              '--stock-row-bg':
                'linear-gradient(180deg, #F6FCFF 0%, #E6EDFF 100%)',
            }}
            tagColor="#74ACF6"
            stockList={SellDate?.slice(0,6)}
            noDataText='今日无新股新债上市'
          />

        </CJZBCard>


        {/* 大盘指数 */}
        {showLargeCapCard ? (
          <CJZBCard
            titleImg={require('../images/cjzb-dpzs.png')}
            style={{ background: '#FFFFFF ' }}
          >
            <Grid
              columns={3}
              style={{ '--gap-horizontal': '0.2500rem', '--gap-vertical': '0.5625rem' }}
            >
              {largeCapList?.map((item, index: any) => (
                <Grid.Item key={index}>
                  <CJZBMarketIndexCard data={item} />
                </Grid.Item>
              ))}
            </Grid>
          </CJZBCard>
        ) : null}
        <SubColumns />
        {/* 中航头条 */}
        {zhttData && zhttData.length > 0 && (
          <CJZBCard
            titleImg={require('../images/cjzb-zhtt.png')}
            style={{ background: '#FFFFFF ' }}
          >
            {zhttData.map((item, index) => (
              <CJZBNewsCard
                showDetailBtn
                key={index}
                title={item.title}
                description={item.shortTitle}
                onCardClick={() => onCardClick(item)}
              />
            ))}
          </CJZBCard>
        )}
        {/* 今日投资预警 */}
        {tzyjData && tzyjData.length > 0 && (
          <CJZBCard
            titleImg={require('../images/cjzb-jrtzyj.png')}
            style={{ background: '#FFFFFF ' }}
          >
            {tzyjData.map((item, index) => (
              <CJZBNewsCard
                showDetailBtn
                key={index}
                title={item.title}
                description={item.shortTitle}
                onCardClick={() => onCardClick(item)}
              />
            ))}
          </CJZBCard>
        )}

        {/* 隔夜环球新闻 */}
        {gyhqData?.[0]?.conten ? (
          <CJZBCard
            titleImg={require('../images/cjzb-gyhqxf.png')}
            style={{ background: '#FFFFFF ' }}
          >
            <div className={cls.card}>
              <div className={cls.text}>{gyhqData?.[0]?.conten}</div>
            </div>
          </CJZBCard>
        ) : null}
        {/* 财经新闻 */}
        {yszbData && yszbData?.length > 0 ? (
          <CJZBCard
            titleImg={require('../images/cjzb-cjxf.png')}
            style={{
              background: '#FFFFFF',
              marginBottom: '0.0000rem',
              paddingBottom: '0.0000rem',
            }}
          >
            <div className={cls.contentView}>
              <div
                className={cls.content + ' reach-text-content'}
                dangerouslySetInnerHTML={{
                  __html: yszbData?.[0]?.conten ?? '',
                }}
              ></div>
            </div>
          </CJZBCard>
        ) : null}
        <ConsultationZone onimgClick={onimgClick} />
      </div>

      <Tips textColor="#989898" style={{ padding: '2.1875rem 0.9375rem 3.7500rem' }} title='以上内容来自恒生聚源、财联社等平台。' />
      <ShareBtnBottom onIconClick={handleNewsShareClick} />
    </div>
  );
};

export default CJZB;
