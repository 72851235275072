/**
 * 翼投教
 */
import { Tabs } from 'antd-mobile';

import cls from './YTJList.module.scss';
import Card from '../../components/Card';
import YTJImageTextList from './YTJImageTextList';
import YTJVideoList from './YTJVideoList';
export interface YTJListProps {
  searchValue?: string;
  onShare?: (func: string, item: API.INews, index: number) => void;
}
let lazyTabKey = sessionStorage.getItem('tebKey')||'1';


export const resetLazy = (key:string) => {
  sessionStorage.setItem('tebKey',key)
};


const YTJList: React.FC<YTJListProps> = ({ searchValue, onShare }) => {
  return (
    <Tabs
      className={cls.wrap}
      stretch={false}
      activeLineMode="fixed"
      style={{
        '--fixed-active-line-width': '1.1875rem',
        '--content-padding': '0',
      }}
      defaultActiveKey={lazyTabKey}
      onChange={(key)=>resetLazy(key)}
    >
      <Tabs.Tab title="中航图说" key="1">
        <Card styles={{ marginLeft: 0, marginRight: 0 }}>
          <YTJImageTextList
            searchValue={searchValue}
            count={20}
            isLoadMore
            onShare={onShare}
          />
        </Card>
      </Tabs.Tab>
      <Tabs.Tab title="中航视频" key="2">
        <Card styles={{ marginLeft: 0, marginRight: 0 }}>
          <YTJVideoList
            searchValue={searchValue}
            count={20}
            isLoadMore
            onShare={onShare}
          />
        </Card>
      </Tabs.Tab>
    </Tabs>
  );
};

export default YTJList;
