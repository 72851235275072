// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nodata_wrap__tnooC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4.375rem 0.9375rem;
  font-size: 1.125rem;
  color: rgba(156, 158, 174, 0.8);
}
.Nodata_wrap__tnooC > img {
  width: 6.6875rem;
  margin-bottom: 1.5625rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Nodata.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,2BAAA;EACA,mBAAA;EACA,+BAAA;AACF;AAAE;EACE,gBAAA;EACA,wBAAA;AAEJ","sourcesContent":[".wrap {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 4.3750rem 0.9375rem;\r\n  font-size: 1.1250rem;\r\n  color: rgba(156, 158, 174, 0.8);\r\n  > img {\r\n    width: 6.6875rem;\r\n    margin-bottom: 1.5625rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Nodata_wrap__tnooC`
};
export default ___CSS_LOADER_EXPORT___;
