import React from 'react';
import { PLATFORM_TYPES } from '../constants';
export interface GlobalState {
  isAppLoaded: boolean;
  wxReady: boolean;
  wxAgentInitSuccess?: boolean;
  wxTicket?: {
    ticket: string;
    expiresTime: number; // 微信ticket过期时间，时间戳
  };
  wxAgentTicket?: {
    ticket: string;
    expiresTime: number; // 微信 agent ticket过期时间，时间戳
  };
  platform?: (typeof PLATFORM_TYPES)[keyof typeof PLATFORM_TYPES];
  wxPushData?:API.IGetCustorInfomation;
  wxUseData?:API.IGetCustorInfomation;
}

export const ACTION_TYPES = {
  APP_LOADED: 'APP_LOADED',
  WX_READY: 'WX_READY',
  WX_AGENT_STATUS: 'WX_AGENT_STATUS',
  PLATFORM: 'PLATFORM',
  WX_TICKET: 'WX_TICKET',
  WX_AGENT_TICKET: 'WX_AGENT_TICKET',
  WX_AGENT_INIT_SUCCESS: 'WX_AGENT_INIT_SUCCESS',
  WX_PUSH_DATA:'WX_PUSH_DATA',
  WX_USE_DATA:'WX_USE_DATA',

};

type ActionTypes = (typeof ACTION_TYPES)[keyof typeof ACTION_TYPES];

export interface Action {
  type: ActionTypes;
  payload: any;
}

export const defaultState: GlobalState = {
  isAppLoaded: false,
  wxReady: false,
  wxAgentInitSuccess:true
};

export const reducer: React.Reducer<GlobalState, Action> = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.APP_LOADED: {
      return {
        ...state,
        isAppLoaded: true,
      };
    }
    case ACTION_TYPES.WX_READY: {
      return {
        ...state,
        wxReady: true,
      };
    }
    case ACTION_TYPES.WX_TICKET: {
      return {
        ...state,
        wxTicket: action.payload,
      };
    }
    case ACTION_TYPES.WX_AGENT_TICKET: {
      return {
        ...state,
        wxAgentTicket: action.payload,
      };
    }
    case ACTION_TYPES.PLATFORM: {
      return {
        ...state,
        platform: action.payload,
      };
    }
    case ACTION_TYPES.WX_AGENT_INIT_SUCCESS: {
      return {
        ...state,
        wxAgentInitSuccess: action.payload,
      };
    }
    case ACTION_TYPES.WX_PUSH_DATA: {
      return {
        ...state,
        wxPushData: action.payload,
      };
    }
    case ACTION_TYPES.WX_USE_DATA: {
      return {
        ...state,
        wxUseData: action.payload,
      };
    }
    default:
      return state;
  }
};

export const Context = React.createContext<{
  state: GlobalState;
  dispatch: React.Dispatch<Action>;
}>({ state: defaultState, dispatch: () => {} });

export function useContextValueGlobalState() {
  const [globalState, dispatch] = React.useReducer(reducer, defaultState);
  const contextValue = React.useMemo(
    () => ({ state: globalState, dispatch }),
    [globalState],
  );
  return contextValue;
}

export function useGlobalState(): [GlobalState, React.Dispatch<Action>] {
  const { state, dispatch } = React.useContext(Context);
  return [state, dispatch];
}
