// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CJZBNewsCard_cardWrap__u6DHw {
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem dashed rgba(89, 95, 118, 0.2);
  margin-bottom: 1.25rem;
}
.CJZBNewsCard_cardWrap__u6DHw:last-child {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 0rem;
}

.CJZBNewsCard_topView__8uz1K {
  display: flex;
}
.CJZBNewsCard_topView__8uz1K .CJZBNewsCard_imgView__42lqe {
  padding-top: 0.25rem;
  padding-right: 0.375rem;
}
.CJZBNewsCard_topView__8uz1K .CJZBNewsCard_imgView__42lqe .CJZBNewsCard_img__Y4HPc {
  width: 0.8125rem;
  height: 0.5rem;
}

.CJZBNewsCard_title__8Kzjp {
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  color: #252f42;
  line-height: 1.5625rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.CJZBNewsCard_description__g\\+Gei {
  color: #595f76;
  font-size: 0.875rem;
  line-height: 1.4375rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.CJZBNewsCard_detailBtn__NNDvU {
  color: #5696f0;
  font-size: 0.9375rem;
  margin-top: 0.875rem;
  line-height: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/CJZBNewsCard.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,sDAAA;EACA,sBAAA;AACF;AACE;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAGA;EACE,aAAA;AAAF;AAEE;EACE,oBAAA;EACA,uBAAA;AAAJ;AACI;EACE,gBAAA;EACA,cAAA;AACN;;AAGA;EACE,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,cAAA;EACA,sBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,uBAAA;EACA,gBAAA;AAAF;;AAEA;EACE,cAAA;EACA,mBAAA;EACA,sBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AACA;EACE,cAAA;EACA,oBAAA;EACA,oBAAA;EACA,cAAA;AAEF","sourcesContent":[".cardWrap {\r\n  padding-bottom: 1.2500rem;\r\n  border-bottom: 0.0625rem dashed rgba(89, 95, 118, 0.2);\r\n  margin-bottom: 1.2500rem;\r\n\r\n  &:last-child {\r\n    padding-bottom: 0;\r\n    border-bottom: 0;\r\n    margin-bottom: 0.0000rem;\r\n  }\r\n}\r\n\r\n.topView {\r\n  display: flex;\r\n\r\n  .imgView {\r\n    padding-top: 0.2500rem;\r\n    padding-right: 0.3750rem;\r\n    .img {\r\n      width: 0.8125rem;\r\n      height: 0.5000rem;\r\n    }\r\n  }\r\n}\r\n.title {\r\n  font-weight: bold;\r\n  font-size: 1.1250rem;\r\n  margin-bottom: 0.5000rem;\r\n  color: #252f42;\r\n  line-height: 1.5625rem;\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 4;\r\n  -webkit-box-orient: vertical;\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n}\r\n.description {\r\n  color: #595f76;\r\n  font-size: 0.8750rem;\r\n  line-height: 1.4375rem;\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 4;\r\n  -webkit-box-orient: vertical;\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n}\r\n.detailBtn {\r\n  color: #5696f0;\r\n  font-size: 0.9375rem;\r\n  margin-top: 0.8750rem;\r\n  line-height: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrap": `CJZBNewsCard_cardWrap__u6DHw`,
	"topView": `CJZBNewsCard_topView__8uz1K`,
	"imgView": `CJZBNewsCard_imgView__42lqe`,
	"img": `CJZBNewsCard_img__Y4HPc`,
	"title": `CJZBNewsCard_title__8Kzjp`,
	"description": `CJZBNewsCard_description__g+Gei`,
	"detailBtn": `CJZBNewsCard_detailBtn__NNDvU`
};
export default ___CSS_LOADER_EXPORT___;
