import React, { useState } from 'react';
import cls from './ItemCardHorizontal.module.scss';
import ShareBtn from './ShareBtn';

export interface ItemCardHorizontalProps {
  title?: string;
  img?: string;
  description?: string;
  share?: boolean;
  time?: string | React.ReactNode;
  imgBottomInfo?: string;
  onItemClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onTitleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onShareClick?: (func: string) => void;
  shereid?: string | number;
  isTG?:boolean;
  onbutClick?:()=>void;
  customerList?: API.ICustomer[];
  stockList?: API.IStock[];
}
const ItemCardHorizontal: React.FC<ItemCardHorizontalProps> = (props) => {

  const [isExpanded, setIsExpanded] = useState(false);
 
  // 截取文本
  let customerList = props.customerList;
  let customerMaxList = props.customerList;
  if (customerList && !isExpanded && customerList.length > 3) {
    customerMaxList = customerList.slice(0, 3);
  }
 
  // 切换展开/收起状态
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={cls.wrap} onClick={props.onItemClick}>
      <div className={cls.img} style={{ backgroundImage: `url(${props.img})` }}>
        {props.imgBottomInfo ? (
          <div className={cls.imgBottomInfo}>{props.imgBottomInfo}</div>
        ) : null}
      </div>
      <div className={cls.info}>
        <div className={cls.title} onClick={props.onTitleClick}>
          {props?.title}
        </div>
        <div className={cls.description}>{props.description}</div>    
        <div className={cls.group_15}>
          <div className={cls.textwrapper1}>
            {customerMaxList && customerMaxList.map((item, index) => (
              <div key={index} className={cls.textwrapper2}>
                <div className={cls.text1}>
                    {item.customerName}
                </div>
            </div>
          ))}
          </div>
          {customerList && customerList.length > 8 && (
            <div className={cls.textwrapper3}>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExpand();
                }}
                className={cls.text2}>
                {isExpanded ? '收起' : '更多'}
              </button>
            </div>
          )}
        </div>
        <div className={cls.flex}>
          <div className={cls.time}>{props.time}</div>
          {props.share && (
            <ShareBtn
              onIconClick={props.onShareClick}
              shereid={props.shereid}
              isTG={props?.isTG}
              onbutClick={props.onbutClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemCardHorizontal;
