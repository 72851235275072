/**
 * 中航快讯
 */

import { InfiniteScroll } from 'antd-mobile';
import { MEDIA_TYPE_NAMES } from '../constants';
import useQueryNewsInfinate from '../hooks/useQueryNewsInfinate';
import NewsTimeline from './components/NewsTimeline';
import cls from './ZHKX.module.scss';
import Nodata from '../components/Nodata';
import Loading from '../components/Loading';
import ImgShare from './components/ImgShare';
import Card from '../components/Card';
import { useCallback, useEffect, useState } from 'react';
import useScrollToTop from '../hooks/useScrollToTop';
import useTitle from '../hooks/useTitle';
import { track } from '../utils/sdk';
const ZHKX = () => {
  useScrollToTop();
  useTitle('中航快讯');
  const { data, loading, loadMore, hasMore } = useQueryNewsInfinate({
    mediaTypeId: MEDIA_TYPE_NAMES.电报,
    count: 30,
    revalidateIfStale: true,
  });

  const [shareData, setShareData] = useState<API.INews>();
  const [shareCardVisible, setShareCardVisible] = useState(false);
  useEffect(()=>{
    track('页面浏览',{
      page_id:'leaf1003',
      page_title:'中航快讯'
    })
  },[])
  const handleShareClick = useCallback((item: API.INews, index: number) => {
    track('按钮点击', {
      page_id: 'leaf1003',
      page_title: '中航快讯',
      btn_title: `分享`
    })
    setShareData(item);
    setShareCardVisible(true);
  }, []);

  const hiddenShare = useCallback(() => {
    setShareCardVisible(false);
  }, []);
  return (
    <div className={cls.wrap}>
      <ImgShare
        onMaskClick={hiddenShare}
        onFail={hiddenShare}
        visible={shareCardVisible}
        data={shareData}
      />
      <Card>
        <NewsTimeline data={data} onShareClick={handleShareClick} />
        {!loading && !data?.length && <Nodata />}
        {/* 初始化loading */}
        {!data && loading ? (
          <Loading />
        ) : (
          hasMore &&
          !!data &&
          !loading && (
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
              <Loading />
            </InfiniteScroll>
          )
        )}
      </Card>
    </div>
  );
};
export default ZHKX;
