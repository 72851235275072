/**
 * 今日必做- 项目
 */

import { RightOutline } from 'antd-mobile-icons';
import cls from './DailyTaskItem.module.scss';
import { useCallback } from 'react';
import ShareBtn from '../../components/ShareBtn';

export interface DailyTaskItemProps {
  time?: string;
  canShare?: boolean;
  titleImg: string;
  description: string;
  moreColor: string;
  descriptionColor: string;
  shareBgColor: string;
  background: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onShareClick?: (func: string) => void;
  onbutClick?:() => void;
}
const DailyTaskItem: React.FC<DailyTaskItemProps> = (props) => {
  const { onShareClick, onClick, canShare,onbutClick } = props;
  const handleCardClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (onClick && canShare) {
        onClick(e);
      }
    },
    [onClick, canShare],
  );
  return (
    <div className={cls.wrap}>
      <div
        className={`${cls.time}${props.canShare ? ' ' + cls.highLight : ''}`}
      >
        {props.time
          ? `${props.time.slice(0, 2)}:${props.time.slice(2, 4)}`
          : ''}
      </div>
      <div
        onClick={handleCardClick}
        className={cls.cardWrap}
        style={{ backgroundImage: `url('${props.background}')` }}
      >
        <div className={cls.title} style={{ color: props.moreColor }}>
          {' '}
          <img className={cls.titleImg} src={props.titleImg} alt="" />{' '}
          <RightOutline />
        </div>
        <div
          style={{ color: props.descriptionColor }}
          className={cls.description}
        >
          {props.description}
        </div>
        {canShare ? (
          <ShareBtn
            onIconClick={onShareClick}
            style={{
              background: props.shareBgColor,
              border: 'none',
              lineHeight: 1,
            }}
            className={cls.shareBtn}
            onbutClick={onbutClick}
          >
            转发获客
          </ShareBtn>
        ) : (
          <div
            style={{ background: props.shareBgColor }}
            className={cls.shareBtn}
          >
            敬请期待
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyTaskItem;
