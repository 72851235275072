import React from 'react';
import cls from './CJZBMarketIndexCard.module.scss';

export interface CJZBMarketIndexCardProps {
  data: {
    name: string;
    newLargePan: string;
    lowRisk: string;
    limitLine: string;
  };
}
const CJZBMarketIndexCard: React.FC<CJZBMarketIndexCardProps> = ({ data }) => {
  const getBgColor = (score: number) => {
    switch (true) {
      case score > 0:
        return cls.up;
      case score === 0:
        return cls.dft;
      case score < 0:
        return cls.down;
      default:
        return cls.dft;
    }
  };
  return (
    <div className={`${cls.wrap} ${getBgColor(Number(data?.lowRisk || 0))}`}>
      <div className={cls.title}>{data?.name || '--'}</div>
      <div className={cls.index}>{data?.newLargePan || '--'}</div>
      <div className={cls.info}>
        <span>{data?.lowRisk || '--'}</span>
        <span>{data?.limitLine || '--'}</span>
      </div>
    </div>
  );
};

export default CJZBMarketIndexCard;
