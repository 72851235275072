// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TGGDDetail_warp__nyTqj {
  background: #f1f2f8;
  min-height: 100vh;
}
.TGGDDetail_warp__nyTqj .TGGDDetail_conent__LmEig {
  padding: 0 0.9375rem;
  margin-top: -4.6875rem;
}
.TGGDDetail_warp__nyTqj .TGGDDetail_conent__LmEig .TGGDDetail_conentView__u0JqR {
  display: flex;
  padding-left: 0.9375rem;
  padding-top: 0.625rem;
}
.TGGDDetail_warp__nyTqj .TGGDDetail_conent__LmEig .TGGDDetail_conentView__u0JqR .TGGDDetail_leftView__AgwhB {
  width: 0.25rem;
  height: 2.5rem;
  opacity: 0.15;
  border-radius: 0.1875rem 0.1875rem, 0.1875rem, 0.1875rem;
  background: rgb(19, 104, 218);
}
.TGGDDetail_warp__nyTqj .TGGDDetail_conent__LmEig .TGGDDetail_conentView__u0JqR .TGGDDetail_rightView__uW-yq {
  padding-left: 0.5rem;
}
.TGGDDetail_warp__nyTqj .TGGDDetail_conent__LmEig .TGGDDetail_conentView__u0JqR .TGGDDetail_rightView__uW-yq .TGGDDetail_text__PyWzE {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(37, 47, 66, 0.8);
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/pages/TGGDDetail.module.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;EACA,iBAAA;AADJ;AAGI;EACI,oBAAA;EACA,sBAAA;AADR;AAGQ;EACI,aAAA;EACA,uBAAA;EACA,qBAAA;AADZ;AAGY;EACI,cAAA;EACA,cAAA;EACA,aAAA;EACA,wDAAA;EACA,6BAAA;AADhB;AAGY;EACI,oBAAA;AADhB;AAEgB;EACI,mBAAA;EACA,oBAAA;EACA,4BAAA;EACA,mBAAA;AAApB","sourcesContent":["@import '../variable.scss';\r\n\r\n.warp {\r\n    background: #f1f2f8;\r\n    min-height: 100vh;\r\n\r\n    .conent {\r\n        padding: 0 0.9375rem;\r\n        margin-top: -4.6875rem;\r\n\r\n        .conentView {\r\n            display: flex;\r\n            padding-left: 0.9375rem;\r\n            padding-top: 0.6250rem;\r\n\r\n            .leftView {\r\n                width: 0.2500rem;\r\n                height: 2.5000rem;\r\n                opacity: 0.15;\r\n                border-radius: 0.1875rem 0.1875rem, 0.1875rem, 0.1875rem;\r\n                background: rgba(19, 104, 218, 1);\r\n            }\r\n            .rightView {\r\n                padding-left: 0.5000rem;\r\n                .text {\r\n                    font-size: 0.8750rem;\r\n                    line-height: 1.2500rem;\r\n                    color: rgba(37, 47, 66, 0.8);\r\n                    text-align: justify;\r\n    \r\n                }\r\n\r\n            }\r\n            \r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warp": `TGGDDetail_warp__nyTqj`,
	"conent": `TGGDDetail_conent__LmEig`,
	"conentView": `TGGDDetail_conentView__u0JqR`,
	"leftView": `TGGDDetail_leftView__AgwhB`,
	"rightView": `TGGDDetail_rightView__uW-yq`,
	"text": `TGGDDetail_text__PyWzE`
};
export default ___CSS_LOADER_EXPORT___;
