import { Tabs } from 'antd-mobile';
import { throttle } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import cls from './StickyTabs.module.scss';
import { track } from '../../utils/sdk';

export interface StickyTabsItemProps {
  key: string;
  title: string;
  component: React.FC;
}
export interface StickyTabsProps {
  tabItems: StickyTabsItemProps[];
  tabHeight?: number;
}

const StickyTabs: React.FC<StickyTabsProps> = ({
  tabItems,
  tabHeight = 35,
}) => {
  const [activeKey, setActiveKey] = useState('');
  useEffect(() => {
    if (tabItems?.[0]) {
      setActiveKey(tabItems[0].key);
    }
  }, [tabItems]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    throttle(
      () => {
        if (!tabItems?.length) {
          return;
        }
        let currentKey = tabItems[0].key;
        const newArray = Array.from(tabItems).reverse();
        for (const item of newArray) {
          const element = document.getElementById(`anchor-${item.key}`);
          if (!element) continue;
          const rect = element.getBoundingClientRect();
          if (rect.top + 50 >= tabHeight) {
            currentKey = item.key;
          } else {
            break;
          }
        }
        setActiveKey(currentKey);
      },
      100,
      {
        leading: true,
        trailing: true,
      },
    ),
    [tabItems],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  const handleTabChange = useCallback(
    (key: string) => {
      const element = document.getElementById(`anchor-${key}`);
      const data=tabItems.filter((item)=>item.key===key)
      track('按钮点击',{
        btn_id:'tab',
        btn_title:data?.[0].title
      })
      if (!element) {
        return;
      }
      element.scrollIntoView();
      window.scrollTo({
        top: window.scrollY - tabHeight,
      });
    },
    [tabHeight,tabItems],
  );

  const eleRef = useRef<null | HTMLDivElement>(null);
  return (
    <div ref={eleRef} className={cls.wrap}>
      <Tabs
        activeLineMode="fixed"
        activeKey={activeKey}
        onChange={handleTabChange}
        style={{
          position: 'sticky',
          zIndex: 101,
          top: 0,
          '--fixed-active-line-width': '1.1875rem',
          '--content-padding': '0',
        }}
      >
        {tabItems.map((item) => (
          <Tabs.Tab title={item.title} key={item.key} />
        ))}
      </Tabs>
      {tabItems.map((item, index) => (
        <div id={`anchor-${item.key}`} key={item.key}>
          {item.component?.({ data: item, key: item.key, index })}
        </div>
      ))}
    </div>
  );
};

export default StickyTabs;
