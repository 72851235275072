// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YZXSelector_selector__vIrpo {
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  max-width: 100%;
  overflow-x: scroll;
}
.YZXSelector_selector__vIrpo::-webkit-scrollbar {
  display: none;
}
.YZXSelector_selector__vIrpo .YZXSelector_selectorItem__6SCPi {
  padding: 0.5rem 1rem;
  line-height: 1;
  font-size: 0.8125rem;
  border-radius: 6.25rem;
  background-color: #e6ebef;
  color: #555667;
  flex: 1 1;
  text-align: center;
  margin-right: 0.5rem;
  white-space: nowrap;
}
.YZXSelector_selector__vIrpo .YZXSelector_selectorItem__6SCPi:last-child {
  margin-right: 0;
}
.YZXSelector_selector__vIrpo .YZXSelector_selectorItem__6SCPi.YZXSelector_selectorItemActive__7vYUt {
  font-weight: bold;
  background-color: #4880cb;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/YZXSelector.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,oBAAA;EACA,cAAA;EACA,oBAAA;EACA,sBAAA;EACA,yBAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;AAEJ;AADI;EACE,eAAA;AAGN;AADI;EACE,iBAAA;EACA,yBAAA;EACA,cAAA;AAGN","sourcesContent":[".selector {\r\n  display: flex;\r\n  flex-direction: row;\r\n  margin-top: 0.7500rem;\r\n  max-width: 100%;\r\n  overflow-x: scroll;\r\n  &::-webkit-scrollbar {\r\n    display: none;\r\n  }\r\n  .selectorItem {\r\n    padding: 0.5000rem 1.0000rem;\r\n    line-height: 1;\r\n    font-size: 0.8125rem;\r\n    border-radius: 6.2500rem;\r\n    background-color: #e6ebef;\r\n    color: #555667;\r\n    flex: 1;\r\n    text-align: center;\r\n    margin-right: 0.5000rem;\r\n    white-space: nowrap;\r\n    &:last-child {\r\n      margin-right: 0;\r\n    }\r\n    &.selectorItemActive {\r\n      font-weight: bold;\r\n      background-color: #4880cb;\r\n      color: #ffffff;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selector": `YZXSelector_selector__vIrpo`,
	"selectorItem": `YZXSelector_selectorItem__6SCPi`,
	"selectorItemActive": `YZXSelector_selectorItemActive__7vYUt`
};
export default ___CSS_LOADER_EXPORT___;
