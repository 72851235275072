/**
 * 大涨股解读、投资日历、自选股/持仓股日报栏目入口
 */

import React, { useCallback} from 'react';
import { track } from '../../utils/sdk';
import useInitWxFunc from '../../hooks/useInitWxFunc';
import { useNavigate } from 'react-router-dom';
import { shareWxworkMessage } from '../../utils/wxUtil';
import { useGlobalState } from '../../models/global';
import { PUSH_NAME,} from '../../constants';
import { getPushUrl } from '../../utils/utils';
import { getFullPathFromMediaTypeIds } from '../../utils/tools';
import DzjdTzrlCcrbItem from './DzjdTzrlCcrbItem';

const DzjdTzrlCcrb = () => {
  const navigator = useNavigate();
  const { onShare } = useInitWxFunc();
  const [{wxUseData}]=useGlobalState()
  const handledzjdClick = useCallback(
    () => {
        track('按钮点击',{
          btn_title:`大涨解读`
        })
        navigator('/cjzb');
      },
    [navigator],
  );
  const handletzrlClick = useCallback(
    () => {
        track('按钮点击',{
          btn_title:`投资日历`
        })
        navigator('/wjkp');
      },
    [navigator],
  );
  const handleccrbClick = useCallback(
    () => {
        track('按钮点击',{
          btn_title:`持仓日报`
        })
        navigator('/mrdc');
      },
    [navigator],
  );
  const handleDzjdTzrlCcrbShareClick = useCallback(
    (func: string) => {
      const title=`大涨解读`
      track('按钮点击',{
        page_id:'leaf1002',
        btn_title:`大涨解读转发|${PUSH_NAME[func]}`
      })
      onShare(func, () => {
        shareWxworkMessage(func, {
          title:title,
          link: getPushUrl(getFullPathFromMediaTypeIds('1', {
            id: 2024100910550024510081,
          }),wxUseData),
        });
      });
    },  
    [onShare,wxUseData],
  );

  return (
    <DzjdTzrlCcrbItem
      onClick1={handledzjdClick}
      onClick2={handletzrlClick}
      onClick3={handleccrbClick}
      onbutClick={()=>{
        track('按钮点击',{
          btn_title:`分享`
        })
      }}
      onShareClick={handleDzjdTzrlCcrbShareClick}
    />
  );
};


export default DzjdTzrlCcrb;
