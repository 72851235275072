/**
 *  子栏目
 */

import React, { useCallback } from 'react';
import CJZBNewsCard from '../components/CJZBNewsCard';
import cls from './SubColumns.module.scss'
import { useQueryGetMoningFlight } from '../../services/api';
import { URL_NAMES } from '../../constants';
import { useNavigate } from 'react-router-dom';
export interface Props {
}
const Getimg: { [key: number]: string } = {
  0: 'zlm-hyts.png',
  1: 'zlm-cl.png',
  2: 'zlm-sd.png',

}
const SubColumns: React.FC<Props> = () => {
  const navigator = useNavigate();
  const {data}=useQueryGetMoningFlight()
  const onCardClick = useCallback(
    (e: any) => {
      if (e && e.id) {
        const url = URL_NAMES.投资早班机子栏目详情页 + '/' + e.id;
        navigator(url);
      }
    },
    [navigator],
  );
  return (
    <>
      {data&&data.length>0?data.map((item,index)=>(
         <div className={cls.wrap} key={index}>
         <div className={cls.titleView}  >
           <img src={require(`../../images/${Getimg[1]}`)} className={cls.img} alt="" />
           <div className={cls.title}>{item.columnName}</div>
         </div>
         <CJZBNewsCard
           showDetailBtn={item.content?true:false}
           title={item.title}
           description={item.shortCountent}
           onCardClick={() => {onCardClick(item)}}
         />
       </div>
      )):null
    }
     

    </>

  );
};

export default SubColumns;
