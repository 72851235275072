import React, { useCallback, useMemo, useRef, useState } from 'react';

import cls from './CJZBVideo.module.scss';
import { throttle } from 'lodash';
import img from '../../images/cjzb-tyxan.png';
import { stopPropagation } from '../../utils/dom';

export interface CJZBVideoProps {
  style?: React.CSSProperties;
  vidUrl?: string;
}
const getTime = (time: number) => {
  if (Number(time)) {
    let m: number | string = parseInt(`${(time / 60) % 60}`);
    m = m < 10 ? '0' + m : m;
    let s: number | string = parseInt(`${time % 60}`);
    s = s < 10 ? '0' + s : s;
    // 作为返回值返回
    return `${m}:${s}`;
  } else {
    return '00:00';
  }
};
const CJZBVideo: React.FC<CJZBVideoProps> = ({ style, vidUrl = '' }) => {
  const audioEle = useRef<HTMLAudioElement | null>(null);
  const [isPlay, setIsPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState('');
  const [duration, setDuration] = useState('');
  const [left, setLeft] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  // 获取总时长
  const onLoadedMetadata = useCallback((e: any) => {
    setDuration(getTime(e.target.duration));
    setIsLoaded(true);
    setCurrentTime('00:00');
  }, []);
  // 获取播放时间
  const onTimeUpdate = useMemo(
    () =>
      throttle(
        (e: any) => {
          setCurrentTime(getTime(e.target.currentTime));
          if (
            Number(
              ((e.target.currentTime / e.target.duration) * 100).toFixed(1),
            ) > 96
          ) {
            setLeft(96);
          } else {
            setLeft(
              Number(
                ((e.target.currentTime / e.target.duration) * 100).toFixed(1),
              ),
            );
          }
        },
        1000,
        { trailing: false },
      ),
    [],
  );

  const handleTimeLineClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // @ts-ignore
      const { offsetWidth } = e.target;
      const { offsetX } = e.nativeEvent;
      if (audioEle.current) {
        const duration = audioEle.current?.duration;
        let currentTime = Math.round(((offsetX - 11) / offsetWidth) * duration);
        if (currentTime < 0) {
          currentTime = 0;
        }
        audioEle.current.currentTime = currentTime;
        setCurrentTime(getTime(currentTime));
        const position = Number(((currentTime / duration) * 100).toFixed(1));
        if (position > 96) {
          setLeft(96);
        } else {
          setLeft(position);
        }
      }
    },
    [audioEle],
  );
  return (
    <div className={cls.wrap}>
      <div className={cls.content}>
        {vidUrl && (
          <audio
            ref={audioEle}
            src={vidUrl}
            className={cls.video}
            onTimeUpdate={(e) => onTimeUpdate(e)}
            onLoadedMetadata={onLoadedMetadata}
          />
        )}

        <div className={cls.timeLine}>
          <div className={cls.line} onClick={handleTimeLineClick}>
            <div className={cls.progress} style={{ left: `${left}%` }}>
              <img
                src={img}
                onClick={stopPropagation}
                alt=""
                style={{ width: '1.3750rem', height: '1.3750rem' }}
              />
            </div>
          </div>
          <div className={cls.timeWrap}>
            <div className={cls.startTime}>{currentTime}</div>
            <div className={cls.endTime}>{duration}</div>
          </div>
        </div>
        <div
          onClick={() => {
            !isPlay ? audioEle.current?.play() : audioEle.current?.pause();
            setIsPlay(isLoaded ? !isPlay : false);
          }}
          className={`${cls.action} ${
            !isPlay ? cls.actionStart : cls.actionStop
          }`}
        ></div>
      </div>
    </div>
  );
};

export default CJZBVideo;
