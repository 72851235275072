import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  ACTION_TYPES,
  Context as GlobalContext,
  useContextValueGlobalState,
} from './models/global';
import Main from './pages/Main';
import Loading from './components/Loading';
// import WJKP from './pages/WJKP';
import JDFP from './pages/JDFP';
import MRDC from './pages/MRDC';
import ZHKX from './pages/ZHKX';
import TCJJ from './pages/TCJJ';
import JCHD from './pages/JCHD';
import ZHDJ from './pages/ZHDJ';
import YZB from './pages/YZB';
import YZX from './pages/YZX';
import YTJ from './pages/YTJ';
import JHY from './pages/JHY';
import TCJJDetail from './pages/TCJJDetail';
import CJZB from './pages/CJZB';
import CJZBDetail from './pages/CJZBDetail';
import CJZBZLMDetail from './pages/CJZBZLMDetail';
import MainSearchDetail from './pages/MainSearchDetail';
import { URL_NAMES } from './constants';
import YZXDetail from './pages/YZXDetail';
import SearchPageLayout from './pages/layout/SearchPageLayout';
import ZHDJDetail from './pages/ZHDJDetail';
import ZXZT from './pages/ZXZT';
import XWLB from './pages/XFLB';
import MRSP from './pages/MRSP';
import PHGG from './pages/PHGG';
import TGGD from './pages/TGGD';
import TGGDDetail from './pages/TGGDDetail';
import { useWxUrlParam } from './hooks/useWxCode';
// import JXZX from './pages/JXZX';

const WJKP = lazy(() => import('./pages/WJKP'));

async function appLoad(dispatch: React.Dispatch<any>) {
  dispatch({
    type: ACTION_TYPES.APP_LOADED,
  });
}
const App = () => {
  const contextValue = useContextValueGlobalState();
  const {pushData,useData}=useWxUrlParam()
  useEffect(()=>{
    if(pushData) {
      contextValue.dispatch({
        type: ACTION_TYPES.WX_PUSH_DATA,
        payload:pushData
      });
    }
    if(useData) {
      contextValue.dispatch({
        type: ACTION_TYPES.WX_USE_DATA,
        payload:useData
      });
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[useData,pushData])
  useEffect(() => {
    appLoad(contextValue.dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <GlobalContext.Provider value={contextValue}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Suspense fallback={<Loading />}>
          {contextValue.state.isAppLoaded ? (
            <Routes>
              <Route path={URL_NAMES.首页搜索} element={<MainSearchDetail />} />
              <Route path={URL_NAMES.投资早班机 + '/:id?'} element={<CJZB />} />
              <Route
                path={URL_NAMES.投资早班机详情页 + '/:id'}
                element={<CJZBDetail />}
              />
              <Route
                path={URL_NAMES.投资早班机子栏目详情页 + '/:id'}
                element={<CJZBZLMDetail />}
              />
              <Route path={URL_NAMES.午间快评 + '/:id?'} element={<WJKP />} />
              <Route path={URL_NAMES.明日洞察 + '/:id?'} element={<MRDC />} />
              <Route path={URL_NAMES.新闻联播 + '/:id?'} element={<XWLB />} />
              <Route path={URL_NAMES.每日收评 + '/:id?'} element={<MRSP />} />
              <Route path={URL_NAMES.盘后公告 + '/:id?'} element={<PHGG />} />
              <Route path={URL_NAMES.中航快讯 + '/:id?'} element={<ZHKX />} />
              <Route
                path={URL_NAMES.题材掘金详情 + '/:id?'}
                element={<TCJJDetail />}
              />
              <Route
                path={URL_NAMES.投顾观点详情页 + '/:id?'}
                element={<TGGDDetail />}
              />
              <Route path={URL_NAMES.焦点复盘 + '/:id?'} element={<JDFP />} />
              <Route
                path={URL_NAMES.翼资讯详情 + '/:id'}
                element={<YZXDetail />}
              />
              <Route
                path={URL_NAMES.中航独家详情 + '/:id'}
                element={<ZHDJDetail />}
              />
              <Route path={URL_NAMES.资讯专题 + '/:id'} element={<ZXZT />} />
              <Route path={URL_NAMES.聚合页 + '/:id?'} element={<JHY />} />
              <Route path={URL_NAMES.首页} element={<Main />} />
              <Route path="/" element={<SearchPageLayout />}>
                <Route path={URL_NAMES.精彩活动} element={<JCHD />} />
                <Route path={URL_NAMES.题材掘金} element={<TCJJ />} />
                <Route path={URL_NAMES.投顾观点} element={<TGGD />} />
                <Route path={URL_NAMES.中航独家} element={<ZHDJ />} />
                <Route path={URL_NAMES.翼直播} element={<YZB />} />
                <Route path={URL_NAMES.翼资讯} element={<YZX />} />
                <Route path={URL_NAMES.翼投教} element={<YTJ />} />
              </Route>
              {/* 其他路由 */}
              {/* <Route path={'/jxzx'} element={<JXZX />} /> */}
            </Routes>
          ) : null}
        </Suspense>
      </BrowserRouter>
    </GlobalContext.Provider>
  );
};

export default App;
