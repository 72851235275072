// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZHDJDetail_wrap__q3crC {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.ZHDJDetail_wrap__q3crC .ZHDJDetail_content__Nh83o {
  flex: 1 1;
  height: 6.25rem;
}

.ZHDJDetail_react-pdf__Page__canvas__3-t0X {
  margin: 0 0 0 -1.875rem;
  width: 25rem !important;
  height: 25rem !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/ZHDJDetail.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;AACF;AAAE;EACE,SAAA;EACA,eAAA;AAEJ;;AACA;EACE,uBAAA;EAEA,uBAAA;EAEA,wBAAA;AAAF","sourcesContent":[".wrap {\r\n  height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  .content {\r\n    flex: 1;\r\n    height: 6.2500rem;\r\n  }\r\n}\r\n.react-pdf__Page__canvas {\r\n  margin: 0 0 0 -1.8750rem;\r\n\r\n  width: 25.0000rem !important;\r\n\r\n  height: 25.0000rem !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ZHDJDetail_wrap__q3crC`,
	"content": `ZHDJDetail_content__Nh83o`,
	"react-pdf__Page__canvas": `ZHDJDetail_react-pdf__Page__canvas__3-t0X`
};
export default ___CSS_LOADER_EXPORT___;
