import React, { useMemo } from 'react';
import cls from './FullImg.module.scss';
export interface FullImgProps {
  img?: string;
  imgHeight?: number;
  showInfo?: boolean;
  title?: string;
  extra?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const FullImg: React.FC<FullImgProps> = ({
  onClick,
  img,
  showInfo,
  title,
  extra,
  imgHeight = 105,
  className,
  style,
}) => {
  const styleMemo = useMemo(() => {
    const res: React.CSSProperties = {};
    if (showInfo) {
      res.background = '#ffffff';
    }
    return {
      ...res,
      ...style,
    };
  }, [showInfo, style]);
  return (
    <div
      onClick={onClick}
      className={`${cls.wrap}${className ?? ''}`}
      style={styleMemo}
    >
      <div
        className={cls.img}
        style={{ height: imgHeight + 'px', backgroundImage: `url('${img}')` }}
      />
      {showInfo && (
        <div className={cls.info}>
          <div className={cls.title}>{title}</div>
          <div className={cls.extra}>{extra}</div>
        </div>
      )}
    </div>
  );
};

export default FullImg;
