// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_wrap__iTz0i {
  display: flex;
  flex-direction: column;
}
.Main_wrap__iTz0i .Main_wrapContent__7wB-C {
  padding: 0.625rem 0.9375rem;
  background: #f2f3f7;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,2BAAA;EACA,mBAAA;AAEJ","sourcesContent":[".wrap {\r\n  display: flex;\r\n  flex-direction: column;\r\n  .wrapContent {\r\n    padding: 0.6250rem 0.9375rem;\r\n    background: #f2f3f7;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Main_wrap__iTz0i`,
	"wrapContent": `Main_wrapContent__7wB-C`
};
export default ___CSS_LOADER_EXPORT___;
