// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CJZBCard_wrap__T7pL0 {
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  border: 0.0625rem solid #ffffff;
  padding: 1.25rem 0.9375rem;
}
.CJZBCard_wrap__T7pL0 .CJZBCard_titleView__mRHKE {
  display: flex;
}
.CJZBCard_wrap__T7pL0 .CJZBCard_titleView__mRHKE .CJZBCard_img__5-5eM {
  width: auto;
  height: 1.3125rem;
}
.CJZBCard_wrap__T7pL0 .CJZBCard_titleView__mRHKE .CJZBCard_text__fTQeO {
  height: 1.1875rem;
  font-size: 1.1875rem;
  font-weight: bold;
  color: #252f42;
  line-height: 1.1875rem;
}
.CJZBCard_wrap__T7pL0 .CJZBCard_sbTitle__9ANc7 {
  font-size: 0.875rem;
  color: #949ab2;
  line-height: 0.875rem;
  padding: 0.9375rem 0 1.25rem;
}
.CJZBCard_wrap__T7pL0 .CJZBCard_content__vWu4F {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.625rem;
  padding: 0.9375rem 0.625rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/CJZBCard.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,oBAAA;EACA,+BAAA;EACA,0BAAA;AACF;AAAE;EACE,aAAA;AAEJ;AADI;EACE,WAAA;EACA,iBAAA;AAGN;AAAI;EACE,iBAAA;EACA,oBAAA;EACA,iBAAA;EACA,cAAA;EACA,sBAAA;AAEN;AACE;EACE,mBAAA;EACA,cAAA;EACA,qBAAA;EACA,4BAAA;AACJ;AACE;EACE,oCAAA;EACA,uBAAA;EACA,2BAAA;AACJ","sourcesContent":[".wrap {\r\n  border-radius: 0.6250rem;\r\n  margin-top: 0.6250rem;\r\n  border: 0.0625rem solid #ffffff;\r\n  padding: 1.2500rem 0.9375rem;\r\n  .titleView {\r\n    display: flex;\r\n    .img {\r\n      width: auto;\r\n      height: 1.3125rem;\r\n    }\r\n\r\n    .text {\r\n      height: 1.1875rem;\r\n      font-size: 1.1875rem;\r\n      font-weight: bold;\r\n      color: #252f42;\r\n      line-height: 1.1875rem;\r\n    }\r\n  }\r\n  .sbTitle {\r\n    font-size: 0.8750rem;\r\n    color: #949ab2;\r\n    line-height: 0.8750rem;\r\n    padding: 0.9375rem 0 1.2500rem;\r\n  }\r\n  .content {\r\n    background: rgba(255, 255, 255, 0.9);\r\n    border-radius: 0.6250rem;\r\n    padding: 0.9375rem 0.6250rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `CJZBCard_wrap__T7pL0`,
	"titleView": `CJZBCard_titleView__mRHKE`,
	"img": `CJZBCard_img__5-5eM`,
	"text": `CJZBCard_text__fTQeO`,
	"sbTitle": `CJZBCard_sbTitle__9ANc7`,
	"content": `CJZBCard_content__vWu4F`
};
export default ___CSS_LOADER_EXPORT___;
