// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tips_wrap__DwCRw {
  padding: 1.875rem 1.875rem 3.375rem 1.875rem;
  line-height: 1.5;
  opacity: 0.8;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Tips.module.scss"],"names":[],"mappings":"AAAA;EACE,4CAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;AACF","sourcesContent":[".wrap {\r\n  padding: 1.8750rem 1.8750rem 3.3750rem 1.8750rem;\r\n  line-height: 1.5;\r\n  opacity: 0.8;\r\n  font-size: 0.7500rem;\r\n  letter-spacing: 0.0625rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Tips_wrap__DwCRw`
};
export default ___CSS_LOADER_EXPORT___;
