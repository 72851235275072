// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZHKX_wrap__0jO5k {
  background-color: #d5e7ff;
  background-image: linear-gradient(180deg, #3975c6 0%, #d5e7ff 100%);
  background-size: 100% 37.5rem;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/ZHKX.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mEAAA;EACA,6BAAA;EACA,4BAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,gBAAA;AACF","sourcesContent":[".wrap {\r\n  background-color: #d5e7ff;\r\n  background-image: linear-gradient(180deg, #3975c6 0%, #d5e7ff 100%);\r\n  background-size: 100% 37.5000rem;\r\n  background-repeat: no-repeat;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: stretch;\r\n  overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ZHKX_wrap__0jO5k`
};
export default ___CSS_LOADER_EXPORT___;
