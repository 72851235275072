import sensors from 'sa-sdk-javascript';
import { zhLog } from './utils';
const { useSersors = true } = window.config;
sensors.init({
    server_url: 'https://samd.avicsec.com:8106/sa?project=default',
    is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    app_js_bridge: true,
    send_type: 'beacon',
    heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'not_collect',
        //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'not_collect'
    }
});
const PAGEINFO = 0b100;
const EVENTDATA: {
    [key: string]: {
        name: string,
        options: number
    }
} = {
    '页面浏览': {
        name: 'page',
        options: PAGEINFO
    },
    '按钮点击': {
        name: 'click',
        options: PAGEINFO
    },
}
const pageInfo = {
    page_id: 'leaf1001',
    page_title: '资讯中心',
}
interface IPageInfo {
    page_id?: string;
    page_title?: string;
}
export function track(eventName: '按钮点击', params: {
    btn_id?: string;
    btn_title?: string;
} & IPageInfo): any;
export function track(eventName: '页面浏览', params?: IPageInfo): any;
export function track(eventName: string, params: any) {
    if (!useSersors) {
        zhLog('track', '埋点已关闭');
        return;
    }
    const trackParams = Object.assign(
        {},
        EVENTDATA[eventName].options & PAGEINFO ? pageInfo : {},
        params
    );
    zhLog('track:', EVENTDATA[eventName].name, trackParams);
    sensors.track(EVENTDATA[eventName].name, trackParams);
}