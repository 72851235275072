// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CJZBDetail_wrap__AOvCa {
  background: #f2f3f7;
  width: 100%;
  min-height: 100vh;
}
.CJZBDetail_wrap__AOvCa .CJZBDetail_pageCard__B6\\+ht {
  background: #ffffff;
  padding-bottom: 0.625rem;
}
.CJZBDetail_wrap__AOvCa .CJZBDetail_pageCard__B6\\+ht .CJZBDetail_title__P4M-S {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333333;
  line-height: 1.875rem;
  padding: 1.25rem;
}
.CJZBDetail_wrap__AOvCa .CJZBDetail_pageCard__B6\\+ht .CJZBDetail_time__HAiEY {
  padding-left: 1.375rem;
  font-size: 0.875rem;
  color: #666666;
}
.CJZBDetail_wrap__AOvCa .CJZBDetail_pageCard__B6\\+ht .CJZBDetail_card__9dBF7 {
  padding: 1.125rem 0.9375rem 1.4375rem;
}
.CJZBDetail_wrap__AOvCa .CJZBDetail_pageCard__B6\\+ht .CJZBDetail_card__9dBF7 .CJZBDetail_subTitle__6Ts-Z {
  padding: 1rem 0.8125rem 1.125rem;
  color: #666666;
  line-height: 1.125rem;
  font-size: 0.875rem;
  background: #f6f6f6;
}
.CJZBDetail_wrap__AOvCa .CJZBDetail_pageCard__B6\\+ht .CJZBDetail_text__sPsDX {
  color: #333333;
  line-height: 1.5625rem;
  font-size: 0.9375rem;
  padding: 0 0.875rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/CJZBDetail.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,WAAA;EACA,iBAAA;AACF;AAAE;EACE,mBAAA;EACA,wBAAA;AAEJ;AADI;EACE,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,qBAAA;EACA,gBAAA;AAGN;AAAI;EACE,sBAAA;EACA,mBAAA;EACA,cAAA;AAEN;AAAI;EACE,qCAAA;AAEN;AADM;EACE,gCAAA;EACA,cAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;AAGR;AAAI;EACE,cAAA;EACA,sBAAA;EACA,oBAAA;EACA,mBAAA;AAEN","sourcesContent":[".wrap {\r\n  background: #f2f3f7;\r\n  width: 100%;\r\n  min-height: 100vh;\r\n  .pageCard {\r\n    background: #ffffff;\r\n    padding-bottom: 0.6250rem;\r\n    .title {\r\n      font-size: 1.2500rem;\r\n      font-weight: bold;\r\n      color: #333333;\r\n      line-height: 1.8750rem;\r\n      padding: 1.2500rem;\r\n    }\r\n\r\n    .time {\r\n      padding-left: 1.3750rem;\r\n      font-size: 0.8750rem;\r\n      color: #666666;\r\n    }\r\n    .card {\r\n      padding: 1.1250rem 0.9375rem 1.4375rem;\r\n      .subTitle {\r\n        padding: 1.0000rem 0.8125rem 1.1250rem;\r\n        color: #666666;\r\n        line-height: 1.1250rem;\r\n        font-size: 0.8750rem;\r\n        background: #f6f6f6;\r\n      }\r\n    }\r\n    .text {\r\n      color: #333333;\r\n      line-height: 1.5625rem;\r\n      font-size: 0.9375rem;\r\n      padding: 0 0.8750rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `CJZBDetail_wrap__AOvCa`,
	"pageCard": `CJZBDetail_pageCard__B6+ht`,
	"title": `CJZBDetail_title__P4M-S`,
	"time": `CJZBDetail_time__HAiEY`,
	"card": `CJZBDetail_card__9dBF7`,
	"subTitle": `CJZBDetail_subTitle__6Ts-Z`,
	"text": `CJZBDetail_text__sPsDX`
};
export default ___CSS_LOADER_EXPORT___;
