import axios from 'axios';
import { fmtRoute2FullPath } from './tools';

// 使用axios获取图片文件流

export const blobToBase64 = (blob: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e?.target?.result);
    };
    // readAsDataURL
    fileReader.readAsDataURL(blob);
    fileReader.onerror = () => {
      reject(new Error('blobToBase64 error'));
    };
  });
};

export async function urlToBase64(url: string) {
  return axios({ method: 'get', url: url, responseType: 'blob' })
    .then((res: any) => {
      let blob = new Blob([res.data], { type: 'application/pdf' });
      return blob;
    })
    .catch((error: any) => {
      console.error('Error fetching image:', error);
    });
}

// 早报代表1，午报2，焦点复盘3,主题前瞻4,5精彩活动，6题材掘金，8中航独家，10翼资讯，11翼投教—图片
export const mediaTypeToImg: { [key: string]: string } = {
  '1': '/shareImg/zbj.jpg',
  '2': '/shareImg/wjkp.jpg',
  '3': '/shareImg/jdfp.jpg',
  '4': '/shareImg/mrdc.jpg',
  '5': '/shareImg/jchd.jpg',
  '6': '/shareImg/tcjj.jpg',
  '8': '/shareImg/zhdj.jpg',
  '10': '/shareImg/yzx.jpg',
  '11': '/shareImg/zhts.jpg',
};

export const numToweek: { [key: number]: string } = {
  1: '星期一',
  2: '星期二',
  3: '星期三',
  4: '星期四',
  5: '星期五',
  6: '星期六',
  7: '星期日',
};
export const getUrlParam = (url: string, paramName: string) => {
  if (url) {
    let url1 = new URL(url);
    return url1.searchParams.get(paramName) || '';
  }
  return '';
};

export const getPushUrl = (url?: string,data?:any) => {
  let link =url ||window.location.href
  let url1 = new URL(link);
  if(data){
    url1.searchParams.append('usedata',encodeURIComponent(JSON.stringify(data)));
    return `${url1}`
  }
  return link
 
};
export const getStringInsideFirstBracke=(str:string)=> {
  if(str){
    let match = str.split('【')[1]?.split('】')[0];
    return match?`【${match}】`:'';
  }
  return ''
}
export const envjudge=()=> {
  const isMobile = window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  ) // 是否手机端
  const isWx = /micromessenger/i.test(navigator.userAgent) // 是否微信
  const isComWx = /wxwork/i.test(navigator.userAgent) // 是否企业微信
  if (isComWx && isMobile) {
    //手机端企业微信
    return 'com-wx-mobile'
  } else if (isComWx && !isMobile) {
    //PC端企业微信
    return 'com-wx-pc'
  } else if (isWx && isMobile) {
    // 手机端微信
    return 'wx-mobile'
  } else if (isWx && !isMobile) {
    // PC端微信
    return 'wx-pc'
  } else {
    return 'other'
  }
}

export const getJhySlt=(id:string)=>{
  if(id){
    let num=`${Number(id)%13}`
    return fmtRoute2FullPath(`/shareImg/slt${num}.png`)
  }else{
    fmtRoute2FullPath(`/shareImg/slt1.png`)
  }

}
//zhLog
export const zhLog=(a?:any,b?:any,c?:any)=>{
  if(window.config.showLog){
    console.log(a,b,c)
  }

}