import React, { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import Card from '../components/Card';
import useTitle from '../hooks/useTitle';
import useInitWxFunc from '../hooks/useInitWxFunc';
import { shareWxworkMessage } from '../utils/wxUtil';
import { TggdImg, getFullPathFromMediaTypeIds } from '../utils/tools';
import useScrollToTop from '../hooks/useScrollToTop';
import PageSwiper from './containers/PageSwiper';
import TGGDList from './containers/TGGDList';
import { MEDIA_TYPE_NAMES } from '../constants';
import { useGlobalState } from '../models/global';
import { getPushUrl } from '../utils/utils';
/**
 * 投顾观点
 */
const PAGE_NAME = '投顾观点';
const TGGD = () => {
  useScrollToTop();
  useTitle(PAGE_NAME);
  const [{wxUseData}]=useGlobalState()
  const [{ searchValue }] = useOutletContext<[{ searchValue?: string }]>();
  const { onShare } = useInitWxFunc();
  const handleNewsShareClick = useCallback(
    (func: string, item: API.IInvestmentAdviser, index: number) => {
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: item.mediaName,
          link: getPushUrl(getFullPathFromMediaTypeIds(MEDIA_TYPE_NAMES.投顾观点,{
            id: item.id,
          }),wxUseData),
          desc: item.shortTitle,
          imgUrl: TggdImg,
        });
      });
    },
    [onShare,wxUseData],
  );

  return (
    <>
      <PageSwiper regionName={PAGE_NAME} style={{ paddingTop: '0.7500rem' }} />
      <Card
        styles={{
          paddingTop: 0,
          marginTop: '0.9375rem',
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <TGGDList
          mediaName={searchValue}
          count={20}
          isLoadMore
          onShare={handleNewsShareClick}
        />
      </Card>
    </>
  );
};

export default TGGD;
