import { useEffect } from 'react';

const useTitle = (title?: string, options?: { description?: string }) => {
  const { description } = options ?? {};
  window.document.title = title || '';
  useEffect(() => {
    if (!description) {
      return;
    }
    window.document
      .querySelector('meta[name="description"]')
      ?.setAttribute('content', description);
  }, [description]);
};

export default useTitle;
