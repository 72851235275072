// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YTJList_wrap__Xq\\+jX .adm-tabs-header {
  background-color: transparent !important;
  border-bottom-color: transparent;
  margin-left: -0.75rem;
}
.YTJList_wrap__Xq\\+jX .adm-tabs-tab {
  color: #e6e6e6;
  font-size: 0.9375rem;
}
.YTJList_wrap__Xq\\+jX .adm-tabs-tab-active {
  font-weight: bold;
  color: #ffffff;
}
.YTJList_wrap__Xq\\+jX .adm-tabs-tab-line {
  background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/pages/containers/YTJList.module.scss"],"names":[],"mappings":"AAEI;EACE,wCAAA;EACA,gCAAA;EACA,qBAAA;AADN;AAGI;EACE,cAAA;EACA,oBAAA;AADN;AAGI;EACE,iBAAA;EACA,cAAA;AADN;AAGI;EACE,yBAAA;AADN","sourcesContent":[".wrap {\r\n  :global {\r\n    .adm-tabs-header {\r\n      background-color: transparent !important;\r\n      border-bottom-color: transparent;\r\n      margin-left: -0.7500rem;\r\n    }\r\n    .adm-tabs-tab {\r\n      color: #e6e6e6;\r\n      font-size: 0.9375rem;\r\n    }\r\n    .adm-tabs-tab-active {\r\n      font-weight: bold;\r\n      color: #ffffff;\r\n    }\r\n    .adm-tabs-tab-line {\r\n      background-color: #ffffff;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `YTJList_wrap__Xq+jX`
};
export default ___CSS_LOADER_EXPORT___;
