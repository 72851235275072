import React, { useCallback, useState } from 'react';
import { MEDIA_TYPE_NAMES, REGION_NAMES, URL_NAMES } from '../../constants';
import useQueryNewsInfinate from '../../hooks/useQueryNewsInfinate';
import NewsFlash from '../components/NewsFlash';
import ImgShare from '../components/ImgShare';
import { useNavigate } from 'react-router-dom';
import { track } from '../../utils/sdk';

const YZXNewsFlash: React.FC<{
  regionName: keyof typeof REGION_NAMES;
}> = ({
  regionName
}) => {
  const navigator = useNavigate();
  const { data: dbData } = useQueryNewsInfinate({
    mediaTypeId: MEDIA_TYPE_NAMES.电报,
    count: 3,
    revalidateIfStale: true,
  });
  const [shareIndex, setShareIndex] = useState<number>(-1);
  const [shareCardVisible, setShareCardVisible] = useState(false);
  const handleShareClick = useCallback((index: number) => {
    if(regionName==='资讯中心'){
      track('按钮点击',{
        btn_title:'7*24小时快讯|分享'
      })
    }
    setShareIndex(index);
    setShareCardVisible(true);
    
  }, [regionName]);

  const hiddenShare = useCallback(() => {
    setShareCardVisible(false);
  }, []);
  const handleCardClick = useCallback(() => {
    if(regionName==='资讯中心'){
      track('按钮点击',{
        btn_title:'7*24小时快讯'
      })
    }
    navigator(URL_NAMES.中航快讯);

  }, [regionName,navigator]);

  return (
    <React.Fragment>
      <ImgShare
        onMaskClick={hiddenShare}
        onFail={hiddenShare}
        visible={shareCardVisible}
        data={shareIndex >= 0 ? dbData?.[shareIndex] : undefined}
      />
      {!!dbData?.length && (
        <NewsFlash dataList={dbData} onShare={handleShareClick} handleCardClick={handleCardClick}/>
      )}
    </React.Fragment>
  );
};

export default YZXNewsFlash;
