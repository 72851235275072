/** 所有的常量放这里 */

/**URL跳转类型：key=name */
export const URL_TYPES = {
  不跳转: '0',
  内部跳转: '1',
  外部跳转: '2',
};

export const PLATFORM_TYPES = {
  微信: 'wx',
  企业微信: 'qywx',
  浏览器: 'browser',
  PC企业微信: 'pcqywx',
};

export const WX_FUNC_NAMES = {
  // 自定义分享好友内容: 'updateAppMessageShareData',  // 微信sdk 1.6.0
  // 自定义分享朋友圈内容: 'updateTimelineShareData',  // 微信sdk 1.6.0
  转发好友: 'onMenuShareAppMessage', // 微信sdk 1.2.0
  转发微信好友: 'onMenuShareWechat', // 微信sdk 1.2.0
  转发朋友圈: 'onMenuShareTimeline', // 微信sdk 1.2.0
  隐藏左上角菜单: 'hideOptionMenu',
  显示左上角菜单: 'showOptionMenu',
};
export const WX_AGENT_FUNC_NAMES = {
  转发到企微好友: 'shareAppMessage',
  群发客户: 'shareToExternalContact',
  转发到客户朋友圈: 'shareToExternalMoments',
  转发到微信好友: 'shareWechatMessage',
};
export const PUSH_NAME : { [key: string]: string } = {
  'shareAppMessage': '转发',
  'shareToExternalContact': '群发',
  'shareToExternalMoments': '发朋友圈',
  'shareWechatMessage': '微信好友',
};
export const PUSH_NAME_BUT : { [key: string]: string } = {
  'shareAppMessage': '转发获客',
  'shareToExternalContact': '群发获客',
  'shareToExternalMoments': '发朋友圈',
  'shareWechatMessage': '分享微信',
};
export const WX_CONFIG_API_LIST = Object.values(WX_FUNC_NAMES);
export const WX_AGENT_CONFIG_API_LIST = Object.values(WX_AGENT_FUNC_NAMES);

export const MEDIA_TYPE_NAMES = {
  投资早班机: '1',
  午间快评: '2',
  焦点复盘: '3',
  明日洞察: '4',
  精彩活动: '5',
  题材掘金: '6',
  中航独家: '8',
  翼直播: '9',
  电报: '10',
  翼投教图文: '11',
  翼投教视频: '12',
  翼资讯科创: '13',
  翼资讯宏观: '14',
  翼资讯股市: '15',
  翼资讯公司: '16',
  翼资讯地产: '17',
  翼资讯金融: '18',
  翼资讯基金: '19',
  翼资讯环球: '20',
  翼资讯要闻: '21',
  隔夜环球市场: '22',
  每日投资预警: '23',
  置顶标签资讯: '25',
  每日收评: "26",
  新闻联播: "27",
  盘后公告: "28",
  投顾观点: "29",


};

export const MEDIA_TYPE_ID_CONNECTOR = '@';

export const YZX_KEY_STR = MEDIA_TYPE_NAMES.翼资讯要闻;

export const URL_NAMES = {
  首页: '/',
  首页搜索: '/mainSearchDetail',
  投资早班机: '/cjzb',
  午间快评: '/wjkp',
  明日洞察: '/mrdc',
  中航快讯: '/zhkx',
  精彩活动: '/jchd',
  题材掘金: '/tcjj',
  焦点复盘: '/jdfp',
  中航独家: '/zhdj',
  翼直播: '/yzb',
  翼资讯: '/yzx',
  翼投教: '/ytj',
  题材掘金详情: '/tcjjDetail',
  翼资讯详情: '/yzxDetail',
  中航独家详情: '/zhdjDetail',
  投资早班机详情页: '/cjzbDetail',
  资讯专题: '/zxzt',
  聚合页: '/jhy',
  投资早班机子栏目详情页: '/cjzbzlmdDetail',
  每日收评: "/mrsp",
  新闻联播: "/xwlb",
  盘后公告: "/phgg",
  投顾观点: '/tggd',
  投顾观点详情页: '/tggdDetail',

};


// 位置信息
export const REGION_NAMES = {
  资讯中心: 1, // 首页
  题材掘金: 2,
  中航独家: 3,
  翼资讯: 4,
  翼直播: 5,
  翼投教: 6,
  翼投教中航视频: 7,
  投顾观点: 8,
  投资早班机: 9,
  午间快评: 10,
  焦点复盘: 11,
  明日洞察: 12,
  每日收评: 13,
  新闻联播: 14,
  盘后公告: 15,
  题材掘金详情: 16,
  翼资讯详情: 17,
  投顾观点详情: 18,

};

// 每日必做，摘要内容
export const MRBZ_DESCRIPTIONS = {
  投资早班机: '每日3分钟，市场要闻早知道',
  午间快评: '早盘盘面回顾，午间涨停解析。',
  焦点复盘: '复盘焦点板块走势与原因，深度分析市场。',
  明日洞察: '晚间出击，最强题材复盘和最全题材前瞻。',
  每日收评: '全面总结一天行情，热点事件回顾。',
  盘后公告: '盘后公告集锦，市场行情不再错过。',
  新闻联播: '提前发现政策红利，埋伏市场投资机会。',
};
export const MEDIA_TYPE_IMG: { [key: string]: string } = {
  '1': 'zbj.jpg',
  '2': 'wjkp.jpg',
  '3': 'jdfp.jpg',
  '4': 'mrdc.jpg',
  '5': 'jchd.jpg',
  '6': 'tcjj.jpg',
  '8': 'zhdj.jpg',
  '9': 'yzb.jpg',
  '10': 'yzx.jpg',
  '11': 'zhts.jpg',
  '12': 'zhsp.jpg',
  '13': 'yzx.jpg',
  '14': 'yzx.jpg',
  '15': 'yzx.jpg',
  '16': 'yzx.jpg',
  '17': 'yzx.jpg',
  '18': 'yzx.jpg',
  '19': 'yzx.jpg',
  '20': 'yzx.jpg',
  '21': 'zbj.jpg',
  '22': 'zbj.jpg',
  '23': 'zbj.jpg',
  '25': 'zbj.jpg',
};
export const APP_ID = window.config?.appId || 'ww520baf6c9ad5ce79'; // 企业微信AppId
export const AGENT_ID = window.config?.agentId || '1000067'; // 企业微信AgentId
export const GRWX_APP_ID = window.config?.grwxAppId || 'wxe82ee756712dcefc'; // 个人微信AppId
