/**
 * 
 */
import React from 'react';
import cls from './DetailTitleCard.module.scss';
import fdhurl from '../../images/wjkp-fdh.png';
import dhurl from '../../images/wjkp-dh.png';

export interface Props {
    children?: React.ReactNode;
    title?:string;
    shortTitle?:string;
}
const DetailTitleCard: React.FC<Props> = ({
    title,
    shortTitle,
    children
}) => {
    return (
        <div className={cls.titleView}>
            <div className={cls.fdhView}>
                <img src={fdhurl} className={cls.fdhimg} alt="" />
            </div>
            <div className={cls.title}>{title||'--'}</div>
            <div className={cls.dhView}>
                <img src={dhurl} className={cls.dhimg} alt="" />
            </div>
            {children?children:<div className={cls.abstract}>{shortTitle||''}</div>}
        </div>
    );
};

export default DetailTitleCard;
