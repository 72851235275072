import React from 'react';
import cls from './EwmModal.module.scss';
import gb from '../../images/gb-copy.png';
export interface IProps {
  onCancel: () => void;
  visible: boolean;
  url:string;
  txurl?:string


}
const EwmModal: React.FC<IProps> = ({
  visible,
  url,
  onCancel,
  txurl
}) => {
  if (!visible) return null;
  return (
    <div className={cls.wrap}>
      <div className={cls.box}>
          <div className={cls.imgView}>
           <img src={txurl} className={cls.img} alt='' />
          </div>
          <div className={cls.ewmView}>
           <img src={url} className={cls.ewm} alt='' />
          </div>
      </div>
      <div className={cls.foot} onClick={onCancel}>
        <img src={gb} className={cls.gb} alt='' />
     </div>
    </div>
  );
};

export default EwmModal;
