import moment from 'moment';
import {
  MEDIA_TYPE_ID_CONNECTOR,
  MEDIA_TYPE_NAMES,
  PLATFORM_TYPES,
  URL_NAMES,
} from '../constants';
import { numToweek } from './utils';

export function getEnv() {
  let ua = window.navigator.userAgent.toLowerCase();

  //判断ua和微信浏览器的标识头是否匹配
  const isWx = ua.indexOf('micromessenger') > -1;
  const isWxWork = ua.indexOf('wxwork') > -1;
  const isMobile = ua.indexOf('android') > -1 || ua.indexOf('iphone') > -1;
  switch (true) {
    case isWx && isWxWork && isMobile:
      return PLATFORM_TYPES.企业微信;
    case isWx && isWxWork:
      return PLATFORM_TYPES.PC企业微信;
    case isWx:
      return PLATFORM_TYPES.微信;
    default:
      return PLATFORM_TYPES.浏览器;
  }
}

export const filterEmptyKeys = (
  params: { [key: string]: any },
  /**是否需要加密 */
  isEncrypt: boolean = false,
) => {
  return Object.keys(params).reduce<{ [key: string]: string }>(
    (curr, prev: string) => {
      let param = params[prev as keyof typeof params];
      if (param === null || param === undefined || param === '') {
        return curr;
      }
      curr[prev] = param;
      return curr;
    },
    {},
  );
};

export const env = getEnv();

/**
 * 当日时间转换成xxx小时前，非当日用日期表示
 * @time YYYY-MM-DD HH:mm:ss
 */
export function fmtCurrentDateTime2Str(
  time?: string,
  options?: { format?: string; defaultValue?: string },
  type: boolean | undefined = true,
) {
  const { format = 'MM-DD', defaultValue } = options || {};
  if (time === undefined || time === null || !time) {
    return defaultValue;
  }

  const timeFmt = moment(time, 'YYYY-MM-DD HH:mm:ss');
  const currentTime = moment();
  // 非当日，直接返回
  if (timeFmt.format('YYYYMMDD') !== currentTime.format('YYYYMMDD') || !type) {
    return format ? timeFmt.format(format) : time;
  }
  const diffMinuteTime = currentTime.diff(timeFmt, 'minutes');
  // 未来时间，直接显示
  if (diffMinuteTime < 0) {
    return format ? timeFmt.format(format) : time;
  }
  // 30分钟内（0~29，不包含30），显示分钟
  if (diffMinuteTime < 30) {
    return `刚刚`;
  }
  const remainder = diffMinuteTime % 60;
  const quotient = Math.floor(diffMinuteTime / 60);
  // 当日，显示X小时前
  return `${remainder < 30 ? quotient : quotient + 1}小时前`;
}

export function addParam2Search(
  url: string,
  options: { search?: string; param: { key: string; value: string } },
) {
  const { param, search = '' } = options;
  const searchParam = new URLSearchParams(search);
  if (param.value === undefined || param === null || param.value === '') {
    searchParam.delete('search');
  } else {
    searchParam.set(param.key, param.value);
  }
  const searchRes = searchParam.toString();
  return url + (searchRes ? '?' + searchRes : '');
}

export function fmtRoute2FullPath(url?: string) {
  if (!url) {
    return '';
  }
  if (url.startsWith('http')) {
    return url;
  }
  const origin = window.location.origin;
  const basePath = process.env.PUBLIC_URL;
  return origin + basePath + url;
}

/**
 * 通过新闻列表的MediaTypeId 返回不同url
 */
export function getFullPathFromMediaTypeIds(
  mediaTypeIds: string, // 多个mediaTypeId使用连接符链接
  options?: { url?: string; id?: number; date?: string },
): string | undefined {
  const type = mediaTypeIds.split(MEDIA_TYPE_ID_CONNECTOR)[0];
  const { date = '', id = '' } = options || {};
  switch (type) {
    case MEDIA_TYPE_NAMES.投资早班机:
      return fmtRoute2FullPath(URL_NAMES.投资早班机 + '/' + date);
    case MEDIA_TYPE_NAMES.午间快评:
      return fmtRoute2FullPath(URL_NAMES.午间快评 + '/' + id);
    case MEDIA_TYPE_NAMES.焦点复盘:
      return fmtRoute2FullPath(URL_NAMES.焦点复盘 + '/' + id);
    case MEDIA_TYPE_NAMES.明日洞察:
      return fmtRoute2FullPath(URL_NAMES.明日洞察 + '/' + id);
    case MEDIA_TYPE_NAMES.每日收评:
      return fmtRoute2FullPath(URL_NAMES.每日收评 + '/' + id);
    case MEDIA_TYPE_NAMES.新闻联播:
      return fmtRoute2FullPath(URL_NAMES.新闻联播 + '/' + id);
    case MEDIA_TYPE_NAMES.盘后公告:
      return fmtRoute2FullPath(URL_NAMES.盘后公告 + '/' + id);
    case MEDIA_TYPE_NAMES.精彩活动:
    case MEDIA_TYPE_NAMES.中航独家:
    case MEDIA_TYPE_NAMES.翼直播:
    case MEDIA_TYPE_NAMES.翼投教图文:
    case MEDIA_TYPE_NAMES.翼投教视频: {
      return options?.url;
    }
    case MEDIA_TYPE_NAMES.投顾观点:{
      return fmtRoute2FullPath(URL_NAMES.投顾观点详情页 + '/' + options?.id);
    }
    case MEDIA_TYPE_NAMES.题材掘金: {
      return fmtRoute2FullPath(URL_NAMES.题材掘金详情 + '/' + options?.id);
    }
    case MEDIA_TYPE_NAMES.电报:
      break;
    case MEDIA_TYPE_NAMES.翼资讯科创:
    case MEDIA_TYPE_NAMES.翼资讯宏观:
    case MEDIA_TYPE_NAMES.翼资讯股市:
    case MEDIA_TYPE_NAMES.翼资讯公司:
    case MEDIA_TYPE_NAMES.翼资讯地产:
    case MEDIA_TYPE_NAMES.翼资讯金融:
    case MEDIA_TYPE_NAMES.翼资讯基金:
    case MEDIA_TYPE_NAMES.翼资讯环球: {
      return fmtRoute2FullPath(URL_NAMES.翼资讯详情 + '/' + options?.id);
    }
    default:
      break;
  }
}

export function fmtTimeToweek(time?: string) {
  if (time === undefined || time === null || !time) {
    return '';
  }
  let dd = moment(time).isoWeekday();
  return numToweek[dd];
}

// 压缩函数，imgBase64为图片源文件，quality图片质量，imgType图片类型
export function canvas2DataUrl(canvas: any, quality?: number, type?: string) {
  return canvas.toDataURL(type || 'image/jpeg', quality || 0.8);
}
// 生成随机数，题材掘金、翼咨询显示13张默认图随机一张
export const randomImg = () => {
  let randomNum = Math.floor(Math.random() * (14 - 1) + 1);
  return fmtRoute2FullPath(`/shareImg/slt${randomNum}.png`);
};

// 获取当前日期
export function getCurrentTimeStr(fmt: string = 'YYYY-MM-DD') {
  const currentTime = moment();
  return currentTime.format(fmt);
}

export const TggdImg='https://zxxcx.avicsec.com/static/images/touguguandian.png'

/**
   * @description url转Base64
*/
export function urlToBase64(url:string) {
  return new Promise((resolve) => {
    
    let timeStamp = new Date().getTime();
    //通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，相比 createElement() 创建 				<img> 省去了 append()，也就避免了文档冗余和污染
    let Img = new Image();
    // 处理缓存,fix缓存bug,有缓存，浏览器会报错;
    Img.src = url + '?' + timeStamp;
    // 解决控制台跨域报错的问题
    Img.crossOrigin = 'Anonymous';
    //获取后缀
    let ext = Img.src.substring(Img.src.lastIndexOf('.') + 1).toLowerCase();
   
    Img.onload = function () {
      console.log(ext,'2222')
      //要先确保图片完整获取到，这是个异步事件
      var canvas = document.createElement('canvas'); //创建canvas元素
      //确保canvas的尺寸和图片一样
      canvas.width = Img.width;
      canvas.height = Img.height;
      //将图片绘制到canvas中
      canvas.getContext('2d')?.drawImage(Img, 0, 0, Img.width, Img.height);
     
      //转换图片为dataURL
      resolve(canvas.toDataURL(`image/${ext}`));
    };
    Img.onerror = function() {
      // 图片加载失败后的处理逻辑
      console.log('图片加载失败');
  };
  });
}

