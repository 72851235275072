/**
 * 聚合页列表
 */

import React from 'react';
import ItemCardHorizontal from '../../components/ItemCardHorizontal';
import { SendOutline } from 'antd-mobile-icons';
import Nodata from '../../components/Nodata';
import { fmtCurrentDateTime2Str, fmtRoute2FullPath } from '../../utils/tools';
import useJump from '../../hooks/useJump';
import { getJhySlt} from '../../utils/utils';

export interface JHYListProps {
    onShare?: (func: string, item: API.INews, index: number) => void;
    data: API.IMiddleLisItem[];
    loading: boolean;

}

const JHYList: React.FC<JHYListProps> = ({
    onShare, data, loading
}) => {
    const { jumpNewsDetailFromMediaType } = useJump();
    return (
        <React.Fragment>
            {data?.map((item, index) => (
                <ItemCardHorizontal
                    key={item.id}
                    shereid={item.id}
                    img={item.img || getJhySlt(`${item.id}`)}
                    title={item?.title}
                    description={item.shortTitle}
                    time={
                        <span>
                            {item?.updateTime
                                ? fmtCurrentDateTime2Str(item.updateTime)
                                : null}
                            &nbsp;&nbsp;
                            {item.hostMedia ? (
                                <>
                                    <SendOutline />
                                    &nbsp;{item.hostMedia}
                                </>
                            ) : null}
                        </span>
                    }
                    share
                    onItemClick={() => {
                        jumpNewsDetailFromMediaType(item.mediaType, {
                            url: item.url,
                            id: item.id,
                            type: Number(item.viewers),
                        });

                    }}
                    onShareClick={(func) => {
                        onShare?.(
                            func,
                            {
                                ...item,
                                img:fmtRoute2FullPath('/shareImg/yzx.jpg') ,
                            },
                            index,
                        );
                    }}
                />
            ))}
            {!loading && !data?.length && (
                <Nodata content={'暂无数据'} />
            )}

        </React.Fragment>
    );
};

export default JHYList;
