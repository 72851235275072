/**
 * 今日必做
 */

import DailyTaskItem from './DailyTaskItem';
import cls from './DailyTask.module.scss';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import ImgDailyTaskTitle from '../../images/DailyTaskTitle.png';
import { useQueryUpdateTimeInformation } from '../../services/api';
import { track } from '../../utils/sdk';

export interface DailyTaskDataItemProps {
  mediaTypeId: string;
  titleImg: string;
  description: string;
  background: string;
  moreColor: string;
  descriptionColor: string;
  shareBgColor: string;
  path?: string;
  time?: string; // hhmm
  isUpdate?: boolean;
  title: string;
  imgUrl: string;
  id?: string;
  isDisplay?:boolean;

}
export interface DailyTaskProps {
  data: DailyTaskDataItemProps[];
  onClick?: (item: DailyTaskDataItemProps, index: number) => void;
  onShareClick?: (
    func: string,
    item: DailyTaskDataItemProps,
    index: number,
  ) => void;
}
const DailyTask: React.FC<DailyTaskProps> = ({
  data,
  onClick,
  onShareClick,
}) => {
  const nearTimeIndex = useMemo(() => {
    const nearIndex = data.reduce((prev, curr, currentIndex) => {
      if (curr.isUpdate) {
        return currentIndex;
      } else {
        return prev;
      }
    }, 0);
    return nearIndex;
  }, [data]);

  const timelineRef = useRef<null | HTMLDivElement>(null);

  const handleClick = useCallback(
    (item: DailyTaskDataItemProps, index: number) => {
      if (onClick) {
        onClick(item, index);
      }
    },
    [onClick],
  );
  useEffect(() => {
    if (!timelineRef?.current || nearTimeIndex === undefined) {
      return;
    }

    const scrollLeft =
      // @ts-ignore
      timelineRef?.current.childNodes[nearTimeIndex]?.offsetLeft -
      timelineRef?.current.offsetLeft -
      12;
    timelineRef?.current.scrollTo({
      left: scrollLeft > 0 ? scrollLeft : 0,
    });
  }, [timelineRef, nearTimeIndex]);
  const { data: timeData } = useQueryUpdateTimeInformation();
  const timeDataMap = useMemo(() => {
    return (timeData || []).reduce<{
      [key: string]: API.IUpdateTimeInformation;
    }>((pre, signal) => {
      pre[`${signal.mediaTypeId}`] = signal;
      return pre;
    }, {});
  }, [timeData]);
  return (
    <div className={cls.wrap}>
      <img className={cls.title} src={ImgDailyTaskTitle} alt=""></img>
      <div className={cls.timeline} ref={timelineRef}>
        {data.map((item, index) => {
          item.id = timeDataMap[item.mediaTypeId]?.id || '';
          let time = timeDataMap[item.mediaTypeId]?.updateTime
            ? timeDataMap[item.mediaTypeId].updateTime
            : item.time;
          return (item.isDisplay?
            <DailyTaskItem
              onClick={() => {
                handleClick(item, index);
              }}
              onbutClick={()=>{
                track('按钮点击',{
                  btn_title:`今日必做|${item.title}|转发获客`
                })
              }}
              onShareClick={(func) => {
                onShareClick?.(func, item, index);
              }}
              key={index}
              {...item}
              canShare={item.isUpdate}
              time={time}
            />
          :null);
        })}
      </div>
    </div>
  );
};

export default DailyTask;
