// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZXZT_warp__jsPv4 {
  background: #f1f2f8;
  min-height: 100vh;
}
.ZXZT_warp__jsPv4 .ZXZT_conten__kkLVm {
  padding: 0 0.9375rem;
  margin-top: -4.6875rem;
}
.ZXZT_warp__jsPv4 .ZXZT_conten__kkLVm .ZXZT_abstractWrap__OzFG5 {
  width: 100%;
  background: linear-gradient(180deg, rgba(229, 236, 255, 0.58) 0%, rgb(255, 255, 255) 100%);
  border-radius: 0.625rem;
  border: 0.0625rem solid #ffffff;
  backdrop-filter: blur(0.375rem);
  padding: 1.25rem 0.9375rem;
  box-sizing: border-box;
  backdrop-filter: blur(1.625rem);
}
.ZXZT_warp__jsPv4 .ZXZT_conten__kkLVm .ZXZT_abstractWrap__OzFG5 .ZXZT_abstract__gy8d6 {
  color: #1368da;
  font-size: 0.875rem;
  line-height: 1.4375rem;
}
.ZXZT_warp__jsPv4 .ZXZT_conten__kkLVm .ZXZT_contentTitleWrap__lS66y {
  display: flex;
  padding: 1.25rem 0.9375rem 0rem;
}
.ZXZT_warp__jsPv4 .ZXZT_conten__kkLVm .ZXZT_contentTitleWrap__lS66y .ZXZT_contentTitleImg__a8Ujc {
  height: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/ZXZT.module.scss"],"names":[],"mappings":"AACA;EACE,mBAAA;EACA,iBAAA;AAAF;AACE;EACE,oBAAA;EACA,sBAAA;AACJ;AAAI;EACE,WAAA;EACA,0FAAA;EAKA,uBAAA;EACA,+BAAA;EACA,+BAAA;EACA,0BAAA;EACA,sBAAA;EACA,+BAAA;AAFN;AAGM;EACE,cAAA;EACA,mBAAA;EACA,sBAAA;AADR;AAII;EACE,aAAA;EACA,+BAAA;AAFN;AAGM;EACE,cAAA;AADR","sourcesContent":["@import '../variable.scss';\r\n.warp {\r\n  background: #f1f2f8;\r\n  min-height: 100vh;\r\n  .conten {\r\n    padding: 0 0.9375rem;\r\n    margin-top: -4.6875rem;\r\n    .abstractWrap {\r\n      width: 100%;\r\n      background: linear-gradient(\r\n        180deg,\r\n        rgba(229, 236, 255, 0.58) 0%,\r\n        rgba(255, 255, 255, 1) 100%\r\n      );\r\n      border-radius: 0.6250rem;\r\n      border: 0.0625rem solid #ffffff;\r\n      backdrop-filter: blur(0.3750rem);\r\n      padding: 1.2500rem 0.9375rem;\r\n      box-sizing: border-box;\r\n      backdrop-filter: blur(1.6250rem);\r\n      .abstract {\r\n        color: #1368da;\r\n        font-size: 0.8750rem;\r\n        line-height: 1.4375rem;\r\n      }\r\n    }\r\n    .contentTitleWrap {\r\n      display: flex;\r\n      padding: 1.2500rem 0.9375rem 0.0000rem;\r\n      .contentTitleImg {\r\n        height: 1.5000rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warp": `ZXZT_warp__jsPv4`,
	"conten": `ZXZT_conten__kkLVm`,
	"abstractWrap": `ZXZT_abstractWrap__OzFG5`,
	"abstract": `ZXZT_abstract__gy8d6`,
	"contentTitleWrap": `ZXZT_contentTitleWrap__lS66y`,
	"contentTitleImg": `ZXZT_contentTitleImg__a8Ujc`
};
export default ___CSS_LOADER_EXPORT___;
