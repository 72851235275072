import React, { useCallback, useEffect, useMemo } from 'react';
import cls from './YZXDetail.module.scss';
import Tips from '../components/Tips';
import { useParams } from 'react-router-dom';
import { queryGetUpdateHost, useQueryMediaById } from '../services/api';
import ShareBtnBottom from '../components/ShareBtnBottom';
import useInitWxFunc from '../hooks/useInitWxFunc';
import { shareWxworkMessage } from '../utils/wxUtil';
import useScrollToTop from '../hooks/useScrollToTop';
import fdhurl from '../images/wjkp-fdh.png';
import dhurl from '../images/wjkp-dh.png';
import url from '../images/wjkp-tb.png';
import nrxqurl from '../images/nrxq.png';
import BannerCard from './components/BannerCard';
import { PUSH_NAME_BUT, URL_NAMES } from '../constants';
import { fmtRoute2FullPath } from '../utils/tools';
import useTitle from '../hooks/useTitle';
import PageSwiper from './containers/PageSwiper';
import BusinessCard from './containers/BusinessCard';
import { getPushUrl } from '../utils/utils';
import { useGlobalState } from '../models/global';
import { track } from '../utils/sdk';
import XGKH from './XGKH';
import XGGG from './XGGG';

const PAGE_NAME = '翼资讯详情';
const YZXDetail = () => {
  useScrollToTop();
  const [{wxUseData}]=useGlobalState()
  const { id } = useParams();
  console.log(id);
  const { data } = useQueryMediaById({ id });

  useTitle(data?.title || '');
  const shareInfo = useMemo(() => {
    if (!data?.id) {
      return undefined;
    }
    return {
      title: data?.title,
      link: getPushUrl(window.location.href,wxUseData),
      desc: data?.shortTitle,
      imgUrl: fmtRoute2FullPath('/shareImg/yzx.jpg'),
      success: () => {
        queryGetUpdateHost(data.id + '');
      },
    };
  }, [data,wxUseData]);
  const { onShare } = useInitWxFunc({ shareInfo });
  useEffect(()=>{
    track('页面浏览',{
      page_id:'leaf1003',
      page_title:'翼资讯'
    })
  },[])
  const handleNewsShareClick = useCallback(
    (func: string) => {
      queryGetUpdateHost(`${data?.id}`);
      track('按钮点击',{
        page_id:'leaf1003',
        page_title:'翼资讯',
        btn_title:`${PUSH_NAME_BUT[func]}`
      })
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: data?.title,
          link: getPushUrl(window.location.href,wxUseData),
          desc: data?.shortTitle,
          imgUrl: fmtRoute2FullPath('/shareImg/yzx.jpg'),
        });
      });
    },
    [onShare, data,wxUseData],
  );
  return (
    <div className={cls.warp}>
       <BusinessCard />
      <BannerCard
        time={data?.updateTime || ''}
        bgColor={`rgba(124,30,30,0.45)`}
        pageTitle={URL_NAMES.翼资讯}
      />
      <div className={cls.conent}>
        <div className={cls.titleView}>
          <div className={cls.fdhView}>
            <img src={fdhurl} className={cls.fdhimg} alt="" />
          </div>
          <div className={cls.title}>{data?.title}</div>
          <div className={cls.dhView}>
            <img src={dhurl} className={cls.dhimg} alt="" />
          </div>
          <div className={cls.abstract}>{data?.shortTitle}</div>
        </div>
        <PageSwiper regionName={PAGE_NAME} style={{ paddingTop: '0.6250rem' }} />
        <XGKH data={data}/>
        <XGGG data={data}/>
        <div className={cls.contentWrap}>
          <div className={cls.contentWrap_title}>
            <img src={url} className={cls.tbimg} alt="" />
            <div className={cls.nrxqView}>
              <img src={nrxqurl} className={cls.nrxqimg} alt="" />
            </div>
          </div>
          <div
            className={cls.content + ' reach-text-content'}
            dangerouslySetInnerHTML={{ __html: data?.conten ?? '' }}
          ></div>
        </div>
      </div>
      <Tips textColor="#989898" style={{ padding: '2.1875rem 0.9375rem 3.7500rem' }} title={'以上内容来自财联社。'} />
      <ShareBtnBottom onIconClick={handleNewsShareClick} />
    </div>
  );
};

export default YZXDetail;
