import { CenterPopup, Popup } from 'antd-mobile';
import React, { useCallback, useRef, useState } from 'react';
import cls from './ImgShare.module.scss';
import html2canvas from 'html2canvas';
import Loading from '../../components/Loading';
import useInitWxFunc from '../../hooks/useInitWxFunc';
import { WX_AGENT_FUNC_NAMES } from '../../constants';
import { querySharePicture } from '../../services/api';
import { canvas2DataUrl, fmtTimeToweek } from '../../utils/tools';
import { useGlobalState } from '../../models/global';
import { getStringInsideFirstBracke } from '../../utils/utils';
export interface ImgShareProps {
  visible?: boolean;
  data?: API.INews;
  onMaskClick?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
}
const ImgShare: React.FC<ImgShareProps> = ({
  visible,
  data,
  onSuccess,
  onFail,
  onMaskClick,
}) => {
  const shareEleRef = useRef<null | HTMLDivElement>(null);
  const [url, setUrl] = useState('');
  const [shareUrl, setShareUrl] = useState('');
  const base64ImgtoFile = (dataurl: any, filename = 'file') => {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let suffix = mime.split('/')[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${suffix}`, {
      type: mime,
    });
  };

  const [{ wxPushData }] = useGlobalState();
  const handlePopupShow = useCallback(() => {
    if (!shareEleRef.current) {
      return;
    }
    html2canvas(shareEleRef.current, { useCORS: true, })
      .then(async function (canvas) {
        let compressImg = canvas2DataUrl(canvas, 0.5);
        querySharePicture(base64ImgtoFile(compressImg)).then((res) => {
          setUrl(compressImg);
          setShareUrl(res);
          onSuccess?.();
        });
      })
      .catch(() => {
        onFail?.();
      });
  }, [onFail, onSuccess]);

  const handlePopupHidden = useCallback(() => {
    setUrl('');
  }, []);
  const handleMaskClick = useCallback(() => {
    if (!url) {
      return;
    }
    onMaskClick?.();
  }, [url, onMaskClick]);
  const { onShare } = useInitWxFunc();


  const handleShare = useCallback(() => {
    onShare(WX_AGENT_FUNC_NAMES.转发到客户朋友圈, () => {
      window.wx.invoke(
        WX_AGENT_FUNC_NAMES.转发到客户朋友圈,
        {
          attachments: [
            {
              msgtype: 'image', // 消息类型，必填
              image: {
                mediaId: 'xxx',
                imgUrl: shareUrl, // 图片的imgUrl,跟图片mediaid填其中一个即可
              },
            },
          ],
        },
        function (res: any) { },
      );
    });
  }, [onShare, shareUrl]);

  return (
    <React.Fragment>
      <CenterPopup
        visible={visible}
        onMaskClick={handleMaskClick}
        style={{
          '--border-radius': '0',
          '--min-width': '18.7500rem',
          '--background-color': 'transtparent',
        }}
        className={cls.popupWrap}
        afterShow={handlePopupShow}
        afterClose={handlePopupHidden}
      >
        <div ref={shareEleRef} className={`${cls.shareCardEle}`}>
          <div className={cls.shareCardEleTitle}>
            <img src={require('../../images/pic-zhkx.png')} className={cls.line} alt='' />
          </div>
          <div className={cls.shareContentWrap}>
            <div className={cls.shareContent}>
              <div className={cls.title}>
                <div className={cls.time}>
                  {data?.updateTime || '--'} {fmtTimeToweek(data?.updateTime)}
                </div>
              </div>
              <div className={cls.subTitle}>{getStringInsideFirstBracke(data?.conten || '')}</div>
              <div className={cls.content}>{data?.conten?.replace(getStringInsideFirstBracke(data?.conten || ''), "")}</div>
              <div className={cls.tips}> <div>以上内容来自财联社。</div>免责声明:以上所载内容均来自第三方，中航证券对信息的准确性、完整性、可靠性、及时性不做任何保证，本内容所载信息，不代表中航证券观点，不构成任何投资建议。对任何因直接或间接据此进行投资所造成的后果，中航证券不承担任何法律责任。投资有风险，入市需谨慎。</div>
            </div>
          </div>
          {wxPushData?.username && !window.config.hiddenBusinessCard && <div className={cls.foot} >
            <div className={cls.mp}>
              <div className={cls.left}>
                <div className={cls.imgView}>
                  <img src={wxPushData?.avatar} className={cls.img} alt='' />
                </div>
                <div className={cls.conent}>
                  <div className={cls.title}>
                    {wxPushData?.username || '--'}
                  </div>
                  <div className={cls.subtitle}>{wxPushData?.position || '--'}</div>
                </div>
              </div>
              <div className={cls.right}>
                <img src={wxPushData?.qr_code} className={cls.ewm} alt='' />
              </div>
            </div>
            <div className={cls.bottom}>
              <div className={cls.test}>长按扫描二维码加我企微随时交流</div>
            </div>
          </div>}

        </div>
        {url ? (
          <div className={cls.shareCardImgWrap}>
            <img className={cls.shareCardImg} src={url} width="100%" alt="" />
            <div
              className={cls.shareCardImgPlaceholder}
              onClick={handleMaskClick}
            ></div>
          </div>
        ) : (
          <Loading text="图片加载中" />
        )}
      </CenterPopup>
      <Popup
        visible={!!url}
        mask={false}
        bodyStyle={{
          borderRadius: '0.9375rem 0.9375rem 0 0',
          backgroundColor: '#EAEAEA',
        }}
      >
        <div className={cls.bottomShareTitle}>
          <div className={`${cls.line} ${cls.leftLine}`}></div>
          <span>长按保存至手机</span>
          <div className={`${cls.line} ${cls.rightLine}`}></div>
        </div>
        <div className={cls.bottomShareTips}>
          保存图片后分享给客户，或点击下方按钮分享至朋友圈
        </div>
        <div className={cls.bottomShareBtn} onClick={handleShare}>
          分享至朋友圈
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default ImgShare;


