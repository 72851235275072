/**
 * 中航独家列表
 */

import React from 'react';
import ItemCardHorizontal from '../../components/ItemCardHorizontal';
import { SendOutline } from 'antd-mobile-icons';
import { MEDIA_TYPE_NAMES, URL_NAMES } from '../../constants';
import useQueryNewsInfinate from '../../hooks/useQueryNewsInfinate';
import Nodata from '../../components/Nodata';
import Loading from '../../components/Loading';
import { InfiniteScroll } from 'antd-mobile';
import { fmtCurrentDateTime2Str, fmtRoute2FullPath } from '../../utils/tools';
import useJump from '../../hooks/useJump';
import DefaultNewsImg from '../../images/DefaultNewsImg.png';
import { useNavigate } from 'react-router-dom';
const defaultImgUrl = window.location.origin + DefaultNewsImg;
export interface ZHDJListProps {
  searchValue?: string;
  count?: number;
  isLoadMore?: boolean;
  loadingColor?: string;
  onShare?: (func: string, item: API.INews, index: number) => void;
  onbutClick?:()=>void;
  onItemClick?:()=>void;
}
const ZHDJList: React.FC<ZHDJListProps> = ({
  searchValue,
  count,
  isLoadMore,
  loadingColor,
  onShare,
  onbutClick,
  onItemClick,
}) => {
  const { jumpNewsDetailFromMediaType } = useJump();
  const navigator = useNavigate();
  const { data, loading, loadMore, hasMore } = useQueryNewsInfinate({
    mediaTypeId: MEDIA_TYPE_NAMES.中航独家,
    searchValue,
    count,
  });
  return (
    <React.Fragment>
      {data?.map((item, index) => (
        <ItemCardHorizontal
          key={item.id}
          shereid={item.id}
          img={item.img || defaultImgUrl}
          title={item?.title}
          description={item.shortTitle}
          onbutClick={onbutClick}
          time={
            <span>
              {item?.updateTime
                ? fmtCurrentDateTime2Str(item.updateTime)
                : null}
              &nbsp;&nbsp;
              {item.hostMedia ? (
                <>
                  <SendOutline />
                  &nbsp;{item.hostMedia}
                </>
              ) : null}
            </span>
          }
          share
          onItemClick={() => {
            if(onItemClick){
              onItemClick()
            }
            if (item.url) {
              jumpNewsDetailFromMediaType(MEDIA_TYPE_NAMES.中航独家, {
                url: item.url,
                id: item.id,
                type: Number(item.viewers),
              });
            } else {
              navigator(URL_NAMES.中航独家详情 + '/' + item?.id);
            }
          }}
          onShareClick={(func) => {
            onShare?.(
              func,
              {
                ...item,
                img: item.img || fmtRoute2FullPath('/shareImg/zhdj.jpg'),
              },
              index,
            );
          }}
        />
      ))}
      {!loading && !data?.length && (
        <Nodata content={searchValue ? '暂未搜索到相关数据' : '暂无数据'} />
      )}
      {/* 初始化loading */}
      {!data && loading ? (
        <Loading loadingColor={loadingColor} />
      ) : (
        hasMore &&
        !!data &&
        isLoadMore &&
        !loading && (
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <Loading loadingColor={loadingColor} />
          </InfiniteScroll>
        )
      )}
    </React.Fragment>
  );
};

export default ZHDJList;
