import React from 'react';
import FullImg from '../../components/FullImg';
import { InfiniteScroll } from 'antd-mobile';

import { MEDIA_TYPE_NAMES } from '../../constants';
import Loading from '../../components/Loading';
import useQueryNewsInfinate from '../../hooks/useQueryNewsInfinate';
import { fmtCurrentDateTime2Str } from '../../utils/tools';
const img = `data:image/webp;base64,UklGRjgBAABXRUJQVlA4WAoAAAAQAAAAJwAACwAAQUxQSCAAAAABF/Dc/4iIQVHbNtBuXIVY0vkNQET/J+ACnaR4N0lHBlZQOCDyAAAA0AUAnQEqKAAMAD5tLJNFpCKhlVYAQAbEtgBOmUJBZ8g3mHcAlgAI+5wxCUofvJV2n0pO20o7AAD+/62gvRGWKbxTkT+epjVxVjCXJKkqcmKz2Sou/vpRpDqn7tTmdsTCn4JqDn1QHnhfmWmF/9G7c9TqRmM9xW7NY0AP/FQcAd5q8iUrsad7/Q3Lg7hG9UGWESFePcsiwvMjbWlz3IQChUWsmzE3OSr3cJKELBj0/JbophnZ7itu46M1ifOgVAVF3bXIQA/dfzj1zFT5RLbFsPe+8aoNoSQTfNFWa108vinmZ7/e9+n/KtwscayR2pLgAAA=`;
export interface ExcitingActivityListProps {
  searchValue?: string;
  count?: number;
  isLoadMore?: boolean;
  showInfo?: boolean;
  loadingColor?: string;
}
const ExcitingActivityList: React.FC<ExcitingActivityListProps> = ({
  searchValue,
  count = 2,
  isLoadMore,
  showInfo,
  loadingColor = '#cccccc',
}) => {
  const { data, loading, loadMore, hasMore } = useQueryNewsInfinate({
    mediaTypeId: MEDIA_TYPE_NAMES.精彩活动,
    searchValue,
    count,
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {data?.map((item, index) => (
        <FullImg
          style={{ marginTop: '0.7500rem' }}
          key={item.id}
          img={img}
          title={item.title}
          extra={`${
            fmtCurrentDateTime2Str(item.startTime, { format: 'YYYY/MM/DD' }) ||
            '----'
          }~${
            fmtCurrentDateTime2Str(item.stany1, { format: 'MM/DD' }) || '----'
          }`}
          showInfo={showInfo}
        />
      ))}
      {/* 初始化loading */}
      {!data && loading ? (
        <Loading loadingColor={loadingColor} />
      ) : (
        hasMore &&
        !!data &&
        isLoadMore && (
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <Loading loadingColor={loadingColor} />
          </InfiniteScroll>
        )
      )}
    </div>
  );
};

export default ExcitingActivityList;
