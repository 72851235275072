/**
 * 焦点复盘
 */
import React from 'react';
import cls from './DetailTitleCard.module.scss';
import url from '../../images/wjkp-tb.png';
import nrxqurl from '../../images/nrxq.png';
import gdxqurl from '../../images/WechatIMG17.png';
export interface Props {
    type?:boolean;
    conten?:string;
}
const DetailContCard: React.FC<Props> = ({
    conten,
    type=true
}) => {
  return (
        <div className={cls.contentWrap}>
          <div className={cls.contentWrap_title}>
            <img src={url} className={cls.tbimg} alt="" />
            <div className={cls.nrxqView}>
              <img src={type?nrxqurl:gdxqurl} className={cls.nrxqimg} alt="" />
            </div>
          </div>
          <div
            className={cls.content + ' reach-text-content'}
            dangerouslySetInnerHTML={{ __html: conten ?? '' }}
          ></div>
        </div>
  );
};

export default DetailContCard;
