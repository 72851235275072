import React from 'react';

import cls from './CJZBCard.module.scss';

export interface CJZBCardProps {
  titleImg: string;
  children?: React.ReactNode;
  renderContent?: () => any;
  style?: React.CSSProperties;
  time?: string;
}
const CJZBCard: React.FC<CJZBCardProps> = ({
  titleImg,
  children,
  renderContent,
  style,
  time,
}) => {
  return (
    <div className={cls.wrap} style={style}>
      <div
        className={cls.titleView}
        style={{ paddingBottom: time ? '0.0000rem' : '1.2500rem' }}
      >
        <img src={titleImg} className={cls.img} alt="" />
      </div>
      {time && <div className={cls.sbTitle}>{time}</div>}
      {renderContent ? renderContent() : <div>{children}</div>}
    </div>
  );
};

export default CJZBCard;
