/**
 * Dom操作相关的工具类
 */

/**
 * 加载script
 * @param src script地址
 * @returns
 */
export const loadScript = (urls: string) => {
  const src = urls.startsWith('http')
    ? urls
    : `${process.env.PUBLIC_URL ?? ''}${urls}`;
  const scripts = [
    ...Array.from(window.document.head.getElementsByTagName('script')),
    ...Array.from(window.document.body.getElementsByTagName('script')),
  ];
  // @ts-ignore
  if (Array.from(scripts).find((item) => item.attributes?.src?.value === src)) {
    return;
  }
  return new Promise((resolve, reject) => {
    const s = window.document.createElement('script');
    s.src = src;
    s.onload = function () {
      resolve(1);
    };
    s.onerror = function (error) {
      reject(error);
    };
    window.document.body.appendChild(s);
  });
};

// /**
//  * replace并刷新页面
//  * @param url 跳转的页面url（config中配置的url，不带base）
//  */
// export const replaceAndRefreshPage = (url: string): void => {
//   const replaceUrl = window.routerBase + url;
//   window.location.replace(replaceUrl);
// };

export const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};
