// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/DailyTaskCardBg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DailyTask_wrap__1nODE {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) #ffffff;
  background-size: 100% auto;
  background-repeat: no-repeat;
  border-radius: 0.625rem;
  padding-top: 0.9375rem;
  padding-bottom: 0.75rem;
}
.DailyTask_wrap__1nODE .DailyTask_timeline__yJjLO {
  display: flex;
  overflow-x: auto;
  padding: 0 0.9375rem;
}
.DailyTask_wrap__1nODE .DailyTask_timeline__yJjLO::-webkit-scrollbar {
  display: none;
}
.DailyTask_wrap__1nODE .DailyTask_title__sZpCT {
  height: 1.125rem;
  margin: 0 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/DailyTask.module.scss"],"names":[],"mappings":"AAAA;EACE,2DAAA;EACA,0BAAA;EACA,4BAAA;EACA,uBAAA;EACA,sBAAA;EACA,uBAAA;AACF;AAAE;EACE,aAAA;EACA,gBAAA;EACA,oBAAA;AAEJ;AADI;EACE,aAAA;AAGN;AAAE;EACE,gBAAA;EACA,iBAAA;AAEJ","sourcesContent":[".wrap {\r\n  background: url('../../images/DailyTaskCardBg.png') #ffffff;\r\n  background-size: 100% auto;\r\n  background-repeat: no-repeat;\r\n  border-radius: 0.6250rem;\r\n  padding-top: 0.9375rem;\r\n  padding-bottom: 0.7500rem;\r\n  .timeline {\r\n    display: flex;\r\n    overflow-x: auto;\r\n    padding: 0 0.9375rem;\r\n    &::-webkit-scrollbar {\r\n      display: none;\r\n    }\r\n  }\r\n  .title {\r\n    height: 1.1250rem;\r\n    margin: 0 0.7500rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `DailyTask_wrap__1nODE`,
	"timeline": `DailyTask_timeline__yJjLO`,
	"title": `DailyTask_title__sZpCT`
};
export default ___CSS_LOADER_EXPORT___;
