import React, { useState } from 'react';
import cls from './XGGG.module.scss';
import './common.scss';

export interface XgkhXgggProps {
  data: API.INews | undefined;
}

const XGGG: React.FC<XgkhXgggProps> = ({
  data,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
 
  // 截取文本
  let stockList = data?.relatedStocks;
  let stockMaxList = data?.relatedStocks;
  if (stockList && !isExpanded && stockList.length > 6) {
    stockMaxList = stockList.slice(0, 6);
  }
 
  // 切换展开/收起状态
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className={`${cls['block_3']} flex-col`}>
      <div className={`${cls['box_5']} flex-col`}>
        <div className={`${cls['block_6']} flex-row`}>
          <img
            className={`${cls['label_2']}`}
            src={require('../images/xiangguangegu1.png')}
            alt=''
          />
          <span className={`${cls['text_7']}`}>相关个股</span>
              {stockList && stockList.length > 6 && (
                <div className={`${cls['section_1']} flex-row`}>
                  <div className={`${cls['image-text_8']} flex-row justify-between`}>
                    <div className={`flex-row justify-between`}>
                      <button 
                        onClick={toggleExpand}
                        className={`${cls['text-group_1']}`} >
                        {isExpanded ? '收起' : '展开'}
                      </button>
                      <img
                        className={`${cls['thumbnail_1']}`}
                        src={require('../images/xiangguankehu2.png')}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              )}
          <img
            className={`${cls['image_6']}`}
            src={require('../images/xiangguankehu3.png')}
            alt=''
          />
        </div>
        <div className={`${cls['grid_4']} flex-row`}>
          {stockMaxList && stockMaxList.map((item, index) => (
            <div key={index} className={`${cls['xiangguangegu']}`}>
              <div className={`${cls['text-wrapper_7']}`}>
                <div className={`${cls['text_22']}`}>
                    {item.stockName}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default XGGG;
