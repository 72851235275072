import { useCallback, useEffect, useMemo, useState } from 'react';
import { queryActiveList, useQueryActiveList } from '../services/api';

const useQueryNewsInfinate = ({
  mediaTypeId,
  searchValue,
  count = 2,
  revalidateIfStale,
}: {
  mediaTypeId?: string;
  searchValue?: string;
  count?: number;
  revalidateIfStale?: boolean;
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { data, loading, mutate } = useQueryActiveList({
    mediaTypeId,
    searchTitle: searchValue,
    pageMaxCount: count,
    revalidateIfStale,
  });

  useEffect(() => {
    setCurrentPage(0);
  }, [searchValue, mediaTypeId]);

  const loadMore = useCallback(
    async (isRetry: boolean) => {
      const res = await queryActiveList({
        mediaTypeId: mediaTypeId,
        searchTitle: searchValue,
        pageIndex: currentPage + 1,
        pageMaxCount: count,
      });
      if (res) {
        // 修改数据后，不重新刷新缓存
        mutate([...(data ?? []), ...res], { revalidate: false });
        setCurrentPage((old) => old + 1);
      }
    },
    [data, mutate, currentPage, mediaTypeId, searchValue, count],
  );

  const hasMore = useMemo(() => {
    if (!data?.length) {
      return false;
    }

    // 实际页数完全等于当前页（正好请求完，且没有余数）
    return data.length / count === currentPage + 1;
  }, [data, count, currentPage]);
  return {
    data,
    loading,
    loadMore,
    currentPage,
    hasMore,
  };
};

export default useQueryNewsInfinate;
