import React from 'react';
import ExcitingActivityList from './containers/ExcitingActivityList';
import { useOutletContext } from 'react-router-dom';
import Card from '../components/Card';
import useTitle from '../hooks/useTitle';
import useScrollToTop from '../hooks/useScrollToTop';

/**
 * 精彩活动
 */
const JCHD = () => {
  useScrollToTop();
  useTitle('精彩活动');
  const [{ searchValue }] = useOutletContext<[{ searchValue?: string }]>();

  return (
    <Card
      styles={{
        paddingTop: 0,
        marginTop: '0.9375rem',
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      <ExcitingActivityList
        isLoadMore
        showInfo
        searchValue={searchValue}
        count={20}
      />
    </Card>
  );
};

export default JCHD;
