// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/HotShareCardBg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HotShareCard_wrap__JWE2j {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-color: #2e3254;
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0.625rem;
  padding: 0.4375rem;
}
.HotShareCard_wrap__JWE2j .HotShareCard_title__9bnNf {
  height: 1.5rem;
  padding: 0.5rem;
}
.HotShareCard_wrap__JWE2j .HotShareCard_item__-o3N9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.9375rem;
}
.HotShareCard_wrap__JWE2j .HotShareCard_item__-o3N9:last-child {
  margin-bottom: 0;
}
.HotShareCard_wrap__JWE2j .HotShareCard_item__-o3N9 .HotShareCard_icon__1lK2L {
  height: 0.625rem;
}
.HotShareCard_wrap__JWE2j .HotShareCard_item__-o3N9 .HotShareCard_itemTitle__8PuC0 {
  flex: 1 1;
  margin: 0 0.4375rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #1e1e1e;
  font-size: 0.875rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/HotShareCard.module.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,yBAAA;EACA,8BAAA;EACA,wBAAA;EACA,4BAAA;EACA,uBAAA;EACA,kBAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,wBAAA;AAEJ;AADI;EACE,gBAAA;AAGN;AADI;EACE,gBAAA;AAGN;AADI;EACE,SAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,mBAAA;AAGN","sourcesContent":[".wrap {\r\n  background-image: url('../../images/HotShareCardBg.png');\r\n  background-color: #2e3254;\r\n  background-position: top right;\r\n  background-size: contain;\r\n  background-repeat: no-repeat;\r\n  border-radius: 0.6250rem;\r\n  padding: 0.4375rem;\r\n  .title {\r\n    height: 1.5000rem;\r\n    padding: 0.5000rem;\r\n  }\r\n  .item {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    margin-bottom: 0.9375rem;\r\n    &:last-child {\r\n      margin-bottom: 0;\r\n    }\r\n    .icon {\r\n      height: 0.6250rem;\r\n    }\r\n    .itemTitle {\r\n      flex: 1;\r\n      margin: 0 0.4375rem;\r\n      overflow: hidden;\r\n      white-space: nowrap;\r\n      text-overflow: ellipsis;\r\n      color: #1e1e1e;\r\n      font-size: 0.8750rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `HotShareCard_wrap__JWE2j`,
	"title": `HotShareCard_title__9bnNf`,
	"item": `HotShareCard_item__-o3N9`,
	"icon": `HotShareCard_icon__1lK2L`,
	"itemTitle": `HotShareCard_itemTitle__8PuC0`
};
export default ___CSS_LOADER_EXPORT___;
