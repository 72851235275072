// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/WechatIMG105.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EwmModal_wrap__DRZQM {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}
.EwmModal_wrap__DRZQM .EwmModal_box__GgXfV {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
  width: 79vw;
  height: 98vw;
  border-radius: 1.5rem;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% auto;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.EwmModal_wrap__DRZQM .EwmModal_box__GgXfV .EwmModal_imgView__Z9C5G {
  display: flex;
  padding-left: 32vw;
  padding-top: 0.125rem;
}
.EwmModal_wrap__DRZQM .EwmModal_box__GgXfV .EwmModal_imgView__Z9C5G .EwmModal_img__ua1hr {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
}
.EwmModal_wrap__DRZQM .EwmModal_box__GgXfV .EwmModal_ewmView__sNiqd {
  display: flex;
  justify-content: center;
  padding-top: 5vw;
}
.EwmModal_wrap__DRZQM .EwmModal_box__GgXfV .EwmModal_ewmView__sNiqd .EwmModal_ewm__ZipEn {
  width: 45vw;
  height: 45vw;
}
.EwmModal_wrap__DRZQM .EwmModal_foot__8Zslh {
  position: absolute;
  top: calc(18% + 98vw + 1.25rem);
  left: calc(50% - 0.9375rem);
}
.EwmModal_wrap__DRZQM .EwmModal_foot__8Zslh .EwmModal_gb__KW2jJ {
  width: 1.875rem;
  height: 1.875rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/EwmModal.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,oCAAA;AACJ;AACI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,4BAAA;EACA,wBAAA;EACA,0BAAA;EACA,yDAAA;AACR;AAAQ;EACI,aAAA;EACA,kBAAA;EACA,qBAAA;AAEZ;AADY;EACI,cAAA;EACA,eAAA;EACA,kBAAA;AAGhB;AAAQ;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;AAEZ;AADY;EACI,WAAA;EACA,YAAA;AAGhB;AAEI;EACI,kBAAA;EACA,+BAAA;EACA,2BAAA;AAAR;AACQ;EACI,eAAA;EACA,gBAAA;AACZ","sourcesContent":[".wrap {\r\n    width: 100vw;\r\n    height: 100vh;\r\n    position: fixed;\r\n    left: 0;\r\n    top: 0;\r\n    z-index: 9999;\r\n    background-color: rgba($color: #000000, $alpha: 0.5);\r\n\r\n    .box {\r\n        position: absolute;\r\n        top: 18%;\r\n        left: 50%;\r\n        transform: translateX(-50%);\r\n        width: 79vw;\r\n        height: 98vw;\r\n        border-radius: 1.5000rem;\r\n        background-repeat: no-repeat;\r\n        background-position: top;\r\n        background-size: 100% auto;\r\n        background-image: url('../../images/WechatIMG105.png');\r\n        .imgView {\r\n            display: flex;\r\n            padding-left: 32vw;\r\n            padding-top: 0.1250rem;\r\n            .img {\r\n                width: 3.7500rem;\r\n                height: 3.7500rem;\r\n                border-radius: 50%;\r\n            }\r\n        }\r\n        .ewmView {\r\n            display: flex;\r\n            justify-content: center;\r\n            padding-top: 5vw;\r\n            .ewm {\r\n                width: 45vw;\r\n                height: 45vw;\r\n            }\r\n        }\r\n       \r\n    }\r\n    .foot {\r\n        position: absolute;\r\n        top: calc(18% + 98vw + 1.2500rem);\r\n        left: calc(50% - 0.9375rem);\r\n        .gb {\r\n            width: 1.8750rem;\r\n            height: 1.8750rem;\r\n        }\r\n\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `EwmModal_wrap__DRZQM`,
	"box": `EwmModal_box__GgXfV`,
	"imgView": `EwmModal_imgView__Z9C5G`,
	"img": `EwmModal_img__ua1hr`,
	"ewmView": `EwmModal_ewmView__sNiqd`,
	"ewm": `EwmModal_ewm__ZipEn`,
	"foot": `EwmModal_foot__8Zslh`,
	"gb": `EwmModal_gb__KW2jJ`
};
export default ___CSS_LOADER_EXPORT___;
