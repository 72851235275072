import React from 'react';
import cls from './PhoneModal.module.scss';
export interface IProps {
  onSubmit: () => void;
  onCancel: () => void;
  visible: boolean;
  phone:string;

}
const PhoneModal: React.FC<IProps> = ({
  visible,
  onSubmit,
  phone,
  onCancel,
}) => {
  if (!visible) return null;
  return (
    <div className={cls.wrap}>
      <div className={cls.box}>
        <h3 className={cls.title}>提示</h3>
        <div className={cls.body}>确定拨打电<span>{phone}</span> 吗?</div>
        <div className={cls.footer}>
          <div className={cls.submit} onClick={onSubmit}>
            确定
          </div>
          <div className={cls.cancel} onClick={onCancel}>
            取消
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneModal;
