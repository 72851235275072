/**
 * 翼直播列表
 */

import React from 'react';
import { Grid, InfiniteScroll } from 'antd-mobile';
import ItemCardVertical from '../../components/ItemCardVertical';
import Nodata from '../../components/Nodata';
import Loading from '../../components/Loading';
import { MEDIA_TYPE_NAMES } from '../../constants';
import useJump from '../../hooks/useJump';
import useQueryNewsInfinate from '../../hooks/useQueryNewsInfinate';
import moment from 'moment';
import DefaultNewsImg from '../../images/DefaultNewsImg.png';
import { fmtRoute2FullPath } from '../../utils/tools';
const defaultImgUrl = window.location.origin + DefaultNewsImg;
export interface YZBListProps {
  searchValue?: string;
  count?: number;
  isLoadMore?: boolean;
  loadingColor?: string;
  onShare?: (func: string, item: API.INews, index: number) => void;
  onbutClick?:()=>void;
  onItemClick?:()=>void;
}
const YZBList: React.FC<YZBListProps> = ({
  searchValue,
  count,
  isLoadMore,
  loadingColor,
  onShare,
  onbutClick,
  onItemClick,
}) => {
  const { jumpNewsDetailFromMediaType } = useJump();
  const { data, loading, loadMore, hasMore } = useQueryNewsInfinate({
    mediaTypeId: MEDIA_TYPE_NAMES.翼直播,
    searchValue,
    count,
  });
  return (
    <React.Fragment>
      <Grid columns={2} gap={12}>
        {data?.map((item, index) => (
          <Grid.Item key={item.id}>
            <ItemCardVertical
              img={item.img || defaultImgUrl}
              title={item?.title}
              imgHeight='6.5625rem'
              shereid={item.id}
              onbutClick={onbutClick}
              time={
                item.updateTime
                  ? moment(item.updateTime, 'YYYY-MM-DD HH:mm:ss').format(
                      'MM-DD HH:mm',
                    )
                  : ''
              }
              name={item?.author}
              share
              onItemClick={() => {
                if(onItemClick){
                  onItemClick()
                }
                jumpNewsDetailFromMediaType(MEDIA_TYPE_NAMES.翼直播, {
                  url: item.url,
                  id: item.id,
                });
              }}
              onShareClick={(func) => {
                onShare?.(
                  func,
                  { ...item, img: fmtRoute2FullPath('/shareImg/yzb.jpg') },
                  index,
                );
              }}
            />
          </Grid.Item>
        ))}
      </Grid>
      {!loading && !data?.length && (
        <Nodata content={searchValue ? '暂未搜索到相关数据' : '暂无数据'} />
      )}
      {/* 初始化loading */}
      {!data && loading ? (
        <Loading loadingColor={loadingColor} />
      ) : (
        hasMore &&
        !!data &&
        isLoadMore &&
        !loading && (
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <Loading loadingColor={loadingColor} />
          </InfiniteScroll>
        )
      )}
    </React.Fragment>
  );
};

export default YZBList;
