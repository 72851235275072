// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsFlash_wrap__O8oBY {
  background: #f5f8ff;
  box-shadow: 0.0625rem 0.125rem 0.3125rem 0rem rgba(196, 220, 253, 0.47);
  border-radius: 0.625rem;
  padding: 0.625rem 0.625rem 0.625rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.NewsFlash_wrap__O8oBY .NewsFlash_content__vvefw {
  flex: 1 1;
  margin: 0 1.0625rem;
  overflow: hidden;
  font-size: 0.8125rem;
}
.NewsFlash_wrap__O8oBY .NewsFlash_content__vvefw .NewsFlash_newsItemWrap__LBQ7y {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 1.875rem;
}
.NewsFlash_wrap__O8oBY .NewsFlash_content__vvefw .NewsFlash_newsItem__MB8WU {
  word-wrap: break-word;
  overflow: hidden;
  height: 1.875rem;
  display: -webkit-box;
  line-height: 1rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.NewsFlash_wrap__O8oBY .NewsFlash_content__vvefw .NewsFlash_animation2__evrIZ {
  animation-delay: 2s;
  animation: NewsFlash_scroll__IKGCM 0.5s linear forwards;
}
.NewsFlash_wrap__O8oBY .NewsFlash_content__vvefw .NewsFlash_animation1__uW-mD {
  animation-delay: 2s;
  animation: NewsFlash_scroll__IKGCM 0.5s linear forwards;
}
.NewsFlash_wrap__O8oBY .NewsFlash_img__NxI-W {
  height: 1.8125rem;
}
@keyframes NewsFlash_scroll__IKGCM {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.NewsFlash_SwiperView__ma5-n {
  height: 1.875rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/NewsFlash.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,uEAAA;EACA,uBAAA;EACA,wCAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;AACF;AAAE;EACE,SAAA;EACA,mBAAA;EACA,gBAAA;EACA,oBAAA;AAEJ;AAAI;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;AAEN;AAAI;EACE,qBAAA;EACA,gBAAA;EACA,gBAAA;EACA,oBAAA;EACA,iBAAA;EACA,qBAAA;EACA,4BAAA;EACA,uBAAA;AAEN;AACI;EACE,mBAAA;EACA,uDAAA;AACN;AACI;EACE,mBAAA;EACA,uDAAA;AACN;AAEE;EACE,iBAAA;AAAJ;AAII;EACE;IACE,wBAAA;EAFN;EAII;IACE,4BAAA;EAFN;AACF;;AAMA;EACE,gBAAA;AAHF","sourcesContent":[".wrap {\r\n  background: #f5f8ff;\r\n  box-shadow: 0.0625rem 0.1250rem 0.3125rem 0.0000rem rgba(196, 220, 253, 0.47);\r\n  border-radius: 0.6250rem;\r\n  padding: 0.6250rem 0.6250rem 0.6250rem 1.0000rem;\r\n  display: flex;\r\n  flex-direction: row;\r\n  align-items: center;\r\n  justify-content: flex-start;\r\n  .content {\r\n    flex: 1;\r\n    margin: 0 1.0625rem;\r\n    overflow: hidden;\r\n    font-size: 0.8125rem;\r\n\r\n    .newsItemWrap {\r\n      display: flex;\r\n      align-items: center;\r\n      box-sizing: border-box;\r\n      height: 1.8750rem;\r\n    }\r\n    .newsItem {\r\n      word-wrap: break-word;\r\n      overflow: hidden;\r\n      height: 1.8750rem;\r\n      display: -webkit-box;\r\n      line-height: 1.0000rem;\r\n      -webkit-line-clamp: 2;\r\n      -webkit-box-orient: vertical;\r\n      text-overflow: ellipsis;\r\n    }\r\n\r\n    .animation2 {\r\n      animation-delay: 2s;\r\n      animation: scroll 0.5s linear forwards;\r\n    }\r\n    .animation1 {\r\n      animation-delay: 2s;\r\n      animation: scroll 0.5s linear forwards;\r\n    }\r\n  }\r\n  .img {\r\n    height: 1.8125rem;\r\n  }\r\n\r\n  :global {\r\n    @keyframes scroll {\r\n      0% {\r\n        transform: translateY(0);\r\n      }\r\n      100% {\r\n        transform: translateY(-100%);\r\n      }\r\n    }\r\n  }\r\n}\r\n.SwiperView {\r\n  height: 1.8750rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `NewsFlash_wrap__O8oBY`,
	"content": `NewsFlash_content__vvefw`,
	"newsItemWrap": `NewsFlash_newsItemWrap__LBQ7y`,
	"newsItem": `NewsFlash_newsItem__MB8WU`,
	"animation2": `NewsFlash_animation2__evrIZ`,
	"scroll": `NewsFlash_scroll__IKGCM`,
	"animation1": `NewsFlash_animation1__uW-mD`,
	"img": `NewsFlash_img__NxI-W`,
	"SwiperView": `NewsFlash_SwiperView__ma5-n`
};
export default ___CSS_LOADER_EXPORT___;
