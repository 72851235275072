/**
 * 焦点复盘
 */
import { useCallback, useMemo } from 'react';
import ShareBtnBottom from '../components/ShareBtnBottom';

import useInitWxFunc from '../hooks/useInitWxFunc';
import useTitle from '../hooks/useTitle';
import { queryGetUpdateHost, useQueryAggregationUrl} from '../services/api';
import { fmtRoute2FullPath, getFullPathFromMediaTypeIds } from '../utils/tools';
import cls from './JHY.module.scss';
import { shareWxworkMessage } from '../utils/wxUtil';
import useScrollToTop from '../hooks/useScrollToTop';

import url from '../images/ZXZTContentTitle.png';

import { useParams } from 'react-router-dom';

import JHYList from './containers/JHYList';
import { getPushUrl } from '../utils/utils';
import { useGlobalState } from '../models/global';

const JHY = () => {
    useScrollToTop();
    const { id } = useParams();
    const [{wxUseData}]=useGlobalState()
    console.log(id);
    const { data, loading } = useQueryAggregationUrl(Number(id));
    useTitle(data?.specialTopics||' ')
    const shareInfo = useMemo(() => {
        if (!data?.aggrerationId) {
          return undefined;
        }
        return {
          title: data?.specialTopics,
          link:getPushUrl(window.location.href,wxUseData),
          desc: data?.specialIntroduce,
          imgUrl: fmtRoute2FullPath('/shareImg/yzx.jpg'),
          success: () => {
            queryGetUpdateHost(data.aggrerationId + '');
          },
        };
      }, [data,wxUseData]);
    const { onShare } = useInitWxFunc({ shareInfo });
    const handleNewsShareClick = useCallback(
      (func: string) => {
        const {success, ...otherShareInfo} = shareInfo || {};
        // queryGetUpdateHost(`${data?.aggrerationId}`);
        onShare(func, () => {
          shareWxworkMessage(func, otherShareInfo);
        });
      },
      [onShare, shareInfo],
    );
    const handleNewsShareClickItem = useCallback(
        (func: string, item: API.INews, index: number) => {
            queryGetUpdateHost(`${item.id}`);
          onShare(func, () => {
            shareWxworkMessage(func, {
              title: item.title,
              link: getPushUrl(getFullPathFromMediaTypeIds(item.mediaType, {
                url: item.url,
                id: item.id,
                date:item.updateTime
              }),wxUseData),
              desc: item.shortTitle,
              imgUrl: item.img,
            });
          });
        },
        [onShare,wxUseData],
      );
    return (
        <div className={cls.warp}>
            <div className={cls.banner} style={{ backgroundImage: `url(${data?.specialPicture})` }} >
                <div className={cls.title}>{data?.specialTopics||'--'}</div>
            </div>
            <div className={cls.conent}>
                <div className={cls.titleView}>
                    <div className={cls.abstract}>{data?.specialIntroduce||'--'}</div>
                </div>
                <div className={cls.contentWrap_title}>
                    <img src={url} className={cls.img} alt="" />

                </div>
                <div className={cls.contentWrap}>
                  <JHYList  data={data?.selectResouceRegionMiddleList||[]} loading={loading} onShare={handleNewsShareClickItem}/>
                </div>
            </div>
            <ShareBtnBottom onIconClick={handleNewsShareClick} />
        </div>
    );
};

export default JHY;
