/**
 * 资讯专题
 */
import { useCallback, useMemo } from 'react';
import ShareBtnBottom from '../components/ShareBtnBottom';
import Tips from '../components/Tips';
import useInitWxFunc from '../hooks/useInitWxFunc';
import useTitle from '../hooks/useTitle';
import { queryGetUpdateHost, useQueryHotShareList } from '../services/api';
import cls from './ZXZT.module.scss';
import { shareWxworkMessage } from '../utils/wxUtil';
import useScrollToTop from '../hooks/useScrollToTop';
import BannerCard from './components/BannerCard';
import {
  fmtCurrentDateTime2Str,
  fmtRoute2FullPath,
  getCurrentTimeStr,
  randomImg,
} from '../utils/tools';
import Card from '../components/Card';
import ItemCardHorizontal from '../components/ItemCardHorizontal';
import useJump from '../hooks/useJump';
import { getPushUrl } from '../utils/utils';
import { useGlobalState } from '../models/global';

const PAGE_NAME = '资讯专题';
const ZXZT = () => {
  useScrollToTop();
  useTitle(PAGE_NAME);
  const { jumpNewsDetailFromMediaType } = useJump();

  const data = useMemo(() => {
    return {
      id: 'sdsds',
      title: 'title 12121221',
      shortTitle: 'shortTitledsdss shortTitle',
      conten: 'content---',
    };
  }, []);
  const [{wxUseData}]=useGlobalState()
  const shareInfo = useMemo(() => {
    if (!data?.id) {
      return undefined;
    }
    return {
      title: `资讯专题 ${getCurrentTimeStr()}`,
      link: getPushUrl(window.location.href,wxUseData),
      desc: data?.title,
      imgUrl: fmtRoute2FullPath('/shareImg/wjkp.jpg'),
      success: () => {
        queryGetUpdateHost(data.id + '');
      },
    };
  }, [data,wxUseData]);
  const { onShare } = useInitWxFunc({ shareInfo });
  const handlePageShareClick = useCallback(
    (func: string) => {
      queryGetUpdateHost(`${data?.id}`);
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: `资讯专题 ${getCurrentTimeStr()}`,
          link: getPushUrl(window.location.href,wxUseData),
          desc: data?.title,
          imgUrl: fmtRoute2FullPath('/shareImg/wjkp.jpg'),
        });
      });
    },
    [onShare, data,wxUseData],
  );
  const handleNewsShareClick = useCallback(
    (func: string, item: API.INews) => {
      queryGetUpdateHost(`${data?.id}`);
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: `资讯专题 ${getCurrentTimeStr()}`,
          link: window.location.href,
          desc: data?.title,
          imgUrl: fmtRoute2FullPath('/shareImg/wjkp.jpg'),
        });
      });
    },
    [onShare, data],
  );
  const { data: newsList } = useQueryHotShareList();

  return (
    <div className={cls.warp}>
      <BannerCard
        hiddenTime
        pageTitle={data.title}
        img={require('../images/wjkp-banner.png')}
      />
      <div className={cls.conten}>
        <div className={cls.abstractWrap}>
          <div className={cls.abstract}>{data?.shortTitle}</div>
        </div>
        <div className={cls.contentTitleWrap}>
          <img
            src={require('../images/ZXZTContentTitle.png')}
            className={cls.contentTitleImg}
            alt=""
          />
        </div>
        <Card
          styles={{
            paddingTop: 0,
            marginTop: '0.9375rem',
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          {newsList?.map((item, index) => (
            <ItemCardHorizontal
              key={item.id}
              shereid={item.id}
              img={item.img || randomImg()}
              title={item.title}
              description={item.shortTitle}
              time={
                <span>
                  {item?.updateTime
                    ? fmtCurrentDateTime2Str(item.updateTime, {
                        format: 'MM-DD',
                      })
                    : null}
                </span>
              }
              share
              onItemClick={() => {
                jumpNewsDetailFromMediaType(item.mediaType, {
                  url: item.url,
                  id: item.id,
                });
              }}
              onShareClick={(func) => handleNewsShareClick?.(func, item)}
            />
          ))}
        </Card>
      </div>
      <Tips textColor="#989898" style={{ padding: '2.1875rem 0.9375rem 3.7500rem' }} />
      <ShareBtnBottom onIconClick={handlePageShareClick} />
    </div>
  );
};

export default ZXZT;
