/**
 * 翼投教
 */
import { useOutletContext } from 'react-router-dom';
import YTJList from './containers/YTJList';
import useTitle from '../hooks/useTitle';
import useScrollToTop from '../hooks/useScrollToTop';
import useInitWxFunc from '../hooks/useInitWxFunc';
import { useCallback } from 'react';
import { shareWxworkMessage } from '../utils/wxUtil';
import { getFullPathFromMediaTypeIds } from '../utils/tools';
import PageSwiper from './containers/PageSwiper';
import { getPushUrl } from '../utils/utils';
import { useGlobalState } from '../models/global';
const PAGE_NAME = '翼投教';
const YTJ = () => {
  useScrollToTop();
  useTitle(PAGE_NAME);
  const { onShare } = useInitWxFunc();
  const [{wxUseData}]=useGlobalState()
  const handleNewsShareClick = useCallback(
    (func: string, item: API.INews, index: number) => {
      onShare(func, () => {
        shareWxworkMessage(func, {
          title: item.title,
          link: getPushUrl(getFullPathFromMediaTypeIds(item.mediaType, {
            url: item.url,
            id: item.id,
          }),wxUseData),
          desc: item.shortTitle|| '',
          imgUrl: item.img,
        });
      });
    },
    [onShare,wxUseData],
  );
  const [{ searchValue }] = useOutletContext<[{ searchValue?: string }]>();
  return (
    <>
      <PageSwiper
        regionName={PAGE_NAME}
        style={{ paddingTop: '0.7500rem', paddingBottom: '0.3750rem' }}
      />
      <YTJList searchValue={searchValue} onShare={handleNewsShareClick} />
    </>
  );
};

export default YTJ;
