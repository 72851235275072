import React from 'react';
import { RightOutline } from 'antd-mobile-icons';

import cls from './More.module.scss';
export interface MoreProps {
  inline?: boolean;
  onClick?: () => void;
  showUnderLine?: boolean;
  arrowDirection?: 'top' | 'bottom' | 'left' | 'right';
  showArrow?: boolean;
  text?: string;
}
const More: React.FC<MoreProps> = ({
  inline,
  onClick,
  showUnderLine = true,
  arrowDirection = 'right',
  showArrow = true,
  text = '查看更多',
}) => {
  return (
    <div className={`${cls.wrap} ${inline ? ' ' + cls.inline : ''}`}>
      <div
        onClick={onClick}
        className={`${cls.content}${showUnderLine ? ' ' + cls.underline : ''}`}
      >
        {text}&nbsp;
        {showArrow && (
          <RightOutline className={`${cls.icon} ${cls[arrowDirection]}`} />
        )}
      </div>
    </div>
  );
};

export default More;
