// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTicket: '/api/media/homePage/getTicket', //获取企业微信应用ticket
  getJsapiTicket: '/api/media/homePage/getJsapiTicket', //获取企业微信ticket
  queryGrwxJsapiTicket: '/api/media/homePage/getWxTicket', //获取企业微信ticket
  finance: '/api/media/homePage/finance', // 查询财经午报、焦点复盘、明日前瞻
  hotShare: '/api/media/homePage/hotShare', // 查询首页分享热榜
  activeList: '/api/media/homePage/activeList', // 查询跳转页金刚区各个列表（精彩活动、题材掘金、中航独家、翼直播、翼资讯）
  mediaType: '/api/media/homePage/mediaType', //金刚区分类
  shareMedia: '/api/media/homePage/shareMedia', // 分享功能（通过id查询资讯详情）
  getUpdateTimeInformation: '/api/media/homePage/getUpdateTimeInformation', // 获取今日必做的更新时间
  sellMarketDate: '/api/media/juyuan/sellMarketDate', // 获取新股新债
  sellDate: '/api/media/juyuan/sellDate', // 获取今日上市
  getLargeCap: '/api/media/juyuan/getLargeCap', //获取大盘指数
  getUpdateHost: '/api/media/homePage/getUpdateHost', //更新分享次数
  sharePicture: '/api/media/homePage/sharePicture', //分享图片上传
  indexSlide: '/api/media/message/indexslide/selectIndexSlide', // 轮播图
  aggregationUrl:'/api/media/homePage/aggregationUrl', //通过聚合ID查询聚合信息以及资讯详情
  getMoningFlight:'/api/media/homePage/getMoningFlight', // 查询早班机子栏目
  moningFlightPicture:'/api/media/homePage/getMoningFlightPicture', // 查询早班机图片
  moningFlightById:'/api/media/homePage/moningFlightById',   //查询早班机子栏目详情
  investmentAdviserList:'/api/contest/sys/investmentAdviser/list',   // 查询投顾观点列表
  investmentAdviserById:'/api/contest/sys/investmentAdviser/investmentAdviserById', // 查询投顾观点详情
  investmentAdviser:'/api/contest/sys/investmentAdviser', 
  getCustorInfomation:'/api/media/homePage/getCustorInfomation', // 员工名片

};
