import cls from './Card.module.scss';

export interface CardProps {
  children?: React.ReactNode;
  styles?: React.CSSProperties;
  className?: string;
}
const Card: React.FC<CardProps> = ({ children, styles, className }) => {
  return (
    <div className={`${cls.wrap} ${className ?? ''}`} style={styles}>
      <div className={cls.cardContent}>{children}</div>
    </div>
  );
};
export default Card;
